<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
    open-on-hover
    transition="slide-y-transition"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        class="my-2"
        size="40"
        :style="{
          borderBottom: '2px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '2px solid #272727',
        }"
        v-bind="attrs"
        v-on="on"
        ><v-img src="@/assets/max.jpeg"></v-img
      ></v-avatar>
    </template>

    <v-card v-if="userProfile">
      <div class="profile pa-4">
        <v-avatar class="my-2" size="60"
          ><v-img src="@/assets/max.jpeg"></v-img
        ></v-avatar>
        <p class="mb-0 font-weight-medium" style="font-size: 15px">
          {{ userProfile.name }}
        </p>
        <p style="font-size: 12px" class="mb-4">{{ userProfile.email }}</p>
        <div class="mb-2 d-flex" style="gap: 25px">
          <v-btn small text @click="$router.push('/settings')"
            ><v-icon x-small class="mr-1">mdi-cog</v-icon> Settings</v-btn
          >
          <v-btn small text @click="logout"
            ><v-icon x-small class="mr-1">mdi-logout</v-icon> Sign out</v-btn
          >
        </div>
      </div>
      <v-divider></v-divider>

      <v-sheet
        v-if="workspace"
        elevation="0"
        color="primary"
        class="px-4 pt-2 pb-3 text-left"
        style="border-radius: 0px"
      >
        <div class="d-flex">
          <p
            class="white--text text-left"
            style="margin-bottom: 0; font-size: 18pt; font-weight: 700"
          >
            {{ workspace.counter.tokens }}
          </p>
          <v-spacer></v-spacer>
          <v-btn icon small
            ><v-icon small color="smoke">mdi-information</v-icon></v-btn
          >
        </div>
        <p
          class="white--text text-left"
          style="font-size: 9pt; margin-bottom: 0"
        >
          Stix AI tokens left
        </p>
      </v-sheet>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.items {
  display: flex;
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: 100px;
  height: 100px;

  &:hover {
    background-color: #c9fa7546;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
}
</style>
