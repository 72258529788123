<template>
  <div>
    <v-card
      max-width="500"
      flat
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
        backgroundColor: '#D3F987',
      }"
    >
      <div
        class="px-6 py-6"
        style="display: grid; grid-template-columns: auto 70px"
      >
        <div>
          <h2 class="mb-2">Referrals</h2>
          <p style="font-size: 14px" class="ma-0">
            For every referral, <b>you ánd your friend</b> will receive one free
            month of subscription.
            <u>So far you have received <b>1 free month</b>.</u>
          </p>
          <v-btn small elevation="0" color="primary" class="mt-3"
            >Read more</v-btn
          >
        </div>
        <div class="pl-4 d-flex align-center">
          <v-img
            width="60"
            src="@/assets/icons/arrow-empty.svg"
            class="d-flex align-center justify-center text-center"
          >
            <h1>1</h1></v-img
          >
        </div>
      </div>
    </v-card>

    <v-card
      class="pa-6 mt-4"
      flat
      max-width="500"
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
    >
      <div class="referrals">
        <p style="font-size: 10px" class="mb-1">REFERRAL PROGRAM</p>
        <h1>Get free months</h1>
        <!-- <p class="mb-1" style="font-size: 12px">
          Share your unique invite to Stix with the world and receive one month
          free subscription for every new signup.
        </p> -->

        <p style="font-size: 12px" class="mb-0 mt-2">
          Let others sign up via your unique link to receive one month free per
          referral:
        </p>
        <div class="d-flex align-center mt-2" style="gap: 10px">
          <v-btn class="smoke" icon
            ><v-icon small color="primary">mdi-content-copy</v-icon></v-btn
          >
          <p style="font-size: 12px" class="mb-0">
            <b>https://www.getstix.com/access?referral={{ userProfile.uid }}</b>
          </p>
        </div>

        <p style="font-size: 12px" class="mb-0 mt-4">Share on social:</p>
        <div class="d-flex">
          <v-btn color="#3b5998" icon><v-icon>mdi-facebook</v-icon></v-btn>
          <v-btn color="#0072b1" icon><v-icon>mdi-linkedin</v-icon></v-btn>
          <v-btn color="#bc2a8d" icon><v-icon>mdi-instagram</v-icon></v-btn>
        </div>

        <p style="font-size: 12px" class="mb-0 mt-2">
          Or share your code directly:
        </p>
        <p class="mb-0" style="font-size: 12px">
          <b>{{ userProfile.uid }}</b>
        </p>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
    }),
  },
};
</script>
