var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top"},[_c('v-btn',{staticClass:"px-8",attrs:{"height":"40","elevation":"0","dark":"","loading":_vm.$store.state.loading.media},on:{"click":_vm.trigger}},[_vm._v("Add media")]),_c('input',{ref:"headerRef",attrs:{"type":"file","multiple":"","accept":"image/jpeg, image/png, image/jpg, video/mp4"},on:{"change":_vm.onFileChange,"click":_vm.resetValue}}),_c('v-text-field',{staticStyle:{"border-radius":"8px","max-width":"200px"},style:({
          borderBottom: '2px solid #272727',
          borderLeft: '0.75px solid #272727',
          borderTop: '0.75px solid #272727',
          borderRight: '2px solid #272727',
        }),attrs:{"label":"Search media ...","flat":"","solo":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticStyle:{"border-radius":"8px","max-width":"200px"},style:({
          borderBottom: '2px solid #272727',
          borderLeft: '0.75px solid #272727',
          borderTop: '0.75px solid #272727',
          borderRight: '2px solid #272727',
        }),attrs:{"label":"Tag","solo":"","hide-details":"","dense":"","item-text":"name","return-object":"","flat":"","items":_vm.tags,"clearable":""},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}}),_c('v-btn-toggle',{staticStyle:{"border-radius":"6px"},style:({
          borderBottom: '2px solid #272727',
          borderLeft: '0.75px solid #272727',
          borderTop: '0.75px solid #272727',
          borderRight: '2px solid #272727',
        }),attrs:{"dense":""},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}},[_c('v-btn',{attrs:{"value":"list","height":"38"}},[_c('v-icon',[_vm._v("mdi-list-box")])],1),_c('v-btn',{attrs:{"height":"38","value":"cards"}},[_c('v-icon',[_vm._v("mdi-card-multiple")])],1)],1)],1),(_vm.mode === 'list')?_c('div',{staticClass:"mb-10"},[_c('MediaList',{attrs:{"tag":_vm.tag}})],1):_vm._e(),(_vm.mode === 'cards')?_c('div',{staticClass:"media"},_vm._l((_vm.filtered),function(m){return _c('MediaCard',{key:m.uid,attrs:{"m":m}})}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }