<template>
  <div class="admin">
    <div
      style="padding: 0 44px; width: 100%"
      class="left smoke animate__animated animate__slideInDown"
    >
      <v-tabs background-color="transparent" v-model="tab">
        <v-tabs-slider
          class="mx-auto"
          style="width: 50%; border-radius: 5px 5px 0 0"
        ></v-tabs-slider>

        <v-tab
          style="text-transform: none; font-size: 12px"
          v-for="t in items"
          :key="t.route"
          @click="$router.push(t.route)"
          >{{ t.title }}</v-tab
        >
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <transition name="slide-fade">
      <router-view class="desktop"></router-view
    ></transition>
  </div>
</template>

<script>
export default {
  watch: {
    $route: {
      handler: function (val) {
        if (val.name === "Media") {
          this.tab = 1;
        } else if (val.name === "Posts") {
          this.tab = 0;
        } else if (val.name === "Topics") {
          this.tab = 2;
        } else if (val.name === "Structures") {
          this.tab = 3;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      items: [
        {
          title: "Posts",
          route: "/library/posts",
        },

        {
          title: "Media",
          route: "/library/media",
        },
        {
          title: "Topics",
          route: "/library/topics",
        },
        // {
        //   title: "Templates",
        //   route: "/library/templates",
        // },
        {
          title: "Structures",
          route: "/library/structures",
        },
      ],
      tab: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.animate__animated.animate__slideInDown {
  --animate-duration: 0.5s;
}
.desktop {
  padding: 20px 44px 0 44px;
  max-width: 1800px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
