<template>
  <v-card flat class="primary pa-4 pb-8">
    <div class="d-flex px-2 pb-2" style="gap: 10px">
      <v-btn
        icon
        x-large
        @click="type = 'facebook'"
        v-if="selected_accounts.find((a) => a.type === 'facebook') != undefined"
      >
        <v-icon :color="type === 'facebook' ? 'button' : 'white'"
          >mdi-facebook</v-icon
        >
      </v-btn>
      <v-btn
        v-if="selected_accounts.find((a) => a.type === 'linkedin') != undefined"
        icon
        x-large
      >
        <v-icon :color="type === 'linkedin' ? 'button' : 'white'"
          >mdi-linkedin</v-icon
        >
      </v-btn>
      <v-btn
        icon
        @click="type = 'instagram'"
        x-large
        v-if="
          selected_accounts.find((a) => a.type === 'instagram') != undefined
        "
      >
        <v-icon :color="type === 'instagram' ? 'button' : 'white'"
          >mdi-instagram</v-icon
        >
      </v-btn>
      <v-spacer></v-spacer>
    </div>
    <Facebook
      v-if="type === 'facebook'"
      :account="facebook_account"
      :message="message"
      :comment="comment"
      :image="image"
      :media_type="media_type"
      :preview="preview"
      :id="id"
      :open_link="false"
    />
    <Instagram
      :account="channels.find((a) => a.type === 'instagram')"
      :post="instaPost"
      :comment="comment"
      v-if="type === 'instagram'"
      :open_link="false"
    />
  </v-card>
</template>

<script>
import Facebook from "@/components/preview/PreviewFacebook.vue";
import Instagram from "./instagramPreview.vue";

import { mapState } from "vuex";

export default {
  components: {
    Facebook,
    Instagram,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    channels: {
      type: Array,
      default() {
        return [];
      },
    },
    variations: {
      type: Array,
      default() {
        return [];
      },
    },
    selected_accounts: {
      type: Array,
      default() {
        return [];
      },
    },
    message: {
      type: String,
      default: "This is a test message.",
    },
    image: {
      type: String,
    },
    media_type: {
      type: String,
    },
    preview: {
      type: Object,
      default: null,
    },
    comment: String,
    comments: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    if (this.selected_accounts.length > 0) {
      this.type = this.selected_accounts[0].type;
    }
  },
  watch: {
    selected_accounts: {
      deep: true,
      async handler() {
        if (
          this.selected_accounts.length > 0 &&
          this.selected_accounts.find((a) => a.type === this.type) === undefined
        ) {
          this.type = this.selected_accounts[0].type;
        }
      },
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      accounts: (state) => state.accounts,
    }),
    facebook_account() {
      let a =
        this.selected_accounts.length === 0 ||
        this.selected_accounts.find((a) => a.type === "facebook") === undefined
          ? null
          : this.selected_accounts.find((a) => a.type === "facebook");
      return !a ? null : a;
    },
    instaPost() {
      let post = {
        timestamp: new Date(),
        media_url: this.preview ? this.preview.image : this.image,
        like_count: 34,
        caption: this.message,
      };

      return post;
    },
  },
  data() {
    return {
      type: "facebook",
    };
  },
};
</script>
