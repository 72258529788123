import { db } from "@/firebase";
import { collection, getDocs } from "firebase/firestore";

const chats = {
  state: {
    chats: [],
  },
  mutations: {
    setChats(state, val) {
      state.chats = val;
    },
  },
  actions: {
    async fetchChats({ commit, rootState }) {
      commit("setLoading", { type: "chats", val: true });

      let chats = [];

      const querySnapshot = await getDocs(
        collection(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "chats"
        )
      );
      querySnapshot.forEach(async (doc) => {
        let chat = doc.data();
        chat.messages = [];

        const messagesSnapshot = await getDocs(
          collection(
            db,
            "workspaces",
            rootState.users.userProfile.workspace,
            "chats",
            doc.id,
            "messages"
          )
        );
        messagesSnapshot.forEach((doc) => {
          chat.messages.push(doc.data());
        });

        chat.messages.sort((a, b) =>
          a.created_at < b.created_at ? -1 : b.created_at < a.created_at ? 1 : 0
        );

        chats.push(chat);
      });

      chats.sort((a, b) =>
        a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
      );

      commit("setChats", chats);
      commit("setLoading", { type: "chats", val: false });
    },
  },
  getters: {},
};

export default chats;
