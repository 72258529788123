<template>
  <div>
    <v-card
      flat
      v-if="userProfile"
      max-width="700"
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
    >
      <div>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="
            font-size: 10pt;
            font-weight: 600;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
          "
        >
          Profile
        </v-sheet>
        <v-divider class="primary"></v-divider>
      </div>

      <div class="d-flex pa-6" style="gap: 25px">
        <div>
          <div class="d-flex mb-4" style="gap: 5px">
            <div>
              <p style="font-size: 12px; margin-bottom: 5px">First name</p>
              <v-text-field
                label="First name"
                solo
                flat
                background-color="smoke"
                style="border-radius: 6px"
                hide-details=""
                v-model="userProfile.first_name"
                @change="updateName()"
              ></v-text-field>
            </div>
            <div>
              <p style="font-size: 12px; margin-bottom: 5px">Last name</p>
              <v-text-field
                solo
                flat
                background-color="smoke"
                style="border-radius: 6px"
                label="Last name"
                hide-details=""
                v-model="userProfile.last_name"
                @change="updateName()"
              ></v-text-field>
            </div>
          </div>
          <p style="font-size: 12px; margin-bottom: 5px">Email</p>
          <v-text-field
            solo
            flat
            background-color="smoke"
            style="border-radius: 6px"
            class="mb-4"
            label="Email"
            hide-details=""
            readonly
            v-model="userProfile.email"
          ></v-text-field>

          <p style="font-size: 12px; margin-bottom: 5px">Job title</p>
          <v-text-field
            solo
            flat
            background-color="smoke"
            style="border-radius: 6px"
            class="mb-4"
            label="Job title"
            hide-details=""
            v-model="userProfile.job_title"
            @change="updateTitle()"
          ></v-text-field>

          <p style="font-size: 12px; margin-bottom: 5px">Joined</p>
          <v-text-field
            solo
            flat
            background-color="smoke"
            style="border-radius: 6px"
            class="mb-2"
            label="Joined"
            hide-details=""
            :value="getTime(userProfile.created_at)"
            readonly
          ></v-text-field>
        </div>
        <div>
          <p style="font-size: 12px; margin-bottom: 5px">Emails</p>
          <div class="d-flex" style="gap: 5px">
            <v-switch
              color="accent"
              class="mt-0 pt-0"
              v-model="newsletter"
            ></v-switch>
            <div>
              <p class="mb-0">Newsletter</p>
              <p style="font-size: 12px">New blog posts and more</p>
            </div>
          </div>

          <div class="d-flex" style="gap: 5px">
            <v-switch
              color="accent"
              class="mt-0 pt-0"
              v-model="product"
            ></v-switch>
            <div>
              <p class="mb-0">Product updates</p>
              <p style="font-size: 12px">
                New features, bug fixes, feedback, product updates
              </p>
            </div>
          </div>

          <div class="d-flex" style="gap: 5px">
            <v-switch
              color="accent"
              class="mt-0 pt-0"
              v-model="summary"
            ></v-switch>
            <div>
              <p class="mb-0">Weekly summary</p>
              <p style="font-size: 12px">Summary of your social performances</p>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-btn elevation="0" text class="primary--text mt-4" small
      >Delete account</v-btn
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
    }),
    summary: {
      get() {
        return this.userProfile.preferences.notifications.summary;
      },
      set(value) {
        this.$store.dispatch("updateNotifications", {
          type: "summary",
          val: value,
        });
      },
    },
    product: {
      get() {
        return this.userProfile.preferences.notifications.product;
      },
      set(value) {
        this.$store.dispatch("updateNotifications", {
          type: "product",
          val: value,
        });
      },
    },
    newsletter: {
      get() {
        return this.userProfile.preferences.notifications.newsletter;
      },
      set(value) {
        this.$store.dispatch("updateNotifications", {
          type: "newsletter",
          val: value,
        });
      },
    },
  },
  data() {
    return {
      edit: false,
    };
  },
  methods: {
    getTime(v) {
      return moment(v.toDate()).format("DD-MM-YYYY");
    },
    updateTitle() {
      this.$store.dispatch("updateTitle", this.userProfile.job_title);
    },
    updateName() {
      this.$store.dispatch("updateName", {
        first_name: this.userProfile.first_name,
        last_name: this.userProfile.last_name,
      });
    },
  },
};
</script>
