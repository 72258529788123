<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="admin_users"
      :items-per-page="-1"
      class="smoke"
      hide-default-footer
      :search="search"
      :loading="loading.admin.users"
    >
      <template v-slot:top>
        <div class="d-flex pa-4" style="gap: 10px">
          <v-text-field
            v-model="search"
            label="Search"
            solo
            flat
            style="border-radius: 6px"
            hide-details=""
          ></v-text-field>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="300"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                height="48"
                elevation="0"
                color="primary"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </template>

            <v-card>
              <div class="pa-4">
                <div v-for="(e, index) in emails" :key="index">
                  <v-text-field
                    solo
                    flat
                    hide-details=""
                    v-model="e.email"
                    class="mb-2"
                    background-color="smoke"
                    label="e-mail"
                    style="border-radius: 6px"
                  ></v-text-field>
                </div>

                <div class="d-flex justify-space-between align-center">
                  <v-btn
                    elevation="0"
                    color="button"
                    class="primary--text"
                    @click="addEmail"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                  <v-switch
                    hide-details=""
                    inset
                    label="beta"
                    v-model="beta"
                    class="mt-0"
                  ></v-switch>
                </div>
              </div>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn v-if="!emails_sending" text @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  :loading="emails_sending"
                  color="primary"
                  text
                  @click="send"
                >
                  Send
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="smoke lighten-1" elevation="0" @click="openInFb(item.uid)"
          ><v-icon>mdi-firebase</v-icon></v-btn
        >
      </template>

      <template v-slot:[`item.workspaces`]="{ item }">
        <span class="mb-1" v-for="(w, index) in item.workspaces" :key="w.uid">
          {{ w.about.name
          }}<span v-if="index + 2 < item.workspaces.length">, </span>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapState } from "vuex";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      admin_users: (state) => state.admin.users,
    }),
  },
  data() {
    return {
      beta: true,
      emails: [{ email: "" }],
      emails_sending: false,
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: "Workspaces", value: "workspaces" },
        { text: "", value: "actions" },
      ],
      menu: false,
    };
  },
  methods: {
    addEmail() {
      this.emails.push({ email: "" });
    },
    openInFb(uid) {
      let production = window.location.origin.includes("app.stix.social");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "one-startup-stack" : "stix-staging-f240f"
      }/firestore/data/~2Fusers~2F${uid}`;
      window.open(url, "_blank");
    },
    async send() {
      this.emails_sending = true;
      const functions = getFunctions();
      const invite = httpsCallable(functions, "invite");
      let emails = [];
      this.emails.forEach((e) => emails.push(e.email));
      const res = await invite({
        emails: emails,
        template: this.beta ? "beta-direct-invite" : "direct-invite",
      });

      if (res.data === 200) {
        this.$store.commit("setNotification", {
          show: true,
          text: "Invites sent!",
          type: "primary",
          icon: "",
        });
      }
      this.emails_sending = false;
      this.menu = false;
      this.emails = [{ email: "" }];
    },
  },
};
</script>
