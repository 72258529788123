const medium = {
  state: {
    search: "",
    search_media: "",
    search_template: "",
    loading: false,
    items: [],
    next_page: "",
    show: false,
    tab: 0,
    type: null,
  },
  mutations: {
    setMediumShow(state, val) {
      state.show = val;
    },
    setMediumTab(state, val) {
      state.tab = val;
    },
    setMediumSearch(state, val) {
      state.search = val;
    },
    setMediumSearchLoading(state, val) {
      state.loading = val;
    },
    setMediumSearchMedia(state, val) {
      state.search_media = val;
    },
    setMediumSearchTemplate(state, val) {
      state.search_template = val;
    },
    setMediumNextPage(state, val) {
      state.next_page = val;
    },
    setMediumItems(state, val) {
      state.items = val;
    },
    setMediumType(state, val) {
      state.type = val;
      state.show = true;
    },
    clearMedium(state) {
      state.search = "";
      state.loading = false;
      state.items = [];
      state.next_page = "";
      state.show = false;
      state.tab = 0;
      state.search_media = "";
      state.search_template = "";
    },
  },
  actions: {
    async selectMedia({ commit, state }, val) {
      if (state.type === "template.background") {
        commit("setTemplateBackground", val);
        commit("setMediumShow", false);
      } else if (state.type.includes("template.block")) {
        commit("setTemplateBlock", {
          index: Number(state.type.split(".")[2]),
          ...val,
        });
        commit("setMediumShow", false);
      } else if (state.type === "post") {
        commit("setPostMedia", val);
        commit("setMediumShow", false);
      }
    },
  },
  getters: {},
};

export default medium;
