var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"500","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.$store.state.users.userProfile.role != 'admin',"elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-account-plus")]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Invite users")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }),attrs:{"flat":"","width":"500"}},[_c('div',{staticClass:"pa-6"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Invite users")]),_vm._l((_vm.invites),function(invite,index){return _c('div',{key:index,staticClass:"email mb-2"},[_c('v-text-field',{staticStyle:{"border-radius":"6px"},attrs:{"placeholder":"name@company.com","hide-details":"","solo":"","flat":"","background-color":"smoke"},model:{value:(invite.email),callback:function ($$v) {_vm.$set(invite, "email", $$v)},expression:"invite.email"}}),_c('v-select',{staticStyle:{"border-radius":"6px"},attrs:{"label":"Role","solo":"","flat":"","hide-details":"","background-color":"smoke","items":_vm.filteredRoles},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.capitalize(item))+" ")]}},{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.capitalize(item))+" ")]}}],null,true),model:{value:(invite.role),callback:function ($$v) {_vm.$set(invite, "role", $$v)},expression:"invite.role"}})],1)}),_c('div',{staticClass:"d-flex justify-space-between mt-6"},[_c('v-btn',{attrs:{"elevation":"0"},on:{"click":_vm.addInvite}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('v-btn',{staticClass:"px-6",attrs:{"elevation":"0","text":""},on:{"click":function($event){_vm.sent = false;
                  _vm.dialog = false;}}},[_vm._v("Close")]),_c('v-btn',{staticClass:"px-6",attrs:{"elevation":"0","color":"button"},on:{"click":function($event){return _vm.invite()}}},[_vm._v("Send")])],1)],1)],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }