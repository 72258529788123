<template>
  <div class="ma-10" style="max-width: 800px">
    <h1 class="mb-6">Settings</h1>

    <div class="calendar">
      <h2>Calendar</h2>
      <div class="d-flex align-center" v-if="calendar && calendar.length > 0">
        <p class="mb-0">
          Calendar ends:
          <u>
            {{
              calendar[calendar.length - 1].days[
                calendar[calendar.length - 1].days.length - 1
              ].text
            }}
            {{
              calendar[calendar.length - 1].days[
                calendar[calendar.length - 1].days.length - 1
              ].month_string
            }}</u
          >. Add more days to the calendar.
        </p>

        <v-spacer></v-spacer>
        <v-text-field
          hide-details=""
          solo
          v-model="n"
          flat
          dense
          style="max-width: 90px; border-radius: 6px"
          type="number"
        ></v-text-field>
        <v-btn
          elevation="0"
          class="ml-2"
          dark
          color="primary"
          @click="fillCalendar"
          :loading="processing"
          >Add</v-btn
        >
      </div>
    </div>

    <!-- <div class="mt-4">
      <h2 class="mb-2">Open AI</h2>

      <v-select
        solo
        flat
        style="border-radius: 6px; max-width: 150px"
        :items="['complete', 'translate']"
        hide-details=""
        v-model="open_ai_tabs"
        class="mb-2"
        dense
      ></v-select>

      <div class="d-flex mb-2" style="gap: 15px">
        <v-select
          solo
          dense
          flat
          value="system"
          disabled
          style="border-radius: 6px; max-width: 150px"
          :items="['assistant', 'user', 'system']"
          hide-details=""
        ></v-select>
        <v-text-field
          solo
          dense
          flat
          style="border-radius: 6px"
          hide-details=""
        ></v-text-field>
      </div>
      <div class="d-flex mb-2" style="gap: 15px">
        <v-select
          solo
          dense
          flat
          hide-details=""
          style="border-radius: 6px; max-width: 150px"
          :items="['assistant', 'user']"
        ></v-select>
        <v-text-field
          solo
          flat
          dense
          style="border-radius: 6px"
          hide-details=""
        ></v-text-field>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      calendar: (state) => state.schedules.calendar,
    }),
  },
  data() {
    return {
      n: 7,
      open_ai_tabs: "complete",
      processing: false,
    };
  },
  methods: {
    async fillCalendar() {
      this.processing = true;
      const functions = getFunctions();
      const extendCalendar = httpsCallable(functions, "extendCalendar");
      await extendCalendar({ n: this.n });
      this.processing = false;
      this.$store.commit("setNotification", {
        show: true,
        text: `Days added to calendar!`,
        type: "primary",
        icon: "",
      });
    },
  },
};
</script>
