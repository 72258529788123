var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('NotificationDesktop'),_c('FeedbackForm'),_c('OpenAIDesktop'),_c('Queue'),_c('Media'),_c('Preview'),_c('Topics'),_c('Loading'),(!_vm.$vuetify.breakpoint.smAndDown)?_c('NavigationDrawer'):_vm._e(),(!_vm.$route.meta.noNav)?_c('AppBar',{on:{"queue_drawer":function($event){_vm.queue_drawer = true}}}):_vm._e(),_c('v-main',{staticStyle:{"background-color":"#f0f0f0"}},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('router-view',{class:{
            mobile:
              _vm.$vuetify.breakpoint.smAndDown &&
              _vm.$route.path != '/inbox' &&
              _vm.$route.path != '/access' &&
              _vm.$route.path != '/verify' &&
              _vm.$route.path != '/reset',
            desktop:
              !_vm.$vuetify.breakpoint.smAndDown &&
              _vm.$route.path != '/access' &&
              _vm.$route.path != '/calendar' &&
              _vm.$route.path != '/feed' &&
              _vm.$route.path != '/chat' &&
              _vm.$route.path != '/join' &&
              _vm.$route.path != '/create-workspace' &&
              _vm.$route.path != '/verify' &&
              _vm.$route.path != '/reset' &&
              !_vm.$route.path.includes('/library') &&
              !_vm.$route.path.includes('/settings') &&
              !_vm.$route.path.includes('/admin'),
          }})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }