var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"py-6 px-8",style:({
        borderBottom: '3px solid' + _vm.category.color,
        borderRight: '3px solid' + _vm.category.color,
        borderLeft: '1px solid' + _vm.category.color,
        borderTop: '1px solid' + _vm.category.color,
      }),attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : 344,"outlined":"","elevation":"0"}},[_c('div',{staticClass:"d-flex flex-column",class:{ 'justify-center': _vm.confirm, 'justify-start': !_vm.confirm },staticStyle:{"height":"100%"}},[(_vm.confirm)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Are you sure?")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('v-btn',{attrs:{"elevation":"0","loading":_vm.deleting,"color":"button"},on:{"click":function($event){return _vm.deleteCategory()}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.confirm = false}}},[_vm._v("No")])],1)]):_vm._e(),(!_vm.confirm)?_c('div',{staticClass:"d-flex align-start justify-space-between"},[_c('div',[_c('h2',{staticClass:"mb-1"},[_c('div',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"15","color":_vm.category.color}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(_vm.category.name)+" ")],1)]),_c('p',{staticClass:"mb-2",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.category.description)+" ")]),_c('p',{staticClass:"mb-1 font-weight-regular",staticStyle:{"font-size":"14px","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push(`/library/posts?category=${_vm.category.uid}`)}}},[_vm._v(" 👉 "),_c('u',[_vm._v(_vm._s(_vm.category.counter.posts)+" post"),(
                    _vm.category.counter.posts > 1 || _vm.category.counter.posts === 0
                  )?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(" in this category.")])]),_c('p',{staticClass:"mb-0 font-weight-regular",staticStyle:{"font-size":"14px","cursor":"pointer"}},[(
                  _vm.schedules.filter((s) => s.category.uid === _vm.category.uid)
                    .length < 1
                )?_c('span',{on:{"click":function($event){return _vm.$router.push(`/schedule?new=true&category=${_vm.category.uid}`)}}},[_vm._v(" 👉 "),_c('u',[_vm._v(" Add schedule")])]):_c('u',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"success darken-2"}},[_vm._v("mdi-check-circle")]),_vm._v("Schedule active")],1)])]),_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-list-item',{staticClass:"px-4",attrs:{"link":""},on:{"click":function($event){return _vm.$router.push(`/post?category=${_vm.category.uid}`)}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":_vm.category.color}},[_vm._v("mdi-plus")]),_c('v-list-item-title',[_vm._v("Add post")])],1),_c('v-list-item',{staticClass:"px-4",attrs:{"link":""},on:{"click":function($event){return _vm.$router.push(`/library/posts?category=${_vm.category.uid}`)}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":_vm.category.color}},[_vm._v("mdi-eye")]),_c('v-list-item-title',[_vm._v("View posts")])],1),(_vm.category.name != 'General')?_c('v-list-item',{staticClass:"px-4",attrs:{"link":""}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":_vm.category.color}},[_vm._v("mdi-pencil")]),_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e(),(_vm.category.name != 'General')?_c('v-list-item',{staticClass:"px-4",attrs:{"link":""},on:{"click":function($event){_vm.confirm = true}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":_vm.category.color}},[_vm._v("mdi-delete")]),_c('v-list-item-title',[_vm._v("Delete")])],1):_vm._e()],1)],1)],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }