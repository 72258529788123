<template>
  <div class="d-flex start">
    <v-menu transition="slide-x-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
            borderRadius: '8px',
          }"
        >
          <v-btn
            class="right-button"
            v-bind="attrs"
            v-on="on"
            elevation="0"
            style="border-radius: 6px"
            color="button"
            width="180"
            >Start
          </v-btn>
        </div>
      </template>
      <v-list class="py-0">
        <v-list-item
          @click="action(i.action)"
          link
          v-for="i in items"
          :key="i.text"
          :to="i.route"
        >
          <v-list-item-icon>
            <v-icon v-if="i.icon" size="20" color="primary">{{
              i.icon
            }}</v-icon>
            <v-img
              v-if="i.logo"
              max-width="20"
              height="20"
              :src="require(`@/assets/${i.logo}`)"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-title>{{ i.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>
.right-button .v-input__slot::before {
  border-bottom: "3px solid #272727";
  border-left: "1px solid #272727";
  border-top: "1px solid #272727";
  border-right: "3px solid #272727";
}
</style>

<script>
export default {
  computed: {},
  data() {
    return {
      items: [
        {
          text: "Add post",
          icon: "mdi-note",
          route: "/post",
        },
        {
          text: "Add topic",
          icon: "mdi-lightbulb",
          route: "/library/topics?new=true",
        },
        {
          text: "Add category",
          icon: "mdi-shape",
          route: "/categories?new=true",
        },
        {
          text: "Add account",
          icon: "mdi-facebook",
          route: "/accounts?new=true",
        },
        {
          text: "Add users",
          icon: "mdi-account-plus",
          route: "/settings/users?invite=true",
        },
      ],
    };
  },
  methods: {
    action(e) {
      if (e && e === "openai-topics") {
        this.$store.commit("setOpenai", true);
      } else if (e && e === "openai-post") {
        this.$store.commit("setOpenai", true);
      }
    },
  },
};
</script>
