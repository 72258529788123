<template>
  <div>
    <v-btn
      icon
      @click="
        $emit('open');
        menu = false;
      "
      v-if="!link_preview"
    >
      <v-icon size="20" color="primary"> mdi-link-variant-plus</v-icon>
    </v-btn>
    <v-menu
      v-else
      v-model="menu"
      :close-on-content-click="true"
      :nudge-width="150"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon size="20" color="primary">mdi-link-box-variant</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list dense>
          <v-list-item dense @click="$emit('remove')">Remove link </v-list-item>
          <v-list-item @click="$emit('open')">Replace link </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-dialog persistent :value="sheet" width="500">
      <v-card
        flat
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
        class="pb-6"
        width="500"
      >
        <div>
          <v-sheet
            class="d-flex justify-center align-center py-2"
            color="accent"
            style="
              font-size: 10pt;
              font-weight: 600;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            "
          >
            Add a link to my post
          </v-sheet>
          <v-divider class="primary"></v-divider>
        </div>
        <v-window v-model="link_step">
          <v-window-item :value="0" class="linkone">
            <div class="pa-6" style="width: 95%">
              <v-text-field
                placeholder="Paste link here.."
                flat
                solo
                hide-details=""
                background-color="smoke"
                style="border-radius: 8px"
                v-model="link"
                autofocus
                @keypress="enter"
              ></v-text-field>
              <v-checkbox
                label="Write post based on the content"
                hide-details=""
                v-model="write_post"
              ></v-checkbox>
            </div>
            <div class="d-flex justify-center" style="gap: 10px">
              <v-btn
                elevation="0"
                :disabled="link === ''"
                color="button"
                @click="next()"
                >Continue</v-btn
              >
              <v-btn @click="$emit('close')" elevation="0" color="primary" text>
                Close
              </v-btn>
            </div>
          </v-window-item>

          <v-window-item :value="2" class="linkone">
            <p style="font-size: 12px" class="mb-1 pa-0">
              Fetching data from url..
            </p>

            <v-progress-linear
              indeterminate
              color="primary"
              style="width: 100px"
              rounded
            ></v-progress-linear>
          </v-window-item>

          <v-window-item :value="3" class="linkone" @click="success">
            <p style="font-size: 12px" class="mb-1 pa-0">
              Link added succesfully
            </p>
            <v-icon large color="success darken-2">mdi-check-circle</v-icon>
          </v-window-item>

          <v-window-item :value="1" class="linkone">
            <div class="pa-6" style="width: 95%">
              <p style="font-size: 12px" class="mb-1 pa-0">Instructions:</p>
              <v-textarea
                placeholder="Use informative tone. Maximum 500 words. Structure like this... Etc."
                flat
                solo
                hide-details=""
                background-color="smoke"
                style="border-radius: 8px"
                v-model="instructions"
                autofocus
                rows="2"
              ></v-textarea>
            </div>
            <div class="d-flex justify-center" style="gap: 10px">
              <v-btn
                elevation="0"
                :disabled="link === ''"
                color="button"
                @click="
                  getPreview();
                  link_step = 2;
                "
                >Continue</v-btn
              >
              <v-btn
                @click="
                  $emit('close');
                  link_step = 0;
                  link = '';
                  write_post = false;
                "
                elevation="0"
                color="primary"
                text
              >
                Close
              </v-btn>
            </div>
          </v-window-item>
        </v-window>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import { languages } from "@/mixins/languages";

export default {
  props: ["sheet", "link_preview"],
  mixins: [languages],
  data() {
    return {
      instructions: "",
      menu: false,
      link: "",
      link_step: 0,
      preview_loading: false,
      write_post: false,
    };
  },
  methods: {
    next() {
      if (this.link_step === 0 && this.write_post) {
        this.link_step = 1;
      } else {
        this.getPreview();
      }
    },
    enter(v) {
      if (v.code === "Enter") {
        this.next();
      }
    },
    async getPreview() {
      let prefix = "https://";
      let link = this.link;
      this.link_step = 2;
      if (this.link != "") {
        if (!link.includes("http")) {
          link = prefix.concat(this.link);
        }
        console.log(link);
        this.preview_loading = true;
        const functions = getFunctions();
        const getPreview = httpsCallable(functions, "getPreview");
        const res = await getPreview({
          url: link,
        });
        if (res.data.error) {
          this.$store.commit("setNotification", {
            show: true,
            text: "Something went wrong. Try another URL.",
            type: "error",
            icon: "",
          });
        } else {
          let result = res.data;
          result.write_post = this.write_post;
          result.instructions = this.instructions;
          this.$emit("preview", result);
        }

        this.preview_loading = false;
        this.link = "";
        this.link_step = 0;
        this.write_post = false;
      } else {
        this.$store.commit("setNotification", {
          show: true,
          text: "Incorrect link",
          type: "error",
          icon: "",
        });
      }
    },
    success() {
      this.link_step = 1;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  border-radius: 10px;
}

.linkone {
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
