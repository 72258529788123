import { render, staticRenderFns } from "./social_schedule.vue?vue&type=template&id=6892028c&scoped=true&"
import script from "./social_schedule.vue?vue&type=script&lang=js&"
export * from "./social_schedule.vue?vue&type=script&lang=js&"
import style0 from "./social_schedule.vue?vue&type=style&index=0&id=6892028c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6892028c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VChip,VIcon})
