<template>
  <div>
    <v-bottom-sheet v-model="menu" v-if="$vuetify.breakpoint.smAndDown">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="primary" elevation="0" dark
          >Add category</v-btn
        >
      </template>
      <v-sheet class="text-center" height="240px" color="#f0f0f0">
        <div class="pt-6 pb-4 px-6">
          <v-text-field
            placeholder="Category name here.."
            flat
            solo
            hide-details=""
            v-model="new_category.name"
            :style="{
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }"
            style="border-radius: 8px"
          ></v-text-field>
          <h3 class="mt-4">Pick category color</h3>
          <v-btn
            class="mt-2"
            v-bind:class="{ smoke: new_category.color === c }"
            icon
            v-for="(c, index) in colors"
            :key="index"
            @click="new_category.color = c"
            ><v-icon :color="c">{{
              c === new_category.color ? "mdi-check-circle" : "mdi-circle"
            }}</v-icon></v-btn
          >
        </div>
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn elevation="0" color="button" @click="addCategory"
            >Add category</v-btn
          >
          <v-btn @click="menu = !menu" elevation="0" color="primary" text
            >Cancel</v-btn
          >
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <v-btn
      v-if="!$vuetify.breakpoint.smAndDown && type === 'button'"
      @click="menu = true"
      elevation="0"
      color="primary"
      >Add category</v-btn
    >
    <v-btn
      @click="menu = true"
      icon
      small
      v-if="!$vuetify.breakpoint.smAndDown && type === 'plus'"
      ><v-icon small>mdi-plus</v-icon></v-btn
    >
    <v-btn
      @click="menu = true"
      icon
      small
      v-if="!$vuetify.breakpoint.smAndDown && type === 'list'"
      ><v-icon small>mdi-plus</v-icon></v-btn
    >

    <v-dialog
      persistent
      width="350"
      transition="dialog-bottom-transition"
      v-if="!$vuetify.breakpoint.smAndDown"
      :value="menu"
    >
      <v-card
        flat
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <div class="px-6 py-6">
          <v-text-field
            placeholder="Category name"
            solo
            class="mb-2"
            flat
            background-color="smoke"
            hide-details=""
            v-model="new_category.name"
          ></v-text-field>
          <v-text-field
            placeholder="Short description"
            solo
            class="mb-4"
            flat
            background-color="smoke"
            hide-details=""
            v-model="new_category.description"
          ></v-text-field>
          <v-btn
            v-bind:class="{ smoke: new_category.color === c }"
            icon
            v-for="(c, index) in colors"
            :key="index"
            @click="new_category.color = c"
            ><v-icon :color="c">{{
              c === new_category.color ? "mdi-check-circle" : "mdi-circle"
            }}</v-icon></v-btn
          >
        </div>

        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn text @click="menu = false">Cancel</v-btn>
          <v-btn
            elevation="0"
            color="button"
            :loading="saving"
            @click="addCategory"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { doc, updateDoc, addDoc, collection } from "firebase/firestore";
import { db } from "@/firebase";

export default {
  props: ["type"],
  data() {
    return {
      colors: [
        "#272727",
        "#67d6fa",
        "#cfb8ff",
        "#fa7a68",
        "#ffd978",
        "#c9fa75",
        "#b6b6b6",
        "#191919",
      ],
      menu: false,
      new_category: {
        name: "",
        color: "#0BDB90",
        description: "",
        deletable: true,
        counter: {
          posts: 0,
        },
        schedules: [],
      },
      saving: false,
    };
  },
  methods: {
    async addCategory() {
      this.$store.commit("setLoading", { type: "categories", val: false });
      this.saving = true;

      // TODO CHECK IF NAME ALREADY EXISTS

      if (this.new_category.name) {
        const docRef = await addDoc(
          collection(
            db,
            "workspaces",
            this.$store.state.users.userProfile.workspace,
            "categories"
          ),
          { ...this.new_category, created_at: new Date() }
        );

        const updateRef = doc(
          db,
          "workspaces",
          this.$store.state.users.userProfile.workspace,
          "categories",
          docRef.id
        );

        // Set the "capital" field of the city 'DC'
        await updateDoc(updateRef, {
          uid: docRef.id,
        });

        await this.$store.dispatch("fetchCategories");
        this.new_category = {
          name: "",
          color: "#0BDB90",
          description: "",
          deletable: true,
          counter: {
            posts: 0,
          },
          schedules: [],
        };
        this.saving = false;
        this.menu = false;
        this.$emit("new", docRef.id);
      } else {
        console.log("No new category input or already exists");
      }
      this.$store.commit("setLoading", { type: "categories", val: false });
    },
    open() {
      this.menu = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  border-radius: 10px;
}
</style>
