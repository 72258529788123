import { db } from "@/firebase";
import { getDocs, collection, doc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

const media = {
  state: {
    media: [],
    tags: [],
  },
  mutations: {
    setMedia(state, val) {
      state.media = val;
    },
    removeMedia(state, val) {
      const index = state.media.findIndex((m) => m.uid === val);
      state.media.splice(index, 1);
    },
    setMediaTags(state, val) {
      state.tags = val;
    },
  },
  actions: {
    async fetchMedia({ commit, rootState }) {
      commit("setLoading", { type: "media", val: true });

      let media = [];
      let tags = [];

      const querySnapshot = await getDocs(
        collection(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "media"
        )
      );
      querySnapshot.forEach((doc) => {
        media.push(doc.data());
        if (doc.data().tags && doc.data().tags.length > 0) {
          doc.data().tags.forEach((t) => {
            if (!tags.includes(t)) {
              tags.push(t);
            }
          });
        }
      });

      media.sort((a, b) =>
        a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
      );

      tags.sort();

      commit("setMediaTags", tags);
      commit("setMedia", media);
      commit("setLoading", { type: "media", val: false });
    },
    async deleteMedia({ commit, state }, uid) {
      const i = state.media.find((m) => m.uid === uid);
      if (i.posts.length > 0 || i.templates.length > 0) {
        commit("setNotification", {
          show: true,
          text: "This file cannot be removed because it is used in posts or templates.",
          type: "error",
          icon: "",
          multiline: true,
        });
      } else {
        console.log(i);
        const storage = getStorage();

        const storageRef = ref(
          storage,
          `${i.workspace}/media/${i.uid}.${i.extension}`
        );

        deleteObject(storageRef)
          .then(async () => {
            await deleteDoc(doc(db, "workspaces", i.workspace, "media", i.uid));

            commit("removeMedia", uid);
          })
          .then(() => {
            commit("setNotification", {
              show: true,
              text: "File deleted!",
              type: "primary",
              icon: "",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  getters: {},
};

export default media;
