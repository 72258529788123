<template>
  <div>
    <v-system-bar
      height="40"
      absolute
      color="primary"
      class="d-flex justify-center align-center"
    >
      <p style="font-size: 10pt; cursor: pointer" class="mb-0 white--text">
        <u>www.stix.social</u>
      </p>
    </v-system-bar>
    <div class="demo">
      <div class="left">
        <div class="d-flex align-center mb-4" style="gap: 5px">
          <v-img
            max-width="40"
            max-height="40"
            contain
            :src="require('@/assets/logo/logo-dark.svg')"
          ></v-img>
          <span class="mx-2"><b>&</b></span>
          <v-img
            max-width="40"
            max-height="40"
            contain
            :src="require('@/assets/logo/logo-dark.svg')"
          ></v-img>
        </div>

        <v-card
          flat
          outlined
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
        >
          <v-sheet
            class="d-flex justify-center align-center py-2"
            color="accent"
            style="font-size: 10pt; font-weight: 600"
          >
            Note
          </v-sheet>
          <v-divider class="primary"></v-divider>
          <div class="pa-4">
            <p style="font-weight: 600; font-size: 10pt" class="mb-2">
              Hi {{ target.first_name }},
            </p>
            <p style="font-size: 10pt" class="mb-2">
              As we spoke before, I’ve prepared a custom demo setup for
              {{ target.company }}. Let’s talk after you have checked this out!.
            </p>
            <p style="font-size: 10pt" class="mb-0">
              Cheers, <br />
              Max
            </p>
          </div>
        </v-card>

        <v-card
          class="mt-4"
          flat
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
            borderRadius: '10px',
          }"
        >
          <v-list dense class="py-0">
            <v-list-item-group v-model="menuItem" color="primary">
              <v-list-item
                @click="$router.push(item.route)"
                link
                v-for="(item, i) in items"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>

        <div
          class="mt-4"
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
            borderRadius: '10px',
          }"
          @click="$router.push('/access')"
        >
          <v-btn
            block
            elevation="0"
            color="button"
            class="primary--text"
            style="font-size: 10pt"
            >Create my account</v-btn
          >
        </div>
        <v-btn text class="mt-2" small
          >Share with my colleagues
          <v-icon small class="ml-2">mdi-share</v-icon></v-btn
        >

        <v-spacer></v-spacer>
        <p style="font-size: 10pt">
          Questions?
          <u>max@stix.social</u>
        </p>
      </div>
      <transition name="slide-fade">
        <router-view class="desktop"></router-view
      ></transition>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    $route: {
      handler: function (val) {
        console.log(val);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      target: {
        first_name: "James",
        company: "Braingineers",
      },
      items: [
        {
          title: "Intro",
          route: "/demo/intro",
        },
        {
          title: "Generate topics",
          route: "/demo/topics",
        },
        {
          title: "Writing posts",
          route: "/demo/posts",
        },
        {
          title: "Generate variants",
          route: "/demo/variants",
        },
        {
          title: "Scheduling",
          route: "/demo/scheduling",
        },
        {
          title: "Pricing & Offer",
          route: "/demo/offer",
        },
      ],
      menuItem: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.demo {
  padding-top: 50px;
  display: grid;
  grid-template-columns: 250px auto;
  gap: 25px;

  .left {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
