var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription"},[_c('div',[(_vm.workspace)?_c('v-card',{staticClass:"mb-4",style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }),attrs:{"flat":"","max-width":"500"}},[_c('div',[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600","border-top-right-radius":"7px","border-top-left-radius":"7px"},attrs:{"color":"accent"}},[_vm._v(" Subscription ")]),_c('v-divider',{staticClass:"primary"})],1),_c('div',{staticClass:"d-flex align-center justify-space-between px-6 py-4"},[_c('div',[_c('h2',[_vm._v(" Subscription "),(_vm.workspace.subscription.active)?_c('v-chip',{staticClass:"ml-2 font-weight-regular",attrs:{"small":"","color":"success"}},[_vm._v("Active")]):_vm._e()],1),_c('p',{staticClass:"ma-0 mt-1",staticStyle:{"font-size":"14px"}},[_vm._v(" You're currently on the paid plan. ")])]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""}},[_vm._v("Cancel")])],1),(
            _vm.workspace.subscription.discount_code ||
            _vm.workspace.subscription.discount_value
          )?_c('div',[_c('v-divider'),_c('div',{staticClass:"px-6 py-4"},[_c('p',{staticClass:"ma-0",staticStyle:{"font-size":"14px"}},[_c('b',[_vm._v("Discount"),(_vm.workspace.subscription.discount_code === 'STARTUP')?_c('span',[_vm._v(" Startup Program")]):_vm._e()]),_vm._v(": $"+_vm._s(_vm.workspace.subscription.discount_value)+" monthly: "),_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v("$89")]),_c('v-icon',{staticClass:"ml-1 pb-1",attrs:{"x-small":""}},[_vm._v("mdi-arrow-right")]),_vm._v(" $"+_vm._s(89 - _vm.workspace.subscription.discount_value)+" ")],1)])],1):_vm._e()]):_vm._e(),(_vm.workspace)?_c('v-card',{staticClass:"mb-4",style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }),attrs:{"flat":"","max-width":"500"}},[_c('div',[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600","border-top-right-radius":"7px","border-top-left-radius":"7px"},attrs:{"color":"accent"}},[_vm._v(" Invoices ")]),_c('v-divider',{staticClass:"primary"})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headersSub,"items":_vm.itemsSub,"hide-default-header":"","mobile-breakpoint":"100","hide-default-footer":_vm.itemsSub.length > 0},scopedSlots:_vm._u([{key:`item.amount`,fn:function({ item }){return [_c('span',[_vm._v("$"+_vm._s(item.amount))])]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","color":item.status === 'paid' ? 'success' : 'info'}},[_vm._v(_vm._s(_vm.capitalize(item.status)))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.download(item)}}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-download ")])],1)]}}],null,true)}),_c('div',[_c('v-divider'),_c('div',{staticClass:"px-6 pb-2"},[_c('p',{staticClass:"mb-2 mt-4",staticStyle:{"font-size":"10px"}},[_vm._v("Send invoices to:")]),_c('v-text-field',{staticClass:"mb-2",staticStyle:{"border-radius":"8px","font-size":"10pt"},attrs:{"label":"invoices@yourcompany.com","hide-details":"","solo":"","dense":"","flat":"","background-color":"smoke"},on:{"keypress":_vm.enter},model:{value:(_vm.workspace.subscription.invoices_email),callback:function ($$v) {_vm.$set(_vm.workspace.subscription, "invoices_email", $$v)},expression:"workspace.subscription.invoices_email"}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"color":"primary","small":"","elevation":"0"},on:{"click":_vm.updateEmail}},[_vm._v("Save")])],1)],2)],1)],1)],1)]):_vm._e(),_c('v-card',{staticClass:"mt-4",style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }),attrs:{"max-width":"500","flat":""}},[_c('div',[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600","border-top-right-radius":"7px","border-top-left-radius":"7px"},attrs:{"color":"accent"}},[_vm._v(" Startup program ")]),_c('v-divider',{staticClass:"primary"})],1),_c('div',{staticClass:"startup"},[_c('v-img',{attrs:{"width":"100","contain":"","src":require("@/assets/icons/rocket.jpg")}}),_c('div',{staticClass:"py-6 pl-2 pr-6"},[_c('p',{staticClass:"mb-1",staticStyle:{"font-size":"10px"}},[_vm._v("STARTUP PROGRAM")]),_c('h1',[_vm._v("Get 79% discount as a startup")]),_c('p',{staticClass:"mb-1",staticStyle:{"font-size":"12px"}},[_vm._v(" Startups younger than "),_c('b',[_vm._v("10")]),_vm._v(" years are eligable for the program. Apply today for your discount. ")]),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","small":"","elevation":"0"}},[_vm._v("Apply")])],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }