<template>
  <div>
    <v-menu transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon
          v-bind="attrs"
          v-on="on"
          v-if="$vuetify.breakpoint.smAndDown"
        ></v-app-bar-nav-icon>
      </template>
      <v-list
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
          borderRadius: '6px',
        }"
      >
        <v-list-item
          @click="$router.push(item.route)"
          link
          v-for="(item, i) in items"
          :key="i"
          class="px-4"
        >
          <v-icon small color="primary" class="mr-3">{{ item.icon }}</v-icon>

          <v-list-item-title>{{ item.title }} </v-list-item-title>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
        <v-list-item link class="px-4" @click="logout">
          <v-icon small color="primary" class="mr-3">mdi-logout</v-icon>

          <v-list-item-title>Sign out </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Dashboard", route: "/", icon: "mdi-view-dashboard" },
        // {
        //   title: "Analytics",
        //   route: "/analytics",
        //   icon: "mdi-chart-line",
        // },
        // { title: "Inbox", route: "/inbox", icon: "mdi-email", badge: "11" },
        {
          title: "Posts",
          route: "/posts",
          icon: "mdi-note-multiple",
        },
        {
          title: "Schedules",
          route: "/schedule",
          icon: "mdi-calendar-arrow-right",
        },
        { title: "Categories", route: "/categories", icon: "mdi-shape" },
        {
          title: "Accounts",
          route: "/accounts",
          icon: "mdi-account-multiple",
        },

        {
          title: "Workspace",
          route: "/workspace",
          icon: "mdi-office-building-cog",
        },
        { title: "My account", route: "/profile", icon: "mdi-account" },
      ],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
