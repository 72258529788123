var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500","value":_vm.dialog,"transition":"dialog-bottom-transition"}},[(_vm.dialog)?_c('v-card',{style:({
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }),attrs:{"flat":"","width":"500"}},[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600"},attrs:{"color":"accent"}},[_vm._v(" Edit template ")]),_c('v-divider',{staticClass:"primary"}),_c('div',{staticClass:"pa-6"},[_vm._l((_vm.template.blocks),function(t,index){return _c('div',{key:index},[_c('v-text-field',{model:{value:(t.text),callback:function ($$v) {_vm.$set(t, "text", $$v)},expression:"t.text"}})],1)}),_c('v-btn',{on:{"click":_vm.save}},[_vm._v("save")])],2),_c('v-divider',{staticClass:"primary"}),_c('v-img',{attrs:{"src":_vm.template.background.url,"alt":"","aspect-ratio":_vm.template.aspect_ratio,"id":"my-node"}},[_c('div',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column","border":"1px solid black"},style:({
            padding: `${_vm.template.padding.y}px ${_vm.template.padding.x}px`,
            'justify-content': _vm.template.align_items,
            'align-items': _vm.template.justify_content,
          })},_vm._l((_vm.template.blocks),function(b,index){return _c('div',{key:index,staticStyle:{"width":"100%"}},[(b.type === 'text')?_c('p',{style:({
                color: b.color,
                'line-height': b.line_height + '%',
                'font-size': b.font.size + 'px',
                'font-weight': b.font.weight,
                'font-style':
                  b.format && b.format.includes('italic') ? 'italic' : '',
                'margin-bottom': b.margin.bottom + 'px',
                'margin-top': b.margin.top + 'px',
                'margin-left': b.margin.left + 'px',
                'margin-right': b.margin.right + 'px',
                'text-align': b.text_align,
                'text-decoration':
                  b.format && b.format.includes('underline') ? 'underline' : '',
              })},[_vm._v(" "+_vm._s(b.text)+" ")]):_vm._e(),(b.type === 'image')?_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"},style:({ 'justify-content': b.justify })},[_c('img',{style:({ width: b.width + '%' }),attrs:{"src":b.media && b.media.url ? b.media.url : '',"alt":""}})]):_vm._e()])}),0)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }