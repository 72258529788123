<template>
  <div
    class="signup-container"
    :class="{
      mob: $vuetify.breakpoint.smAndDown,
      desk: !$vuetify.breakpoint.smAndDown,
    }"
  >
    <v-overlay
      :value="overlay"
      color="button"
      opacity="1"
      v-if="overlay"
      class="animate__animated animate__slideInRight text-center d-flex justify-center align-center flex-column"
    >
      <p class="primary--text mb-4">Creating your awesome workspace..</p>
      <v-progress-linear
        rounded
        indeterminate
        color="primary"
        style="width: 100px"
        class="mx-auto"
      ></v-progress-linear>
    </v-overlay>
    <div class="left">
      <div class="logo">
        <v-img src="@/assets/icon.svg" contain></v-img>
        <b style="font-size: 18px">Stix</b>
      </div>

      <div
        class="sub-container form"
        :style="{
          width: $vuetify.breakpoint.smAndDown ? '80%' : '60%',
          paddingBottom: $vuetify.breakpoint.smAndDown ? '0' : '130px',
          marginTop: $vuetify.breakpoint.smAndDown ? '30px' : '130px',
        }"
      >
        <div>
          <h1
            :style="{
              fontSize: $vuetify.breakpoint.smAndDown ? '35px' : '40px',
            }"
          >
            Verify your email
          </h1>
          <p>
            An email has been sent to {{ email }}. Click the button in the
            e-mail to continue.
          </p>

          <v-btn elevation="0" height="50" class="mt-4">Resend e-mail</v-btn>
        </div>
      </div>
    </div>
    <div class="button" v-if="!$vuetify.breakpoint.smAndDown"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.$route.params.email) {
      this.email = this.$route.params.email;
    }
  },
  data() {
    return {
      email: "",
      overlay: false,
    };
  },
};
</script>

<style scoped lang="scss">
.signup-container {
  height: 100vh;
  background-color: white;

  &.desk {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .left {
    display: grid;
    grid-template-rows: 100px auto;

    .logo {
      display: flex;
      gap: 8px;
      width: 100px;
      align-items: center;
      padding-left: 30px;
    }

    .sub-container {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      max-width: 450px;

      &.form {
        justify-content: flex-start;
      }
    }
  }
}
</style>
