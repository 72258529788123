<template>
  <div>
    <v-card
      v-if="!loading.workspace && workspace"
      flat
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
      class="pb-4"
      max-width="800"
    >
      <div>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="
            font-size: 10pt;
            font-weight: 600;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
          "
        >
          Users
        </v-sheet>
        <v-divider class="primary"></v-divider>
      </div>
      <div
        class="d-flex justify-space-between mb-2 px-6 pt-4"
        style="gap: 20px"
      >
        <InviteDialog ref="invite" />
        <div>
          <p v-if="userProfile.role != 'admin'" style="font-size: 10pt">
            Only users with the role 'Admin' can manage users.
          </p>
        </div>
      </div>
      <v-data-table
        class="px-2"
        :headers="headers"
        :items="members"
        mobile-breakpoint="100"
        sort-by="email"
        :hide-default-footer="workspace.members.length < 11"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            :disabled="
              userProfile.role != 'admin' || userProfile.uid === item.uid
            "
          >
            <v-icon color="primary" small @click="remove(item)">
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.email`]="{ item }">
          <span :style="{ opacity: item.invited ? 0.7 : 1 }">
            {{ item.email }}</span
          >
          <v-chip small color="accent" class="ml-2" v-if="item.invited"
            >Pending</v-chip
          >
        </template>

        <template v-slot:[`item.role`]="{ item }">
          <v-select
            v-if="userProfile.role === 'admin' && userProfile.uid != item.uid"
            solo
            background-color="smoke"
            flat
            style="border-radius: 6px; max-width: 140px"
            label="Role"
            dense
            v-model="item.role"
            hide-details=""
            :items="roles"
            @change="setRole(item)"
          >
            <template v-slot:selection="{ item }">
              <span style="font-size: 14px">{{ capitalize(item) }}</span>
            </template>
            <template v-slot:item="{ item }">
              <span style="font-size: 14px">{{ capitalize(item) }}</span>
            </template>
          </v-select>
          <span v-else>{{ capitalize(item.role) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InviteDialog from "@/components/InviteDialog.vue";

export default {
  components: {
    InviteDialog,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      loading: (state) => state.loading,
    }),
    members() {
      let list = this.workspace.invites;

      list.forEach((e) => (e.invited = true));
      return this.workspace.members.concat(list);
    },
  },
  mounted() {
    if (this.$route.query.invite) {
      let child = this.$refs.invite;
      child.open();
    }
  },
  data() {
    return {
      edit: false,
      roles: ["admin", "manager", "editor"],
      headers: [
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Role",
          align: "start",
          sortable: false,
          value: "role",
        },
        {
          text: "Actions",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  methods: {
    capitalize(string) {
      let firstCharacter = string.charAt(0);
      firstCharacter = firstCharacter.toUpperCase();
      let capitalizedString = firstCharacter + string.slice(1);
      return capitalizedString;
    },
    remove(i) {
      console.log(i);
      // TODO remove

      this.$store.dispatch("removeInvite", i);
      // TODO add role select to invites (pre-assign roles). Roles assigned can never be higher than the current users role.
    },
    setRole(v) {
      this.$store.dispatch("updateRole", v);
    },
  },
};
</script>

<style lang="scss" scoped></style>
