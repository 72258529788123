<template>
  <div class="pa-4" style="height: 100vh; overflow: auto">
    <v-dialog
      width="500"
      v-model="dialog"
      transition="dialog-bottom-transition"
      v-if="!loading.admin.backlog && item"
      @input="changeDialog"
      persistent
    >
      <v-card
        flat
        width="500"
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <div class="pa-6">
          <h2 class="mb-4">Ticket</h2>
          <v-text-field
            placeholder="Title"
            v-model="item.title"
            hide-details=""
            solo
            style="border-radius: 6px"
            flat
            background-color="smoke"
            class="mb-2"
          ></v-text-field>
          <v-textarea
            placeholder="Description"
            v-model="item.description"
            hide-details=""
            solo
            style="border-radius: 6px"
            flat
            background-color="smoke"
          ></v-textarea>
          <div class="d-flex justify-space-between mt-6" style="gap: 10px">
            <v-btn icon @click="deleteItem(item.uid)"
              ><v-icon small>mdi-delete</v-icon></v-btn
            >
            <div>
              <v-btn
                elevation="0"
                text
                class="px-6 mr-2"
                @click="dialog = false"
                >Close</v-btn
              >
              <v-btn
                elevation="0"
                class="px-6"
                color="button"
                @click="
                  update(item);
                  dialog = false;
                "
                >Save</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <Backlog @open="open" :publicView="false" />
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "@/firebase";
import Backlog from "@/components/BacklogView.vue";

export default {
  components: {
    Backlog,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      admin_backlog: (state) => state.admin.backlog,
    }),
    filtered() {
      let list = this.admin_backlog;

      if (this.list === "in development") {
        list = list.filter((i) => i.status === "in development");
      } else if (this.list === "requests") {
        list = list.filter((i) => i.status === "requested");
      } else if (this.list === "backlog") {
        list = list.filter((i) => i.status === "backlog");
      } else if (this.list === "review") {
        list = list.filter((i) => i.status === "ready for review");
      } else if (this.list === "done") {
        list = list.filter((i) => i.status === "done");
      }

      return list;
    },
  },
  data() {
    return {
      dialog: false,
      item: null,
      list: "all",
      search: "",
      headers: [
        { text: "Votes", value: "votes.length" },

        { text: "Content", value: "title" },
        { text: "Details", value: "details", align: "left" },
        { text: "", value: "actions" },
      ],
      filter_noposts: false,
      states: [
        "requested",
        "backlog",
        "in development",
        "ready for review",
        "done",
      ],
    };
  },
  methods: {
    changeDialog(v) {
      if (!v) {
        this.dialog = false;
        this.item = null;
      }
    },
    async deleteItem(uid) {
      await deleteDoc(doc(db, "backlog", uid));
      this.$store.commit("setNotification", {
        show: true,
        text: "Backlog item removed!",
        type: "primary",
        icon: "",
      });
      this.changeDialog();
      this.$store.dispatch("fetchAdminBacklog");
    },
    formatDate(d) {
      let date = d.toDate();
      return moment(date).format("DD-MM-YYYY");
    },
    getColor(status) {
      if (status === "in progress") {
        return "secondary";
      } else if (status === "done") {
        return "success";
      }
    },
    async move(v, dir) {
      let state_index = this.states.findIndex((i) => i === v.status);
      const washingtonRef = doc(db, "backlog", v.uid);
      if (dir === "next" && state_index < this.states.length - 1) {
        await updateDoc(washingtonRef, {
          status: this.states[state_index + 1],
        });
      } else if (dir === "back" && state_index > 0) {
        await updateDoc(washingtonRef, {
          status: this.states[state_index - 1],
        });
      }
      this.$store.dispatch("fetchAdminBacklog");
    },
    open(v) {
      this.dialog = true;
      this.item = v;
    },
    openInFb(uid) {
      let production = window.location.origin.includes("app.stix.social");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "one-startup-stack" : "stix-staging-f240f"
      }/firestore/data/~2Fbacklog~2F${uid}`;
      window.open(url, "_blank");
    },
    async update(v) {
      const washingtonRef = doc(db, "backlog", v.uid);
      await updateDoc(washingtonRef, {
        title: v.title,
      });
      this.$store.commit("setNotification", {
        show: true,
        text: "Backlog item updated!",
        type: "primary",
        icon: "",
      });
    },
  },
};
</script>
