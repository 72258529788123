<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="400"
    max-width="400"
    offset-y
    transition="slide-y-transition"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-badge
          :content="notifications.length"
          color="error"
          overlap
          v-if="notifications.length > 0"
        >
          <v-icon size="20" color="primary">mdi-bell</v-icon>
        </v-badge>
        <v-icon v-else size="20" color="primary">mdi-bell</v-icon>
      </v-btn>
    </template>

    <v-card :class="{ primary: notifications.length === 0 }" tile>
      <div class="profile pa-4">
        <Transition name="slide-fade">
          <div
            class="notification smoke pa-4"
            :class="`${i.color}_border`"
            v-for="(i, index) in notifications"
            :key="index"
          >
            <div class="txt">
              <p class="mb-1">
                <b v-html="i.title"></b>
              </p>
              <p style="font-size: 12px" class="mb-0" v-html="i.message"></p>
              <v-btn
                small
                class="mt-2 smoke darken-1"
                elevation="0"
                @click="openLink(i.read_more)"
                v-if="i.read_more"
                >Read more</v-btn
              >
            </div>
            <div class="d-flex justify-end">
              <v-btn @click="$store.dispatch('markAsSeen', i.uid)" icon small
                ><v-icon small>mdi-close</v-icon></v-btn
              >
            </div>
          </div>
        </Transition>

        <Transition name="slide-fade" v-if="reauthorize">
          <div class="notification smoke pa-4 error_border">
            <div class="txt">
              <p class="mb-1">
                <b>Account(s) permissions revoked</b>
              </p>
              <p style="font-size: 12px" class="mb-0">
                Permissions to one or multiple accounts have been revoked or
                changed.
                <u
                  >Please reauthorize if you want to keep managing these
                  accounts.</u
                >
              </p>
              <v-btn
                small
                class="mt-2 smoke darken-1"
                elevation="0"
                @click="
                  $router.push('/accounts');
                  menu = false;
                "
                >To accounts</v-btn
              >
            </div>
          </div>
        </Transition>
        <div v-if="notifications.length === 0 && !reauthorize">
          <div class="d-flex justify-center mt-4">
            <Astronaut style="max-width: 100px" />
          </div>
          <p
            style="font-size: 12px; text-align: center"
            class="mt-4 mb-2 white--text"
          >
            <u> No notifications!</u>
          </p>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import Astronaut from "@/components/lottie/AstronautLottie.vue";

export default {
  components: {
    Astronaut,
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      userProfile: (state) => state.users.userProfile,
      accounts: (state) => state.accounts,
      notifications: (state) => state.notifications.notifications,
    }),
    reauthorize() {
      return (
        this.accounts.filter((a) => a.access_token_valid === false).length > 0
      );
    },
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  width: 100%;
  display: grid;
  grid-template-columns: auto 50px;
  align-items: center;
  border-radius: 6px;
  &.success_border {
    border-left: 5px solid #c9fa75 !important;
  }

  &.primary_border {
    border-left: 5px solid #272727 !important;
  }

  &.info_border {
    border-left: 5px solid #ffd978 !important;
  }

  &.error_border {
    border-left: 5px solid #fa7a68 !important;
  }
}

.profile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
