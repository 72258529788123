<template>
  <div class="content-library">
    <div class="d-flex mb-4">
      <h1 class="mr-8">
        Categories
        <v-progress-circular
          v-if="loading.categories && categories.length === 0"
          size="20"
          indeterminate
          width="2"
          class="ml-2"
        ></v-progress-circular>
        <span
          v-if="!loading.categories || categories.length > 0"
          class="font-weight-regular"
          >({{ categories.length }})</span
        >
      </h1>
      <NewCategory ref="category" type="button" />
    </div>

    <div
      class="categories"
      :style="{ gap: $vuetify.breakpoint.smAndDown ? '10px' : '20px' }"
    >
      <CategoryCard
        v-for="(c, index) in categories"
        :key="index"
        :category="c"
        :index="index"
      />
    </div>
    <p v-if="categories && categories.length === 0" style="opacity: 0.6">
      No categories have been setup yet. Click the button 'Add category' to
      start!
    </p>
  </div>
</template>

<script>
import CategoryCard from "@/components/CategoryCard.vue";
import { mapState } from "vuex";
import NewCategory from "@/components/NewCategory.vue";

export default {
  components: {
    CategoryCard,
    NewCategory,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.userProfile,
      loading: (state) => state.loading,
      categories: (state) => state.posts.categories,
    }),
  },
  data() {
    return {
      category: null,
      search: "",
    };
  },
  mounted() {
    if (this.$route.query.new) {
      let child = this.$refs.category;
      child.open();
    }
  },
  methods: {
    selectCategory(c) {
      this.$router.push(`/content?category=${c.name}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
