export const template = {
  computed: {
    aspect_ratio: {
      get() {
        return this.$store.state.template.aspect_ratio;
      },
      set(value) {
        this.$store.commit("setTemplateAspectRatio", value);
      },
    },
    editor: {
      get() {
        return this.$store.state.template.editor;
      },
      set(value) {
        this.$store.commit("setTemplateEditor", value);
      },
    },
    name: {
      get() {
        return this.$store.state.template.name;
      },
      set(value) {
        this.$store.commit("setTemplateName", value);
      },
    },
    padding: {
      get() {
        return this.$store.state.template.padding;
      },
      set(value) {
        this.$store.commit("setTemplatePadding", value);
      },
    },
    blocks: {
      get() {
        return this.$store.state.template.blocks;
      },
      set(value) {
        this.$store.commit("setTemplateBlocks", value);
      },
    },
    align_items: {
      get() {
        return this.$store.state.template.align_items;
      },
      set(value) {
        this.$store.commit("setTemplateAlignItems", value);
      },
    },
    tags: {
      get() {
        return this.$store.state.template.tags;
      },
      set(value) {
        this.$store.commit("setTemplateTags", value);
      },
    },
    background: {
      get() {
        return this.$store.state.template.background;
      },
      set(value) {
        this.$store.commit("setTemplateBackground", value);
      },
    },
    justify_content: {
      get() {
        return this.$store.state.template.justify_content;
      },
      set(value) {
        this.$store.commit("setTemplateJustifyContent", value);
      },
    },
    uid: {
      get() {
        return this.$store.state.template.uid;
      },
      set(value) {
        this.$store.commit("setTemplateUid", value);
      },
    },
    created_at: {
      get() {
        return this.$store.state.template.created_at;
      },
      set(value) {
        this.$store.commit("setTemplateCreatedAt", value);
      },
    },
    created_by: {
      get() {
        return this.$store.state.template.created_by;
      },
      set(value) {
        this.$store.commit("setTemplateCreatedBy", value);
      },
    },
    created_by_email: {
      get() {
        return this.$store.state.template.created_by_email;
      },
      set(value) {
        this.$store.commit("setTemplateCreatedByEmail", value);
      },
    },
    thumbnail: {
      get() {
        return this.$store.state.template.thumbnail;
      },
      set(value) {
        this.$store.commit("setTemplateThumbnail", value);
      },
    },
    loading: {
      get() {
        return this.$store.state.template.loading;
      },
      set(value) {
        this.$store.commit("setTemplateLoading", value);
      },
    },
  },
};
