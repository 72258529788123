<template>
  <div>
    <v-card
      :width="$vuetify.breakpoint.smAndDown ? '100%' : 344"
      outlined
      class="py-6 px-8"
      :style="{
        borderBottom: '3px solid' + category.color,
        borderRight: '3px solid' + category.color,
        borderLeft: '1px solid' + category.color,
        borderTop: '1px solid' + category.color,
      }"
      elevation="0"
    >
      <div
        class="d-flex flex-column"
        :class="{ 'justify-center': confirm, 'justify-start': !confirm }"
        style="height: 100%"
      >
        <div
          v-if="confirm"
          class="d-flex flex-column align-center justify-center"
        >
          <p class="font-weight-bold">Are you sure?</p>
          <div class="d-flex" style="gap: 10px">
            <v-btn
              elevation="0"
              :loading="deleting"
              color="button"
              @click="deleteCategory()"
              >Yes</v-btn
            >
            <v-btn text @click="confirm = false">No</v-btn>
          </div>
        </div>
        <div class="d-flex align-start justify-space-between" v-if="!confirm">
          <div>
            <h2 class="mb-1">
              <div class="d-flex">
                <v-icon size="15" class="mr-3" :color="category.color"
                  >mdi-circle</v-icon
                >
                {{ category.name }}
              </div>
            </h2>
            <p class="mb-2" style="font-size: 12px">
              {{ category.description }}
            </p>
            <p
              class="mb-1 font-weight-regular"
              style="font-size: 14px; cursor: pointer"
              @click="$router.push(`/library/posts?category=${category.uid}`)"
            >
              &#128073;
              <u
                >{{ category.counter.posts }} post<span
                  v-if="
                    category.counter.posts > 1 || category.counter.posts === 0
                  "
                  >s</span
                >
                in this category.</u
              >
            </p>
            <p
              class="mb-0 font-weight-regular"
              style="font-size: 14px; cursor: pointer"
            >
              <span
                @click="
                  $router.push(`/schedule?new=true&category=${category.uid}`)
                "
                v-if="
                  schedules.filter((s) => s.category.uid === category.uid)
                    .length < 1
                "
              >
                &#128073; <u> Add schedule</u>
              </span>

              <u v-else>
                <v-icon small color="success darken-2" class="mr-1"
                  >mdi-check-circle</v-icon
                >Schedule active</u
              >
            </p>
          </div>

          <v-menu transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon
                ><v-icon>mdi-dots-vertical</v-icon></v-btn
              >
            </template>
            <v-list>
              <v-list-item
                link
                class="px-4"
                @click="$router.push(`/post?category=${category.uid}`)"
              >
                <v-icon small :color="category.color" class="mr-3"
                  >mdi-plus</v-icon
                >
                <v-list-item-title>Add post</v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                class="px-4"
                @click="$router.push(`/library/posts?category=${category.uid}`)"
              >
                <v-icon small :color="category.color" class="mr-3"
                  >mdi-eye</v-icon
                >
                <v-list-item-title>View posts</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="category.name != 'General'" link class="px-4">
                <v-icon small :color="category.color" class="mr-3"
                  >mdi-pencil</v-icon
                >
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="category.name != 'General'"
                link
                class="px-4"
                @click="confirm = true"
              >
                <v-icon small :color="category.color" class="mr-3"
                  >mdi-delete</v-icon
                >
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  props: ["category", "index"],
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      schedules: (state) => state.schedules.schedules,
    }),
  },
  data() {
    return {
      confirm: false,
      deleting: false,
      elevation: 0,
      items: [
        {
          title: "Edit",
          icon: "mdi-pencil-outline",
        },

        {
          title: "Delete",
          icon: "mdi-delete",
        },
      ],
    };
  },
  methods: {
    async deleteCategory() {
      this.deleting = true;
      await deleteDoc(
        doc(
          db,
          "workspaces",
          this.userProfile.workspace,
          "categories",
          this.category.uid
        )
      );

      this.$store.dispatch("fetchCategories");
      this.deleting = false;
      this.confirm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>
