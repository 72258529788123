<template>
  <div class="admin">
    <div class="left primary animate__animated animate__slideInLeft">
      <v-list nav class="primary" dark>
        <v-list-item
          v-for="i in items"
          :key="i.route"
          link
          :to="i.route"
          :style="{ opacity: $route.path === i.route ? 1 : 0.7 }"
          class="justify-center"
        >
          <div class="d-flex flex-column py-2">
            <v-icon
              size="20"
              style="padding: 1px 0"
              :color="$route.path === `${i.route}` ? 'button' : 'white'"
              >{{ i.icon }}</v-icon
            >
            <p
              class="white--text mt-1 mb-0"
              style="font-size: 12px; font-weight: 500"
            >
              {{ i.title }}
            </p>
          </div>
        </v-list-item>
      </v-list>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        // {
        //   title: "Stats",
        //   route: "/admin/stats",
        //   icon: "mdi-chart-line",
        // },
        {
          title: "Users",
          route: "/admin/users",
          icon: "mdi-account-multiple",
        },
        {
          title: "Workspaces",
          route: "/admin/workspaces",
          icon: "mdi-domain",
        },
        {
          title: "Notifications",
          route: "/admin/notifications",
          icon: "mdi-bell",
        },
        {
          title: "Calendar",
          route: "/admin/calendar",
          icon: "mdi-calendar",
        },
        {
          title: "Posts",
          route: "/admin/posts",
          icon: "mdi-note-multiple",
        },

        {
          title: "Backlog",
          route: "/admin/backlog",
          icon: "mdi-list-box",
        },
        {
          title: "Emails",
          route: "/admin/emails",
          icon: "mdi-email",
        },
        {
          title: "Settings",
          route: "/admin/settings",
          icon: "mdi-cog",
        },
        // {
        //   title: "Subscriptions",
        //   route: "/admin/subscriptions",
        //   icon: "mdi-credit-card",
        // },
        // {
        //   title: "Invoices",
        //   route: "/admin/invoices",
        //   icon: "mdi-currency-eur",
        // },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.admin {
  display: grid;
  grid-template-columns: 110px auto;

  .left {
    height: 100vh;
  }
}

.animate__animated.animate__slideInLeft {
  --animate-duration: 0.5s;
}
</style>
