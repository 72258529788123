<template>
  <div>
    <v-dialog
      width="500"
      :value="preview_dialog"
      transition="dialog-bottom-transition"
      @input="change"
      overlay-opacity="0.8"
    >
      <v-card class="pa-4 px-8" flat color="primary">
        <div class="d-flex justify-center align-center">
          <v-btn
            dark
            class="primary lighten-1"
            icon
            @click="$store.commit('setPostPreviewDialog', false)"
            ><v-icon dark small>mdi-close</v-icon></v-btn
          >
        </div>
        <PreviewContent
          :channels="accounts"
          :message="preview_message"
          :image="media ? media.url : null"
          :media_type="media && media.type ? media.type : null"
          :comment="comment"
          :preview="link_preview"
          :selected_accounts="selected_accounts"
          :variations="variations"
          @delete_image="media = null"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PreviewContent from "./previewContent.vue";
import { post } from "@/mixins/post";
import { mapState } from "vuex";

export default {
  mixins: [post],
  components: {
    PreviewContent,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      accounts: (state) => state.accounts,
      categories: (state) => state.posts.categories,
      posts: (state) => state.posts.posts,
      workspace: (state) => state.workspace.workspace,
    }),
    preview_message() {
      const m = this.variations[0].text.replace(/\n/g, "<br />");
      return m;
    },
  },
  data() {
    return {
      type: "facebook",
    };
  },
  methods: {
    change(v) {
      if (!v) {
        this.$store.commit("setPostPreviewDialog", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
