<template>
  <v-card
    flat
    outlined
    class="d-flex"
    :style="{
      borderBottom: '3px solid #272727',
      borderLeft: '1px solid #272727',
      borderTop: '1px solid #272727',
      borderRight: '3px solid #272727',
    }"
  >
    <img
      :src="template.thumbnail"
      :style="{ aspectRatio: template.aspect_ratio }"
      style="border-radius: 6px 0 0 6px; height: 200px"
    />
    <div class="d-flex flex-column justify-space-between px-4 py-2">
      <div>
        <div class="d-flex align-center">
          <h2 class="truncate">{{ template.name }}</h2>
          <v-spacer></v-spacer>
          <v-menu transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon small>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link class="px-4">
                <v-icon small class="mr-3">mdi-send</v-icon>
                <v-list-item-title>Use</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                class="px-4"
                @click="$router.push(`/template/${template.uid}`)"
              >
                <v-icon small class="mr-3">mdi-pencil</v-icon>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                class="px-4"
                @click="$store.dispatch('deleteTemplate', template.uid)"
              >
                <v-icon small class="mr-3">mdi-delete</v-icon>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-flex mt-2" style="gap: 3px">
          <v-chip
            color="smoke"
            x-small
            v-for="tag in template.tags"
            :key="tag"
            >{{ tag }}</v-chip
          >
        </div>
      </div>

      <div class="mb-2">
        <p class="mb-0" style="font-size: 11px">
          {{ template.created_by_email }} <br />
          {{ getDate(template.created_at) }}
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: ["template"],
  data() {
    return {};
  },
  methods: {
    getDate(t) {
      return moment(t.toDate()).format("DD-MM-YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  margin-right: 10px;
}
</style>
