<template>
  <v-bottom-sheet v-model="sheet" :persistent="link_step > 0">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="primary" elevation="0" dark
        >Add schedule</v-btn
      >
    </template>
    <v-sheet class="text-center" height="200px" color="#f0f0f0">
      <v-window v-model="link_step" style="height: 200px">
        <v-window-item :value="0">
          <div class="pa-6">
            <p style="font-size: 13px" class="mb-2">From</p>
            <v-select
              :items="$store.state.categories"
              item-text="name"
              placeholder="Category"
              hide-details=""
              background-color="white"
              return-object
              v-model="category"
              outlined
              style="border-radius: 8px"
              :style="{
                borderBottom: '2px solid #272727',
                borderLeft: '0.75px solid #272727',
                borderTop: '0.75px solid #272727',
                borderRight: '2px solid #272727',
              }"
            ></v-select>
          </div>
          <div class="d-flex justify-center" style="gap: 10px">
            <v-btn
              elevation="0"
              color="button"
              @click="link_step = link_step + 1"
              >Next</v-btn
            >
            <v-btn @click="sheet = !sheet" elevation="0" color="primary" text
              >Close</v-btn
            >
          </div>
        </v-window-item>
        <v-window-item :value="1" class="linkone" style="height: 200px">
          <div class="pa-6">
            <p style="font-size: 13px" class="mb-2">... post every</p>
            <div class="d-flex" style="gap: 10px">
              <v-text-field
                type="number"
                placeholder="2"
                outlined
                hide-details=""
                v-model="frequency"
                persistent-placeholder
                background-color="white"
                style="border-radius: 8px"
                :style="{
                  borderBottom: '2px solid #272727',
                  borderLeft: '0.75px solid #272727',
                  borderTop: '0.75px solid #272727',
                  borderRight: '2px solid #272727',
                }"
              ></v-text-field>
              <v-select
                placeholder="Weeks"
                hide-details=""
                v-model="type"
                outlined
                persistent-placeholder
                :items="frequencyItems"
                style="border-radius: 8px"
                background-color="white"
                :style="{
                  borderBottom: '2px solid #272727',
                  borderLeft: '0.75px solid #272727',
                  borderTop: '0.75px solid #272727',
                  borderRight: '2px solid #272727',
                }"
              ></v-select>
            </div>
          </div>
          <div class="d-flex justify-center" style="gap: 10px">
            <v-btn elevation="0" color="button" @click="next">Next</v-btn>
            <v-btn @click="sheet = !sheet" elevation="0" color="primary" text
              >Close</v-btn
            >
          </div>
        </v-window-item>

        <v-window-item :value="2" class="linkone" style="height: 200px">
          <div class="pa-6">
            <p style="font-size: 13px" class="mb-2">... at</p>
            <v-select
              hide-details=""
              placeholder="08:00"
              persistent-placeholder
              v-model="timeslot"
              outlined
              background-color="white"
              style="border-radius: 8px; max-width: 140px; margin: auto"
              :style="{
                borderBottom: '2px solid #272727',
                borderLeft: '0.75px solid #272727',
                borderTop: '0.75px solid #272727',
                borderRight: '2px solid #272727',
              }"
              :items="timeslots"
            ></v-select>
          </div>
          <div class="d-flex justify-center" style="gap: 10px">
            <v-btn elevation="0" color="button" @click="next">Next</v-btn>
            <v-btn @click="sheet = !sheet" elevation="0" color="primary" text
              >Close</v-btn
            >
          </div>
        </v-window-item>

        <v-window-item :value="3" class="linkone" style="height: 200px">
          <div class="pa-6">
            <p style="font-size: 13px" class="mb-2">... on a</p>
            <v-select
              hide-details=""
              :items="days"
              v-model="day"
              item-text="text"
              item-value="value"
              outlined
              background-color="white"
              style="border-radius: 8px; max-width: 140px; margin: auto"
              :style="{
                borderBottom: '2px solid #272727',
                borderLeft: '0.75px solid #272727',
                borderTop: '0.75px solid #272727',
                borderRight: '2px solid #272727',
              }"
            ></v-select>
          </div>
          <div class="d-flex justify-center" style="gap: 10px">
            <v-btn elevation="0" color="button" @click="next">Next</v-btn>
            <v-btn @click="sheet = !sheet" elevation="0" color="primary" text
              >Close</v-btn
            >
          </div>
        </v-window-item>

        <v-window-item :value="4" class="linkone" style="height: 200px">
          <div class="pa-6">
            <p style="font-size: 13px" class="mb-2">... on the</p>
            <div class="d-flex" style="gap: 10px">
              <v-select
                placeholder="First"
                v-model="month_day"
                :items="dayItems"
                hide-details=""
                outlined
                background-color="white"
                persistent-placeholder
                style="border-radius: 8px; max-width: 140px; margin: auto"
                :style="{
                  borderBottom: '2px solid #272727',
                  borderLeft: '0.75px solid #272727',
                  borderTop: '0.75px solid #272727',
                  borderRight: '2px solid #272727',
                }"
              ></v-select>
              <v-select
                style="border-radius: 8px; margin: auto"
                :style="{
                  borderBottom: '2px solid #272727',
                  borderLeft: '0.75px solid #272727',
                  borderTop: '0.75px solid #272727',
                  borderRight: '2px solid #272727',
                }"
                v-model="day"
                hide-details=""
                background-color="white"
                outlined
                placeholder="Wednesday"
                item-text="text"
                item-value="value"
                :items="days"
              ></v-select>
            </div>
          </div>
          <div class="d-flex justify-center" style="gap: 10px">
            <v-btn
              elevation="0"
              color="button"
              @click="link_step = link_step + 1"
              >Next</v-btn
            >
            <v-btn @click="sheet = !sheet" elevation="0" color="primary" text
              >Close</v-btn
            >
          </div>
        </v-window-item>

        <v-window-item
          :value="5"
          class="linkone"
          @click="
            link_step = 0;
            sheet = false;
          "
        >
          <p style="font-size: 12px" class="mb-1 pa-0">
            Link added succesfully
          </p>
          <v-icon large color="success darken-2">mdi-check-circle</v-icon>
        </v-window-item>
      </v-window>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  data() {
    return {
      category: "",
      day: "",
      dayItems: [
        {
          text: "First",
          value: 1,
        },
        {
          text: "2nd",
          value: 2,
        },
        {
          text: "3rd",
          value: 3,
        },
        {
          text: "4th",
          value: 4,
        },
        {
          text: "Last",
          value: 99,
        },
      ],
      days: [
        {
          text: "Sunday",
          value: 0,
        },
        {
          text: "Monday",
          value: 1,
        },
        {
          text: "Tuesday",
          value: 2,
        },
        {
          text: "Wednesday",
          value: 3,
        },
        {
          text: "Thursday",
          value: 4,
        },
        {
          text: "Friday",
          value: 5,
        },
        {
          text: "Saturday",
          value: 6,
        },
      ],
      frequency: "",
      frequencyItems: ["Days", "Weeks", "Months"],
      link_step: 0,
      month_day: "",
      sheet: false,
      timeslot: "",
      timeslots: [
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "00:00",
      ],
      type: "Weeks",
    };
  },
  methods: {
    next() {
      if (this.type === "Weeks") {
        this.link_step = this.link_step + 2;
      } else if (this.type === "Months") {
        this.link_step = this.link_step + 1;
      }
    },
  },
};
</script>
