<template>
  <div
    :class="{
      mobile_schedules: $vuetify.breakpoint.smAndDown,
      desktop_schedules: !$vuetify.breakpoint.smAndDown,
    }"
  >
    <div class="d-flex mb-4">
      <h1 class="mr-8">
        Schedules
        <span
          v-if="!loading.schedules || schedules.length > 0"
          class="font-weight-regular"
          >({{ schedules.length }})</span
        >
      </h1>
      <ScheduleDialog ref="scheduleDialog" />
    </div>
    <div v-for="s in schedules" :key="s.uid" class="mb-2 mr-2">
      <v-card
        :class="{
          'px-5': $vuetify.breakpoint.smAndDown,
          'px-10': !$vuetify.breakpoint.smAndDown,
        }"
        class="py-4 d-flex align-center justify-space-between"
        flat
        max-width="750"
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <div v-if="$vuetify.breakpoint.smAndDown">
          <span>From </span>

          <span v-if="$vuetify.breakpoint.smAndDown"
            ><b
              ><u>{{ s.category.name }}</u></b
            ></span
          >

          <span>
            post every
            <span
              ><b
                >{{ s.frequency === 1 ? "" : s.frequency }}
                {{
                  s.frequency === 1
                    ? s.type.toLowerCase().slice(0, -1)
                    : s.type.toLowerCase()
                }}</b
              >
            </span>
            <span v-if="s.type === 'Weeks'">
              on a <span><b>Thursday</b></span> at
              <b>{{ s.timeslot }}</b> </span
            >.
          </span>
        </div>
        <div
          class="d-flex align-center flex-wrap"
          style="gap: 10px"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <span> From</span>
          <v-chip class="px-6 font-weight-bold" :color="s.category.color" label>
            <span
              :style="{
                color: s.category.color === '#272727' ? 'white' : '#272727',
              }"
              >{{ s.category.name }}</span
            >
          </v-chip>
          <span v-if="$vuetify.breakpoint.smAndDown"
            ><b
              ><u>{{ s.category.name }}</u></b
            ></span
          >

          <span
            >post every
            <span
              ><b
                >{{ s.frequency === 1 ? "" : s.frequency }}
                {{
                  s.frequency === 1
                    ? s.type.toLowerCase().slice(0, -1)
                    : s.type.toLowerCase()
                }}</b
              >
            </span>

            <span v-if="s.type === 'Weeks' || s.type === 'Months'">
              on a
              <span v-if="!Array.isArray(s.day_of_week)"
                ><b>{{
                  days.find((f) => f.value === s.day_of_week).text
                }}</b></span
              >
              <span v-else
                ><b>{{ getDays(s.day_of_week) }}</b></span
              >
            </span>

            at <b>{{ s.timeslot }}</b>
            .
          </span>
        </div>

        <v-btn icon @click="$store.dispatch('deleteSchedule', s.uid)"
          ><v-icon small>mdi-delete</v-icon></v-btn
        >
      </v-card>
    </div>
    <p
      v-if="schedules && schedules.length === 0"
      style="opacity: 0.6; margin-top: 20px"
    >
      No schedules have been setup yet. Click the button 'Add schedule' to
      start!
    </p>

    <div
      class="d-flex justify-space-between mb-4"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <h1>Schedules</h1>
      <ScheduleMobile />
    </div>
  </div>
</template>

<script>
import ScheduleDialog from "@/components/scheduler/ScheduleDialog.vue";
import ScheduleMobile from "@/components/scheduler/ScheduleMobile.vue";
import { mapState } from "vuex";

import moment from "moment";
// import PostDesktop from "@/components/post/PostDesktop.vue";

export default {
  components: { ScheduleDialog, ScheduleMobile },
  mounted() {
    if (this.$route.query.new) {
      let child = this.$refs.scheduleDialog;
      child.open(this.$route.query.category);
    }
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      pages: (state) => state.facebook.pages,
      schedules: (state) => state.schedules.schedules,
      categories: (state) => state.posts.categories,
      posts: (state) => state.posts.posts,
      queue: (state) => state.schedules.queue,
    }),
    date() {
      let list = [];
      this.queue.forEach((i) => list.push(i.time));
      return list;
    },
  },
  data: () => ({
    category: "All",
    days: [
      {
        text: "Sunday",
        value: 0,
      },
      {
        text: "Monday",
        value: 1,
      },
      {
        text: "Tuesday",
        value: 2,
      },
      {
        text: "Wednesday",
        value: 3,
      },
      {
        text: "Thursday",
        value: 4,
      },
      {
        text: "Friday",
        value: 5,
      },
      {
        text: "Saturday",
        value: 6,
      },
    ],
    mode: "calendar",
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],

    tab: 1,
  }),
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    eventDescription(e) {
      let hour = e.start.getHours();
      return `${e.name} @ ${hour}:00`;
    },
    getDays(list) {
      let days = [];
      for (let x in list) {
        days.push(this.days.find((v) => v.value === list[x]).text);
      }

      return days;
    },
    getEventColor(event) {
      return event.color;
    },
    getTime(v) {
      return moment(v.toDate()).format("dddd, MMM Do YYYY, HH:MM");
    },

    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
