export const medium = {
  computed: {
    show: {
      get() {
        return this.$store.state.medium.show;
      },
      set(value) {
        this.$store.commit("setMediumShow", value);
      },
    },
    tab: {
      get() {
        return this.$store.state.medium.tab;
      },
      set(value) {
        this.$store.commit("setMediumTab", value);
      },
    },
    search: {
      get() {
        return this.$store.state.medium.search;
      },
      set(value) {
        this.$store.commit("setMediumSearch", value);
      },
    },
    search_loading: {
      get() {
        return this.$store.state.medium.loading;
      },
      set(value) {
        this.$store.commit("setMediumSearchLoading", value);
      },
    },
    search_media: {
      get() {
        return this.$store.state.medium.search_media;
      },
      set(value) {
        this.$store.commit("setMediumSearchMedia", value);
      },
    },
    search_template: {
      get() {
        return this.$store.state.medium.search_template;
      },
      set(value) {
        this.$store.commit("setMediumSearchTemplate", value);
      },
    },
    next_page: {
      get() {
        return this.$store.state.medium.next_page;
      },
      set(value) {
        this.$store.commit("setMediumNextPage", value);
      },
    },
    items: {
      get() {
        return this.$store.state.medium.items;
      },
      set(value) {
        this.$store.commit("setMediumItems", value);
      },
    },
    type: {
      get() {
        return this.$store.state.medium.type;
      },
      set(value) {
        this.$store.commit("setMediumType", value);
      },
    },
  },
};
