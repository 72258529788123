<template>
  <v-card :outlined="outlined" width="100%" flat>
    <div class="preview_top px-4 pt-4">
      <div class="top_left d-flex align-center">
        <v-avatar size="40" class="mr-2" color="smoke">
          <v-img
            v-if="!account && channels && channels.length > 0"
            :src="channels[0].profile_picture"
          ></v-img>
          <v-img v-if="account" :src="account.profile_picture"></v-img>
        </v-avatar>
        <div class="d-flex flex-column justify-center">
          <span
            class="mb-0"
            style="font-size: 15px; color: #050505; font-weight: 600"
          >
            {{ account ? account.name : "" }}
            <span class="font-weight-regular" v-if="type === 'cover_photo'"
              >updated their cover photo.</span
            >
          </span>
          <p class="mb-0" style="font-size: 13px">
            {{ created_at }} · <v-icon x-small>mdi-earth</v-icon>
          </p>
        </div>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              elevation="0"
              color="primary"
              ><v-icon small>mdi-dots-vertical</v-icon></v-btn
            >
          </template>

          <v-card>
            <v-list>
              <v-list-item link> Open in Facebook </v-list-item>
              <v-list-item link @click="importPost"> Import post </v-list-item>
              <v-list-item link> Save struture </v-list-item>
              <v-list-item link> Delete post </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="preview_middle pa-4" v-html="message"></div>
    <div v-if="image">
      <p class="px-4 mb-1 text-end" style="font-size: 9pt; opacity: 0.6">
        <i>Media can appear different on Facebook</i>
      </p>
      <v-img :src="image" contain>
        <div
          style="height: 100%; width: 100%"
          class="pa-2 d-flex justify-center align-center"
        >
          <v-icon
            v-if="media_type === 'video'"
            color="primary"
            size="50"
            class="smoke pa-4"
            style="border-radius: 100%; opacity: 0.5"
            >mdi-movie-open</v-icon
          >
        </div>
      </v-img>
    </div>
    <div v-if="preview">
      <img :src="preview.image" style="max-width: 100%" />
      <div class="pa-3">
        <p
          style="
            font-size: 12px;
            color: grey;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          "
          class="mb-0"
        >
          {{ url }}
        </p>
        <p style="font-size: 15px; font-weight: 500" class="mb-0">
          {{ preview.title }}
        </p>
        <p
          style="
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
          "
          class="mb-0"
        >
          {{ preview.description }}
        </p>
      </div>
    </div>

    <div class="preview_bottom">
      <v-divider v-if="!image && !preview"></v-divider>

      <div class="buttons px-4 py-2 d-flex justify-start align-center">
        <v-btn color="white" small depressed rounded class="facebook--text mr-1"
          ><v-icon
            class="mr-1"
            :color="
              likes && likes.summary.total_count > 0 ? 'facebook' : 'primary'
            "
            small
            >{{
              likes && likes.summary.total_count > 0
                ? "mdi-thumb-up"
                : "mdi-thumb-up-outline"
            }}</v-icon
          ><span
            class="facebook--text"
            v-if="likes && likes.summary.total_count > 0"
            >{{ likes.summary.total_count }}</span
          ></v-btn
        >
        <v-btn small depressed color="white" class="mr-1" rounded
          ><v-icon
            small
            class="mr-1"
            :color="comments && comments.length > 0 ? 'facebook' : 'primary'"
            >{{
              comments && comments.length > 0
                ? "mdi-comment"
                : "mdi-comment-outline"
            }}</v-icon
          >
          <span class="facebook--text" v-if="comments && comments.length > 0">{{
            comments.length
          }}</span>
        </v-btn>
        <v-btn rounded depressed color="white" small
          ><v-icon small class="mr-2">mdi-share-outline</v-icon>Share</v-btn
        >
        <v-spacer></v-spacer>
      </div>
    </div>

    <v-expand-transition v-if="comment">
      <div>
        <div class="commentbox ml-4">
          <v-avatar size="32">
            <v-img
              v-if="!account && channels && channels.length > 0"
              :src="channels[0].profile_picture"
            ></v-img>
            <v-img v-if="account" :src="account.profile_picture"></v-img
          ></v-avatar>
          <div>
            <div class="blob mb-1" style="position: relative">
              <div style="font-size: 13px; font-weight: 400">
                <p class="mb-0" style="font-weight: 600">
                  {{ account ? account.name : "" }}
                </p>
              </div>

              <div style="font-size: 15px">{{ comment }}</div>
            </div>
            <v-btn text x-small color="primary">Like</v-btn>
            <v-btn text x-small>Reply</v-btn>
            <v-btn text x-small>Hide</v-btn>
          </div>
        </div>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { getFunctions, httpsCallable } from "firebase/functions";
import { DateTime } from "luxon";
const functions = getFunctions();
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db } from "@/firebase";
import {
  collection,
  arrayUnion,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

export default {
  props: [
    "delete_image",
    "account",
    "message",
    "channels",
    "comment",
    "comments",
    "preview",
    "image",
    "type",
    "likes",
    "id",
    "date",
    "selected_accounts",
    "outlined",
    "media_type",
    "options",
  ],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
    }),
    url() {
      let link = this.preview.url.split("//")[1];

      return link.split("/")[0].toUpperCase();
    },
    created_at() {
      return moment(this.date).format("DD MMMM YYYY");
    },
  },
  methods: {
    async importPost() {
      let time = DateTime.fromISO(this.date);
      //To do save image in storage
      let original = await fetch(this.image);
      let upload = await original.blob();
      upload.name = this.id;
      console.log(upload);
      var fileExtension = upload.type.split("/")[1];
      var fileName = upload.name + "." + fileExtension;
      const filesize = Math.floor(upload.size / 1024);

      function isType(fileExtension) {
        switch (fileExtension.toLowerCase()) {
          case "m4v":
            return "video";
          case "avi":
            return "video";
          case "mpg":
            return "video";
          case "mp4":
            return "video";
          case "jpg":
            return "image";
          case "gif":
            return "image";
          case "bmp":
            return "image";
          case "png":
            return "image";
          case "jpeg":
            return "image";
        }
        return null;
      }

      const storage = getStorage();

      const metadata = {
        contentType: upload.type,
      };
      const media = {
        title: fileName,
        extension: fileExtension,
        class: "media",
        created_at: new Date(),
        created_by: this.userProfile.uid,
        created_by_email: this.userProfile.email,
        workspace: this.userProfile.workspace,
        size: filesize,
        posts: [],
        templates: [],
        tags: [],
        type: isType(fileExtension),
      };
      const docRef = await addDoc(
        collection(db, "workspaces", this.userProfile.workspace, "media"),
        media
      );
      const storageRef = ref(
        storage,
        `${this.userProfile.workspace}/media/${docRef.id}.${fileExtension}`
      );

      await uploadBytesResumable(storageRef, upload, metadata);

      const downloadURL = await getDownloadURL(storageRef);
      const updateRef = doc(
        db,
        "workspaces",
        this.userProfile.workspace,
        "media",
        docRef.id
      );

      await updateDoc(updateRef, {
        uid: docRef.id,
        url: downloadURL,
      });

      this.$store.dispatch("fetchMedia");

      let post = {
        accounts: [this.account.id],
        category: "",
        created: new Date(),
        created_by: this.userProfile.uid,
        created_by_email: this.userProfile.email,
        recurring: null,
        scheduled: false,
        schedule_date: null,
        last_publish: time.toSeconds(),
        status: "draft",
        media: { url: downloadURL, uid: docRef.id, ...media },
        link_preview: null,
        comment: "",
        workspace: this.userProfile.workspace,
        variations: [
          {
            text: this.message,
            type: "main",
          },
        ],
        uid: null,
        timezone: this.workspace.timezone,
      };

      console.log(post);
      const addPost = httpsCallable(functions, "addPost");
      const res = await addPost(post);
      console.log(res);

      await updateDoc(
        doc(db, "workspaces", this.userProfile.workspace, "media", docRef.id),
        {
          posts: arrayUnion(res.data),
        }
      );

      this.$store.commit("setNotification", {
        show: true,
        text: "Your post has been added!",
        type: "primary",
        redirect: `/post?id=${res.data}`,
        action_text: "Open",
      });
    },
    openPost() {
      window.open(`https://www.facebook.com/${this.id}`, "_blank");
    },
    async uploadFiles(url) {
      let upload = fetch(url);

      console.log(upload);
      var fileName = upload.name;
      var fileExtension = fileName.split(".").pop();
      const filesize = Math.floor(upload.size / 1024);

      function isType(fileExtension) {
        switch (fileExtension.toLowerCase()) {
          case "m4v":
            return "video";
          case "avi":
            return "video";
          case "mpg":
            return "video";
          case "mp4":
            return "video";
          case "jpg":
            return "image";
          case "gif":
            return "image";
          case "bmp":
            return "image";
          case "png":
            return "image";
          case "jpeg":
            return "image";
        }
        return null;
      }

      const storage = getStorage();

      const metadata = {
        contentType: upload.type,
      };
      const docRef = await addDoc(
        collection(db, "workspaces", this.userProfile.workspace, "media"),
        {
          title: fileName,
          extension: fileExtension,
          class: "media",
          created_at: new Date(),
          created_by: this.userProfile.uid,
          created_by_email: this.userProfile.email,
          workspace: this.userProfile.workspace,
          size: filesize,
          posts: [],
          templates: [],
          tags: [],
          type: isType(fileExtension),
        }
      );
      const storageRef = ref(
        storage,
        `${this.userProfile.workspace}/media/${docRef.id}.${fileExtension}`
      );

      await uploadBytesResumable(storageRef, upload, metadata);

      const downloadURL = await getDownloadURL(storageRef);
      const updateRef = doc(
        db,
        "workspaces",
        this.userProfile.workspace,
        "media",
        docRef.id
      );

      await updateDoc(updateRef, {
        uid: docRef.id,
        url: downloadURL,
      });

      this.$store.commit("setNotification", {
        show: true,
        text: "Media added!",
        type: "primary",
        icon: "",
      });

      this.$store.dispatch("fetchMedia");
      this.search_loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.commentbox {
  display: flex;
  gap: 10px;
  color: #050505;
  padding-bottom: 10px;
  .blob {
    background-color: #ebebef;
    padding: 12px;
    border-radius: 20px;
  }
}

.image-delete {
  background-color: rgba($color: #fff, $alpha: 0.4);
}
.animate__animated.animate__fadeInRight {
  --animate-duration: 0.3s;
}
</style>
