var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{style:({
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }),attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : 330,"elevation":"0"}},[_c('v-img',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? 140 : 200,"src":require("@/assets/pexels/1.jpeg")}},[(_vm.item.status === 'paused')?_c('v-btn',{staticStyle:{"position":"absolute","bottom":"0","width":"100%"},attrs:{"block":"","elevation":"0","color":"error","tile":""}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-pause")]),_vm._v(" Paused")],1):_vm._e()],1),_c('div',{staticClass:"px-6 pt-6"},[_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.item.variations.find((i) => i.type === "main").message)+" ")]),_c('p',{staticStyle:{"font-size":"10px","font-weight":"600","letter-spacing":"1px"}},[_vm._v(" "+_vm._s(_vm.item.variations.length)+" VARIATIONS ")])]),_c('div',{staticClass:"d-flex px-6 pb-6"},[_c('v-chip',{staticClass:"px-4 mr-4 font-weight-medium",staticStyle:{"height":"36px","border-radius":"50px"},attrs:{"color":_vm.item.category.color}},[_c('span',{style:({
              color: _vm.item.category.color === '#272727' ? '#fff' : '#272727',
            })},[_vm._v(_vm._s(_vm.item.category.name))])]),_c('v-badge',{attrs:{"overlap":"","color":"accent","left":"","avatar":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-img',{staticStyle:{"border":"0.5px solid #fff","border-radius":"50px"},attrs:{"height":"20","width":"20","src":require("@/assets/facebook.png")}})]},proxy:true}])},[_c('v-avatar',{style:({
              border: '1px solid' + '#f0f0f0',
            }),attrs:{"size":"36"}},[_c('img',{attrs:{"src":require("@/assets/braingineers.png"),"alt":"John"}})])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }