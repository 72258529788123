<template>
  <div>
    <v-dialog
      width="70vw"
      v-model="dialog"
      transition="dialog-bottom-transition"
      style="border-radius: 10px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
            borderRadius: '10px',
          }"
        >
          <v-btn
            style="font-size: 9pt"
            color="pink"
            elevation="0"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small class="mr-1">mdi-lightbulb</v-icon>Demo</v-btn
          >
        </div>
      </template>

      <v-card
        flat
        width="70vw"
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
          borderRadius: '15px',
        }"
        class="onboarding primary"
      >
        <div class="pa-2">
          <v-card
            elevation="10"
            class="left primary d-flex flex-column pa-2"
            height="100%"
          >
            <h1 class="white--text">{{ nav }}</h1>
            <v-list v-model="nav" dark dense rounded color="transparent">
              <v-list-item link>
                <v-list-item-title>Welcome</v-list-item-title>
              </v-list-item>

              <v-list-group
                v-model="nav"
                group="nav"
                active-class="white--text"
              >
                <template v-slot:activator>
                  <v-list-item-content active-class="white--text">
                    <v-list-item-title>Creating posts</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item link class="pl-8" active-class="white--text">
                  <v-list-item-title>Topic ideation</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon small>mdi-lightbulb</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item link class="pl-8" active-class="white--text">
                  <v-list-item-title>Writing copy</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon small>mdi-pencil</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item link class="pl-8" active-class="white--text">
                  <v-list-item-title>Adding media</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon small>mdi-image</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item link class="pl-8" active-class="white--text">
                  <v-list-item-title>Adding links</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon small>mdi-link</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>

              <v-list-item link>
                <v-list-item-title>Categorize & schedule</v-list-item-title>
              </v-list-item>

              <v-list-group active-class="white--text">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Finetune Stix AI</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item link class="pl-8" active-class="white--text">
                  <v-list-item-title>Tone of voice</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon small>mdi-lightbulb</v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item link class="pl-8" active-class="white--text">
                  <v-list-item-title>Structures</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon small>mdi-pencil</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>

              <v-list-item link>
                <v-list-item-title>All set!</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </div>

        <div class="d-flex align-center justify-center">
          <v-btn icon @click="nav = nav - 1"
            ><v-icon>mdi-chevron-left</v-icon></v-btn
          >
        </div>

        <div class="px-6 py-10">
          <v-window v-model="nav">
            <v-window-item :value="1">
              <div class="d-flex align-center flex-column">
                <h1 style="font-size: 22pt" class="mb-2">
                  <span style="font-weight: 400">Welcome to</span>
                  Stix
                </h1>

                <h2 style="font-weight: 400" class="mb-8 text-center">
                  <b> Stix is a social media autopilot</b> and built for
                  companies like Stix
                  <br />
                  who have little or no time for social media marketing.
                </h2>

                <div class="d-flex flex-column" style="gap: 10px">
                  <v-card
                    flat
                    class="px-8 py-4"
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                    }"
                  >
                    <h2>Create posts with ease</h2>
                  </v-card>
                  <v-card
                    flat
                    class="px-8 py-4"
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                    }"
                  >
                    <h2>No more manual publishing</h2>
                  </v-card>
                  <v-card
                    flat
                    class="px-8 py-4"
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                    }"
                  >
                    <h2>Infinite content ideas</h2>
                  </v-card>
                </div>

                <div class="d-flex mt-8 flex-column" style="gap: 10px">
                  <div
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                      borderRadius: '8px',
                    }"
                  >
                    <v-btn
                      elevation="0"
                      color="button"
                      class="primary--text"
                      @click="onboarding = 2"
                      >Create my first post with A.I.</v-btn
                    >
                  </div>

                  <v-btn
                    small
                    text
                    @click="$store.commit('setOnboarding', false)"
                    >Skip onboarding</v-btn
                  >
                </div>
              </div>
            </v-window-item>

            <v-window-item :value="2">
              <div class="d-flex align-center flex-column">
                <h1 style="font-size: 22pt" class="mb-2">
                  <span style="font-weight: 400">Create my first post</span>
                  with A.I.
                </h1>

                <h2 style="font-weight: 400" class="mb-8 text-center">
                  See how easy and fast you will get your posts written. <br />
                  A social post should cost minutes, not hours.
                </h2>

                <div style="width: 50%">
                  <div class="d-flex mb-2">
                    <v-spacer></v-spacer>
                    <v-btn
                      rounded
                      small
                      class="animate__animated animate__slideInRight"
                      elevation="0"
                      dark
                      @click="onboarding = 3"
                      >Topics suggestions</v-btn
                    >
                  </div>
                  <v-textarea
                    style="border-radius: 8px"
                    v-model="prompt"
                    solo
                    class="mb-4 pa-0"
                    flat
                    autofocus
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                    }"
                    hide-details=""
                    placeholder="Write a social media post about the top three trends within the field of neuromarketing."
                  ></v-textarea>

                  <div
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                      borderRadius: '8px',
                    }"
                  >
                    <v-btn
                      elevation="0"
                      color="button"
                      class="primary--text"
                      block
                      @click="onboarding = 4"
                      >Generate post</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-window-item>

            <v-window-item :value="3">
              <div class="d-flex align-center flex-column">
                <h1 style="font-size: 22pt" class="mb-2">
                  <span style="font-weight: 400">Topics</span>
                  with A.I.
                </h1>

                <h2 style="font-weight: 400" class="mb-8 text-center">
                  Unlimited topics to write your social posts about.
                </h2>

                <div style="width: 80%" class="d-flex flex-column align-center">
                  <v-sheet
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                      borderRadius: '8px',
                    }"
                    v-for="(t, index) in topics"
                    :key="index"
                    class="topic mb-2"
                    style="position: relative"
                  >
                    <p>{{ t }}</p>
                    <v-btn @click="onboarding = 2" rounded small dark
                      >Create post</v-btn
                    >
                  </v-sheet>

                  <div class="d-flex mt-4" style="gap: 10px">
                    <v-btn text color="primary" @click="onboarding = 2"
                      ><v-icon small class="mr-2">mdi-chevron-left</v-icon>
                      Back</v-btn
                    >
                    <v-btn elevation="0" color="primary" @click="onboarding = 2"
                      ><v-icon small class="mr-2">mdi-refresh</v-icon>
                      Refresh</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-window-item>

            <v-window-item :value="4">
              <div class="d-flex align-center flex-column">
                <h1 style="font-size: 22pt" class="mb-8">
                  <span style="font-weight: 400">My first post</span>
                  with A.I.
                </h1>

                <v-card
                  flat
                  width="50%"
                  :style="{
                    borderBottom: '3px solid #272727',
                    borderLeft: '1px solid #272727',
                    borderTop: '1px solid #272727',
                    borderRight: '3px solid #272727',
                  }"
                >
                  <v-sheet
                    style="font-size: 10pt; font-weight: 600"
                    color="accent"
                    class="text-center py-1"
                    >Preview</v-sheet
                  >
                  <v-divider class="primary"></v-divider>
                  <Preview :message="post" />
                </v-card>

                <div class="d-flex mt-8" style="gap: 10px">
                  <v-btn text color="primary" @click="onboarding = 2"
                    ><v-icon small class="mr-2">mdi-chevron-left</v-icon> Try
                    again</v-btn
                  >
                  <div
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                      borderRadius: '8px',
                    }"
                  >
                    <v-btn @click="usePost" elevation="0" color="button">
                      Continue
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-window-item>

            <v-window-item :value="5">
              <div class="d-flex align-center flex-column">
                <h1 style="font-size: 22pt" class="mb-2">
                  <span style="font-weight: 400">Finish your </span>first post
                </h1>
                <h2 style="font-weight: 400" class="mb-8 text-center">
                  <b> Click 'continue' </b>to finish your first post. <br />Add
                  an image or variations and save the post.
                </h2>
                <div
                  style="
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 20px;
                  "
                >
                  <v-card
                    flat
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                    }"
                    style="background-color: #f0f0f0"
                  >
                    <v-sheet
                      style="font-size: 10pt; font-weight: 600"
                      color="accent"
                      class="text-center py-1"
                      >Add media</v-sheet
                    >
                    <v-divider class="primary"></v-divider>
                    <img
                      style="max-width: 100%"
                      :src="require('@/assets/add_media.png')"
                    />
                  </v-card>

                  <v-card
                    flat
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                    }"
                    style="background-color: #f0f0f0"
                  >
                    <v-sheet
                      style="font-size: 10pt; font-weight: 600"
                      color="accent"
                      class="text-center py-1"
                      >Add variations</v-sheet
                    >
                    <v-divider class="primary"></v-divider>
                    <img
                      style="max-width: 100%"
                      :src="require('@/assets/add_variations.png')"
                    />
                  </v-card>
                </div>

                <div
                  :style="{
                    borderBottom: '3px solid #272727',
                    borderLeft: '1px solid #272727',
                    borderTop: '1px solid #272727',
                    borderRight: '3px solid #272727',
                    borderRadius: '8px',
                  }"
                  class="mt-8"
                >
                  <v-btn
                    @click="
                      dialog = false;
                      onboarding = 1;
                    "
                    elevation="0"
                    color="button"
                  >
                    Continue
                  </v-btn>
                </div>
              </div>
            </v-window-item>
          </v-window>
        </div>
        <div class="d-flex align-center justify-center">
          <v-btn icon @click="nav++"><v-icon>mdi-chevron-right</v-icon></v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Preview from "./preview/PreviewFacebook.vue";

export default {
  props: [],
  components: { Preview },
  computed: {
    dialog: {
      get() {
        return this.$store.state.onboarding;
      },
      set(value) {
        this.$store.commit("setOnboarding", value);
      },
    },
  },
  data() {
    return {
      selectedItem: 0,
      onboarding: 1,
      nav: 1,
      post: "this is the post result",
      prompt:
        "Write a short informative post about the top three trends within the field of neuromarketing for 2023.",
      topics: [
        "Top 3 trends within neuromarketing research for 2023",
        "Top 3 trends within neuromarketing research for 2023",
        "Top 3 trends within neuromarketing research for 2023",
      ],
    };
  },
  methods: {
    usePost() {
      this.onboarding = 5;
      this.$router.replace({
        name: "Post",
        params: {
          message: this.post,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.animate__animated.animate__slideInRight {
  --animate-duration: 0.5s;
}

.content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 25px;
}

.listitem {
  font-weight: 400;
}
.onboarding {
  display: grid;
  grid-template-columns: 250px 50px 3fr 50px;
  background-image: url("@/assets/backgrounds/pinkgradient.png");

  .left {
    border-right: 3px solid #272727;
  }
}

.topic {
  padding: 10px 25px;
  display: grid;
  grid-template-columns: auto 140px;
  gap: 25px;
  justify-content: space-between;
  align-items: center;

  p {
    padding: 0;
    margin: 0;
    font-size: 11pt;
  }
}
</style>
