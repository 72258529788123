<template>
  <div class="container primary white--text">
    <div class="mb-8">
      <v-img
        width="100"
        max-height="70"
        contain
        src="@/assets/logo/logo-white.svg"
      ></v-img>
      <h1 class="white--text">Stix</h1>
    </div>
    <div v-if="success">
      <h1 class="white--text">Your signup has been received!</h1>
    </div>
    <div class="center" v-if="!success">
      <h1 style="font-weight: 500; font-size: 30px" class="white--text mb-2">
        Beta signup form
      </h1>
      <p class="white--text mb-8" style="max-width: 400px">
        Hi! Thanks for your interest to join the Beta program. I just need some
        basic info about you and your company in order to review your
        application.
      </p>

      <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px">
        <div class="mb-4">
          <p class="white--text mb-2" style="font-size: 12px">First name</p>
          <v-text-field
            solo
            v-model="first_name"
            flat
            background-color="smoke"
            label="First name"
            hide-details=""
            style="border-radius: 6px"
          ></v-text-field>
        </div>
        <div class="mb-4">
          <p class="white--text mb-2" style="font-size: 12px">Last name</p>
          <v-text-field
            solo
            v-model="last_name"
            flat
            background-color="smoke"
            label="Last name"
            hide-details=""
            style="border-radius: 6px"
          ></v-text-field>
        </div>
      </div>

      <div class="mb-4">
        <p class="white--text mb-2" style="font-size: 12px">Work email:</p>
        <v-text-field
          solo
          v-model="email"
          flat
          background-color="smoke"
          label="name@company.com"
          hide-details=""
          style="border-radius: 6px"
        ></v-text-field>
      </div>
      <div class="mb-4">
        <p class="white--text mb-2" style="font-size: 12px">
          Your company's website:
        </p>
        <v-text-field
          solo
          flat
          v-model="website"
          hide-details=""
          background-color="smoke"
          label="www.yourcompany.com"
          style="border-radius: 6px"
        ></v-text-field>
      </div>

      <div class="mb-4">
        <p class="white--text mb-2" style="font-size: 12px">
          How big is your company?
        </p>
        <v-select
          solo
          flat
          hide-details=""
          background-color="smoke"
          label="Company size"
          style="border-radius: 6px"
          :items="company_sizes"
          v-model="company_size"
        >
        </v-select>
      </div>

      <div class="mb-4">
        <p class="white--text mb-2" style="font-size: 12px">
          How experienced are you with social media management?
        </p>
        <v-select
          solo
          flat
          hide-details=""
          background-color="smoke"
          label="Experience"
          style="border-radius: 6px"
          :items="experience_options"
          v-model="experience"
        >
        </v-select>
      </div>

      <div class="mb-4">
        <p class="white--text mb-2" style="font-size: 12px">
          What industry are you in?
        </p>
        <v-select
          solo
          flat
          hide-details=""
          background-color="smoke"
          label="Industry"
          style="border-radius: 6px"
          :items="industry_options"
          v-model="industry"
        >
        </v-select>
      </div>

      <div class="mb-4">
        <p class="white--text mb-2" style="font-size: 12px">
          What best describes your role?
        </p>
        <v-select
          solo
          flat
          background-color="smoke"
          label="Role"
          style="border-radius: 6px"
          hide-details=""
          :items="role_options"
          v-model="role"
        >
        </v-select>
      </div>

      <v-btn
        @click="add"
        height="48"
        block
        color="accent"
        class="mt-8 primary--text"
        >Join waiting list</v-btn
      >
    </div>
    <div style="margin-top: 100px">
      <v-img
        width="100"
        max-height="40"
        contain
        src="@/assets/logo/logo-white.svg"
      ></v-img>
    </div>
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  data() {
    return {
      company_size: null,
      company_sizes: ["Just me", "2 - 10", "11 - 50", "51 - 200", "200+"],
      email: "",
      experience: null,
      experience_options: [
        "I don't have experience with software to manage social media.",
        "I'm familiar with software to manage social media.",
      ],
      first_name: "",
      last_name: "",
      industry: null,
      industry_options: [
        "Software & Services (e.g. SaaS)",
        "Agency (Advertising, Marketing, Design)",
        "Professional Services",
        "E-Commerce, Retail and Consumer Goods",
        "Consumer Apps and Media",
        "Healthcare",
        "Consumer Services",
        "Education",
        "Other",
      ],
      role: null,
      role_options: [
        "Founder / CEO",
        "Customer Success / Support",
        "Sales",
        "Design",
        "Product / Engineering",
        "Marketing",
        "Human Resources",
        "Other",
      ],
      success: false,
      website: "",
    };
  },
  methods: {
    async add() {
      // TODO ADD BETA SIGNUPS TO FIREBASE AS WELL.
      // TODO ASK ALSO WHICH CHANNELS/PLATFORMS THEY WANT TO USE.
      // TODO ASK WHAT THEY FIND MOST IMPORTANT IN A SOCIAL MEDIA TOOL (FEED OVERVIEW, MOBILE APP/WEBSITE, SCHEDULING, OPENAI INTEGRATION, PEXELS INTEGRATION)
      // TODO send email as confirmation for signup for beta
      const functions = getFunctions();
      const get = httpsCallable(functions, "addBetaToAudience");
      let user = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        company_size: this.company_size,
        company_website: this.website,
        role: this.role,
        company_industry: this.industry,
        experience: this.experience,
      };
      const res = await get(user);
      if (res.data === 200) {
        this.success = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-bottom: 100px;

  .center {
    max-width: 400px;
  }
}
</style>
