<template>
  <div
    class="signup-container"
    :class="{
      mob: $vuetify.breakpoint.smAndDown,
      desk: !$vuetify.breakpoint.smAndDown,
    }"
  >
    <div class="left">
      <div
        class="sub-container form"
        :style="{
          width: $vuetify.breakpoint.smAndDown ? '80%' : '60%',
          paddingBottom: $vuetify.breakpoint.smAndDown ? '0' : '130px',
          marginTop: $vuetify.breakpoint.smAndDown ? '30px' : '130px',
        }"
      >
        <v-img
          width="50"
          class="mb-8"
          src="@/assets/logos/icon-dark.svg"
          contain
        ></v-img>
        <div>
          <h1
            :style="{
              fontSize: $vuetify.breakpoint.smAndDown ? '35px' : '40px',
            }"
          >
            Reset your password
          </h1>
          <v-text-field
            v-if="!sent"
            placeholder="Email"
            flat
            solo
            hide-details=""
            :style="{
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }"
            style="border-radius: 8px"
            v-model="email"
            class="my-4"
          ></v-text-field>
          <p v-if="sent">
            An email has been sent to {{ email }}. Click the button in the
            e-mail to continue.
          </p>
          <v-btn
            @click="resetPassword"
            block
            elevation="0"
            height="50"
            color="primary"
            class="mt-2"
            >Reset password</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default {
  data() {
    return {
      email: "",
      overlay: false,
      sent: false,
    };
  },
  methods: {
    resetPassword() {
      console.log("Resetting password");
      const auth = getAuth();
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.sent = true;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.signup-container {
  height: 100vh;
  background-color: white;

  .left {
    display: grid;
    grid-template-rows: 100px auto;

    .logo {
      display: flex;
      gap: 8px;
      width: 100px;
      align-items: center;
      padding-left: 30px;
    }

    .sub-container {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      max-width: 450px;

      &.form {
        justify-content: flex-start;
      }
    }
  }
}
</style>
