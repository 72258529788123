import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

let firebaseConfig;
if (window.location.origin.includes("app.stix.social")) {
  console.log("Running Production Environment");
  firebaseConfig = {
    apiKey: "AIzaSyCEl_4cdhI3I6ID84lm1cwhLBEH9fxYeEI",
    // authDomain: "one-startup-stack.firebaseapp.com",
    authDomain: "auth.stix.social",
    projectId: "one-startup-stack",
    storageBucket: "one-startup-stack.appspot.com",
    messagingSenderId: "777802482001",
    appId: "1:777802482001:web:d81304efd88b14f2675370",
    measurementId: "G-MBTQMX30QK",
  };
} else {
  console.log("Running Staging Environment");
  firebaseConfig = {
    apiKey: "AIzaSyB8EkS_lE4jnAxOD65aYmLF4VfydUwRfvs",
    authDomain: "stix-staging-f240f.firebaseapp.com",
    projectId: "stix-staging-f240f",
    storageBucket: "stix-staging-f240f.appspot.com",
    messagingSenderId: "156531495317",
    appId: "1:156531495317:web:ace2dfd8329579a6c4669c",
    measurementId: "G-Z064NBGKN6",
  };
}

// STAGING
// const firebaseConfig = {
//   apiKey: "AIzaSyB8EkS_lE4jnAxOD65aYmLF4VfydUwRfvs",
//   authDomain: "stix-staging-f240f.firebaseapp.com",
//   projectId: "stix-staging-f240f",
//   storageBucket: "stix-staging-f240f.appspot.com",
//   messagingSenderId: "156531495317",
//   appId: "1:156531495317:web:ace2dfd8329579a6c4669c",
//   measurementId: "G-Z064NBGKN6"
// };

const app = initializeApp(firebaseConfig);

import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getApp } from "firebase/app";
const storage = getStorage(app);

let emulator = true;

if (emulator && window.location.origin.includes("localhost")) {
  console.log("Starting Functions Emulators");
  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// utils
const db = getFirestore(app);
const auth = getAuth;

// export utils/refs
export { db, auth, storage };
