var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{style:({
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
        backgroundColor: '#D3F987',
      }),attrs:{"max-width":"500","flat":""}},[_c('div',{staticClass:"px-6 py-6",staticStyle:{"display":"grid","grid-template-columns":"auto 70px"}},[_c('div',[_c('h2',{staticClass:"mb-2"},[_vm._v("Referrals")]),_c('p',{staticClass:"ma-0",staticStyle:{"font-size":"14px"}},[_vm._v(" For every referral, "),_c('b',[_vm._v("you ánd your friend")]),_vm._v(" will receive one free month of subscription. "),_c('u',[_vm._v("So far you have received "),_c('b',[_vm._v("1 free month")]),_vm._v(".")])]),_c('v-btn',{staticClass:"mt-3",attrs:{"small":"","elevation":"0","color":"primary"}},[_vm._v("Read more")])],1),_c('div',{staticClass:"pl-4 d-flex align-center"},[_c('v-img',{staticClass:"d-flex align-center justify-center text-center",attrs:{"width":"60","src":require("@/assets/icons/arrow-empty.svg")}},[_c('h1',[_vm._v("1")])])],1)])]),_c('v-card',{staticClass:"pa-6 mt-4",style:({
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }),attrs:{"flat":"","max-width":"500"}},[_c('div',{staticClass:"referrals"},[_c('p',{staticClass:"mb-1",staticStyle:{"font-size":"10px"}},[_vm._v("REFERRAL PROGRAM")]),_c('h1',[_vm._v("Get free months")]),_c('p',{staticClass:"mb-0 mt-2",staticStyle:{"font-size":"12px"}},[_vm._v(" Let others sign up via your unique link to receive one month free per referral: ")]),_c('div',{staticClass:"d-flex align-center mt-2",staticStyle:{"gap":"10px"}},[_c('v-btn',{staticClass:"smoke",attrs:{"icon":""}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-content-copy")])],1),_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"12px"}},[_c('b',[_vm._v("https://www.getstix.com/access?referral="+_vm._s(_vm.userProfile.uid))])])],1),_c('p',{staticClass:"mb-0 mt-4",staticStyle:{"font-size":"12px"}},[_vm._v("Share on social:")]),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"#3b5998","icon":""}},[_c('v-icon',[_vm._v("mdi-facebook")])],1),_c('v-btn',{attrs:{"color":"#0072b1","icon":""}},[_c('v-icon',[_vm._v("mdi-linkedin")])],1),_c('v-btn',{attrs:{"color":"#bc2a8d","icon":""}},[_c('v-icon',[_vm._v("mdi-instagram")])],1)],1),_c('p',{staticClass:"mb-0 mt-2",staticStyle:{"font-size":"12px"}},[_vm._v(" Or share your code directly: ")]),_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"12px"}},[_c('b',[_vm._v(_vm._s(_vm.userProfile.uid))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }