<template>
  <div>
    <v-dialog v-model="dialog" max-width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn elevation="0" v-bind="attrs" v-on="on" color="primary" block
          >Add schedule</v-btn
        >
      </template>

      <v-card
        class="pa-4"
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <div class="pa-4 d-flex flex-wrap align-center" style="gap: 10px">
          <p class="mb-0">From</p>
          <v-select
            class="mt-0 pt-0"
            :items="filteredCategories"
            item-text="name"
            placeholder="Category"
            hide-details=""
            item-value="uid"
            v-model="category"
            style="max-width: 150px"
          ></v-select>
          <p class="mb-0">post every</p>
          <v-text-field
            class="mt-0 pt-0"
            type="number"
            placeholder="2"
            v-model="frequency"
            hide-details=""
            persistent-placeholder
            style="max-width: 50px"
          ></v-text-field>
          <v-select
            class="mt-0 pt-0 pl-2"
            placeholder="Weeks"
            hide-details=""
            v-model="type"
            persistent-placeholder
            :items="frequencyItems"
            style="max-width: 100px"
          ></v-select>
          <p class="mb-0">at</p>
          <v-select
            class="mt-0 pt-0"
            placeholder="08:00"
            persistent-placeholder
            hide-details=""
            v-model="timeslot"
            style="max-width: 100px"
            :items="timeslots"
          ></v-select>
          <p class="mb-0" v-if="type === 'Weeks'">on a</p>
          <v-select
            v-if="type === 'Weeks'"
            class="pl-2 mt-0 pt-0"
            style="max-width: 150px"
            hide-details=""
            v-model="day"
            placeholder="Day of week"
            item-text="text"
            item-value="value"
            :items="days"
            multiple
          ></v-select>
          <span v-if="type === 'Months'" style="font-size: 13px">on the</span>
          <v-select
            placeholder="First"
            v-model="month_day"
            style="max-width: 100px"
            :items="dayItems"
            v-if="type === 'Months'"
            persistent-placeholder
          ></v-select>
          <v-select
            style="max-width: 130px"
            v-model="day"
            item-text="text"
            item-value="value"
            :items="days"
            v-if="type === 'Months'"
          ></v-select>
          <span v-if="type === 'Months'" style="font-size: 13px">
            of the month.
          </span>

          <span v-if="type === 'Days'" class="pl-2" style="font-size: 13px"
            >Starting next</span
          >
          <v-select
            v-if="type === 'Days'"
            label="Day"
            hide-details=""
            style="max-width: 130px"
            class="mb-4"
            v-model="day"
            item-text="text"
            item-value="value"
            :items="days"
          ></v-select>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            class="px-6"
            elevation="0"
            @click="addToSchedule"
          >
            Add to schedule
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      categories: (state) => state.posts.categories,
      schedules: (state) => state.schedules.schedules,
    }),
    filteredCategories() {
      return this.categories.filter(
        (c) => !c.schedules || c.schedules.length === 0
      );
    },
  },
  data() {
    return {
      category: null,
      day: null,
      dayItems: [
        {
          text: "First",
          value: 1,
        },
        {
          text: "2nd",
          value: 2,
        },
        {
          text: "3rd",
          value: 3,
        },
        {
          text: "4th",
          value: 4,
        },
        {
          text: "Last",
          value: 99,
        },
      ],
      days: [
        {
          text: "Sunday",
          value: 0,
        },
        {
          text: "Monday",
          value: 1,
        },
        {
          text: "Tuesday",
          value: 2,
        },
        {
          text: "Wednesday",
          value: 3,
        },
        {
          text: "Thursday",
          value: 4,
        },
        {
          text: "Friday",
          value: 5,
        },
        {
          text: "Saturday",
          value: 6,
        },
      ],
      dialog: false,
      frequency: "",
      frequencyItems: ["Days", "Weeks", "Months"],
      month_day: "",
      picker: null,
      timeslot: "",
      timeslots: [
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "00:00",
      ],
      type: "",
    };
  },
  methods: {
    async addToSchedule() {
      let schedule = {
        timeslot: this.timeslot,
        day_of_week: this.day,
        frequency: Number(this.frequency),
        created: new Date(),
        created_by_uid: this.userProfile.uid,
        created_by_email: this.userProfile.email,
        category: this.category,
        type: this.type,
        month_day: this.month_day,
      };

      const docRef = await addDoc(
        collection(db, "workspaces", this.userProfile.workspace, "schedules"),
        schedule
      );

      await updateDoc(
        doc(
          db,
          "workspaces",
          this.userProfile.workspace,
          "schedules",
          docRef.id
        ),
        {
          uid: docRef.id,
        }
      );

      console.log("Schedule added");
      this.$store.dispatch("fetchEvents");
      this.$store.dispatch("fetchCategories");
      this.$store.dispatch("fetchSchedules");

      this.timeslot = null;
      this.day = null;
      this.frequency = null;
      this.category = null;
      this.dialog = false;
    },
    open(c) {
      this.dialog = true;
      this.category = c;
    },
  },
};
</script>

<style lang="scss" scoped>
.split {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 5px;
}
</style>
