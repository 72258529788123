<template>
  <div class="subscription">
    <div>
      <v-card
        v-if="workspace"
        flat
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
        max-width="500"
        class="mb-4"
      >
        <div>
          <v-sheet
            class="d-flex justify-center align-center py-2"
            color="accent"
            style="
              font-size: 10pt;
              font-weight: 600;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            "
          >
            Subscription
          </v-sheet>
          <v-divider class="primary"></v-divider>
        </div>
        <div class="d-flex align-center justify-space-between px-6 py-4">
          <div>
            <h2>
              Subscription
              <v-chip
                class="ml-2 font-weight-regular"
                small
                color="success"
                v-if="workspace.subscription.active"
                >Active</v-chip
              >
            </h2>
            <p style="font-size: 14px" class="ma-0 mt-1">
              You're currently on the paid plan.
            </p>
          </div>
          <v-spacer></v-spacer>
          <v-btn small text>Cancel</v-btn>
        </div>
        <div
          v-if="
            workspace.subscription.discount_code ||
            workspace.subscription.discount_value
          "
        >
          <v-divider></v-divider>
          <div class="px-6 py-4">
            <p style="font-size: 14px" class="ma-0">
              <b
                >Discount<span
                  v-if="workspace.subscription.discount_code === 'STARTUP'"
                >
                  Startup Program</span
                ></b
              >: ${{ workspace.subscription.discount_value }}
              monthly:
              <span style="text-decoration: line-through">$89</span>
              <v-icon x-small class="ml-1 pb-1">mdi-arrow-right</v-icon> ${{
                89 - workspace.subscription.discount_value
              }}
            </p>
          </div>
        </div>
      </v-card>

      <v-card
        v-if="workspace"
        flat
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
        max-width="500"
        class="mb-4"
      >
        <div>
          <v-sheet
            class="d-flex justify-center align-center py-2"
            color="accent"
            style="
              font-size: 10pt;
              font-weight: 600;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            "
          >
            Invoices
          </v-sheet>
          <v-divider class="primary"></v-divider>
        </div>

        <div style="width: 100%">
          <v-data-table
            class=""
            :headers="headersSub"
            :items="itemsSub"
            hide-default-header
            mobile-breakpoint="100"
            :hide-default-footer="itemsSub.length > 0"
          >
            <template v-slot:[`item.amount`]="{ item }">
              <span>${{ item.amount }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                small
                :color="item.status === 'paid' ? 'success' : 'info'"
                >{{ capitalize(item.status) }}</v-chip
              >
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="download(item)">
                <v-icon color="primary" small> mdi-download </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <div>
            <v-divider></v-divider>
            <div class="px-6 pb-2">
              <p style="font-size: 10px" class="mb-2 mt-4">Send invoices to:</p>
              <v-text-field
                class="mb-2"
                label="invoices@yourcompany.com"
                hide-details=""
                solo
                dense
                v-model="workspace.subscription.invoices_email"
                flat
                background-color="smoke"
                style="border-radius: 8px; font-size: 10pt"
                @keypress="enter"
              >
                <template slot="append">
                  <v-btn
                    color="primary"
                    small
                    elevation="0"
                    @click="updateEmail"
                    >Save</v-btn
                  >
                </template>
              </v-text-field>
            </div>
          </div>
        </div>
      </v-card>

      <v-card
        class="mt-4"
        max-width="500"
        flat
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <div>
          <v-sheet
            class="d-flex justify-center align-center py-2"
            color="accent"
            style="
              font-size: 10pt;
              font-weight: 600;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            "
          >
            Startup program
          </v-sheet>
          <v-divider class="primary"></v-divider>
        </div>
        <div class="startup">
          <v-img width="100" contain src="@/assets/icons/rocket.jpg"></v-img>
          <div class="py-6 pl-2 pr-6">
            <p style="font-size: 10px" class="mb-1">STARTUP PROGRAM</p>
            <h1>Get 79% discount as a startup</h1>
            <p class="mb-1" style="font-size: 12px">
              Startups younger than <b>10</b> years are eligable for the
              program. Apply today for your discount.
            </p>
            <v-btn color="primary" small class="mt-2" elevation="0"
              >Apply</v-btn
            >
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      workspace: (state) => state.workspace.workspace,
    }),
  },
  data() {
    return {
      headersSub: [
        {
          text: "Invoices",
          align: "start",
          sortable: false,
          value: "month",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "amount",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      itemsSub: [
        {
          month: "January",
          status: "paid",
          amount: 29,
          discount: 60,
        },
      ],
    };
  },
  methods: {
    capitalize(string) {
      let firstCharacter = string.charAt(0);
      firstCharacter = firstCharacter.toUpperCase();
      let capitalizedString = firstCharacter + string.slice(1);
      return capitalizedString;
    },
    download(i) {
      console.log(i);
    },
    enter(v) {
      if (v.key === "Enter") {
        this.updateEmail();
      }
    },
    updateEmail() {
      this.$store.dispatch("updateWorkspace", {
        type: "subscription.invoices_email",
        value: this.workspace.subscription.invoices_email,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.startup {
  display: grid;
  grid-template-columns: 100px auto;
}
</style>
