<template>
  <v-bottom-sheet v-model="sheet">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        height="52.75"
        elevation="0"
        class="ml-2"
        outlined
        v-if="$vuetify.breakpoint.smAndDown"
        :style="{
          borderBottom: '2px solid #272727',
          borderLeft: '0.75px solid #272727',
          borderTop: '0.75px solid #272727',
          borderRight: '2px solid #272727',
        }"
        ><v-icon>mdi-filter</v-icon></v-btn
      >
    </template>
    <v-sheet class="text-center" height="430px" color="#f0f0f0">
      <div class="pa-6">
        <p style="font-size: 10px" class="mb-2 mt-4">Filters</p>
        <v-select
          label="Category"
          solo
          style="border-radius: 8px"
          :style="{
            borderBottom: '2px solid #272727',
            borderLeft: '0.75px solid #272727',
            borderTop: '0.75px solid #272727',
            borderRight: '2px solid #272727',
          }"
          hide-details=""
          class="mb-4"
          :items="$store.state.categories"
          item-text="name"
          return-object
          height="50"
          flat
        ></v-select>
        <v-select
          label="Account"
          :style="{
            borderBottom: '2px solid #272727',
            borderLeft: '0.75px solid #272727',
            borderTop: '0.75px solid #272727',
            borderRight: '2px solid #272727',
          }"
          solo
          style="border-radius: 8px"
          hide-details=""
          class="mb-4"
          height="50"
          flat
        ></v-select>
        <v-select
          label="Creator"
          solo
          :style="{
            borderBottom: '2px solid #272727',
            borderLeft: '0.75px solid #272727',
            borderTop: '0.75px solid #272727',
            borderRight: '2px solid #272727',
          }"
          style="border-radius: 8px"
          hide-details=""
          class="mb-4"
          height="50"
          flat
        ></v-select>
        <v-select
          label="Status"
          height="50"
          solo
          hide-details=""
          flat
          :style="{
            borderBottom: '2px solid #272727',
            borderLeft: '0.75px solid #272727',
            borderTop: '0.75px solid #272727',
            borderRight: '2px solid #272727',
          }"
          style="border-radius: 8px"
        ></v-select>
      </div>
      <div class="d-flex justify-center" style="gap: 10px">
        <v-btn elevation="0" color="button">Filter</v-btn>
        <v-btn @click="sheet = !sheet" elevation="0" color="primary" text
          >Close</v-btn
        >
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  data() {
    return {
      sheet: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
