import { render, staticRenderFns } from "./AccessWorkspace.vue?vue&type=template&id=6ce63ef4&scoped=true&"
import script from "./AccessWorkspace.vue?vue&type=script&lang=js&"
export * from "./AccessWorkspace.vue?vue&type=script&lang=js&"
import style0 from "./AccessWorkspace.vue?vue&type=style&index=0&id=6ce63ef4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce63ef4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VAutocomplete,VBtn,VCard,VIcon,VImg,VSelect,VTextField,VTextarea,VWindow,VWindowItem})
