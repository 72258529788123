<template>
  <v-navigation-drawer
    :value="$store.state.queue"
    app
    color="primary"
    temporary
    right
    width="600"
    style="z-index: 99"
    @input="changeDrawer"
  >
    <div class="pa-8">
      <div class="mb-4 d-flex justify-space-between align-center">
        <h1 class="white--text">Upcoming posts</h1>
        <v-btn @click="$store.commit('setQueue', false)" icon color="white"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>

      <!-- <p class="mb-4 white--text" style="font-size: 12px">Upcoming posts.</p> -->

      <div class="posts">
        <div v-for="(p, index) in queue" :key="index">
          <div class="d-flex align-center justify-space-between mb-2">
            <p
              style="color: white; font-size: 12px; font-weight: 600"
              class="mb-0"
            >
              {{ getDate(p.unix) }}
            </p>
          </div>
          <v-card flat class="pa-4 post" v-if="p.post">
            <img
              v-if="p.post.media || p.post.link_preview"
              :src="p.post.media ? p.post.media.url : p.post.link_preview.image"
              alt=""
              style="
                object-fit: contain;
                height: 100%;
                max-width: 100%;
                border-radius: 5px;
              "
            />
            <p
              class="mb-0 px-4 line-clamp"
              style="font-size: 12px; height: 165px"
            >
              {{ p.post.variation.text }}
            </p>
          </v-card>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";

export default {
  props: ["drawer"],
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      calendar: (state) => state.schedules.calendar,
    }),
    queue() {
      let list = [];

      for (let x in this.calendar) {
        for (let y in this.calendar[x].days) {
          if (this.calendar[x].days[y].posts.length > 0) {
            for (let z in this.calendar[x].days[y].posts) {
              list.push(this.calendar[x].days[y].posts[z]);
            }
          }
        }
      }

      return list.slice(0, 9);
    },
  },
  methods: {
    getDate(t) {
      let time = DateTime.fromSeconds(t);
      return time.toFormat("cccc DD @ T");
    },
    changeDrawer(v) {
      if (!v) {
        this.$store.commit("setQueue", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.posts {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.post {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
