<template>
  <div>
    <div class="mb-4 d-flex align-center" style="gap: 20px">
      <v-btn
        elevation="0"
        color="primary"
        @click="addTemplate"
        height="40"
        class="px-6"
        v-if="userProfile && userProfile.superadmin"
        >Add template</v-btn
      >
      <v-select
        :menu-props="{ offsetY: true }"
        multiple
        :items="items"
        v-model="selected"
        :loading="loading.templates"
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
          maxWidth: '200px',
        }"
        dense
        solo
        flat
        style="border-radius: 8px"
        label="Tags"
        clearable
        hide-details=""
        :no-data-text="
          loading.templates ? 'Fetching templates..' : 'No templates'
        "
      >
      </v-select>
    </div>
    <div class="d-flex flex-wrap" style="gap: 20px">
      <TemplateCard v-for="t in filtered" :key="t.uid" :template="t" />
    </div>
  </div>
</template>

<script>
import TemplateCard from "@/components/templates/TemplateCard.vue";
import { mapState } from "vuex";

export default {
  components: {
    TemplateCard,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      templates: (state) => state.templates.templates,
    }),
    filtered() {
      let list = this.templates;
      if (this.selected.length > 0) {
        list = list.filter((t) => {
          let pass = false;
          for (let x in t.tags) {
            if (this.selected.includes(t.tags[x])) {
              pass = true;
            }
          }
          return pass;
        });
      }
      return list;
    },
  },
  data() {
    return {
      drawer: false,
      items: ["Blog", "Did you know", "General", "Graphs", "Tips", "Quotes"],
      selected: [],
    };
  },
  methods: {
    addTemplate() {
      this.$router.push("/template/new");
    },
    clearAll() {
      this.selected = [];
    },
    selectAll() {
      for (let x in this.items) {
        if (!this.selected.includes(this.items[x])) {
          this.selected.push(this.items[x]);
        }
      }
    },
  },
};
</script>
