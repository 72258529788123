var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup-container",class:{
      mob: _vm.$vuetify.breakpoint.smAndDown,
      desk: !_vm.$vuetify.breakpoint.smAndDown,
    }},[(_vm.overlay)?_c('v-overlay',{staticClass:"animate__animated animate__slideInRight text-center d-flex justify-center align-center flex-column",attrs:{"value":_vm.overlay,"color":"button","opacity":"1"}},[_c('p',{staticClass:"primary--text mb-4"},[_vm._v("Creating your awesome workspace..")]),_c('v-progress-linear',{staticClass:"mx-auto",staticStyle:{"width":"100px"},attrs:{"rounded":"","indeterminate":"","color":"primary"}})],1):_vm._e(),_c('div',{staticClass:"left"},[_c('div',{staticClass:"logo"},[_c('v-img',{attrs:{"width":"40","src":require("@/assets/logos/icon-dark.svg"),"contain":""}}),_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v("stix")])],1),(_vm.mode === 'signin' || _vm.mode === 'signup')?_c('div',{staticClass:"sub-container signin",style:({
          width: _vm.$vuetify.breakpoint.smAndDown ? '80%' : '60%',
          paddingBottom: _vm.$vuetify.breakpoint.smAndDown ? '0' : '130px',
        })},[_c('div',{staticClass:"mb-4"},[_c('v-btn',{staticClass:"primary--text",attrs:{"elevation":"0","color":_vm.mode === 'signin' ? 'accent' : '',"text":_vm.mode === 'signup'},on:{"click":function($event){_vm.mode = 'signin'}}},[_vm._v("Sign in")]),_c('v-btn',{staticClass:"primary--text",attrs:{"elevation":"0","color":_vm.mode === 'signup' ? 'accent' : '',"text":_vm.mode === 'signin'},on:{"click":function($event){_vm.mode = 'signup'}}},[_vm._v("Sign up")])],1),(!_vm.$route.query.workspace)?_c('h1',{staticStyle:{"margin-bottom":"10px"},style:({
            fontSize: _vm.$vuetify.breakpoint.smAndDown ? '35px' : '40px',
          })},[_vm._v(" Turn "),_vm._m(0),_vm._v(" for your social media ")]):_c('h1',{staticStyle:{"margin-bottom":"10px"},style:({
            fontSize: _vm.$vuetify.breakpoint.smAndDown ? '35px' : '40px',
          })},[_vm._v(" Join the "),_c('span',{staticClass:"accent--text"},[_vm._v(_vm._s(_vm.$route.query.name))]),_vm._v(" workspace ")]),_c('p',{staticStyle:{"font-size":"13px","font-weight":"400"}},[_vm._v(" By signing "+_vm._s(_vm.mode === "signup" ? "up" : "in")+", I agree with stix' "),_c('u',{staticClass:"accent--text"},[_vm._v("Terms")]),_vm._v(" and "),_c('u',{staticClass:"accent--text"},[_vm._v("Privacy policy")]),_vm._v(". ")]),_c('div',{staticClass:"login-buttons"},[(!_vm.email)?_c('v-btn',{style:({
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }),attrs:{"elevation":"0","height":"50","outlined":"","block":""},on:{"click":_vm.google}},[_c('v-img',{attrs:{"src":require("@/assets/google-icon.png"),"contain":"","max-width":"40px"}}),_vm._v(" "+_vm._s(_vm.mode === "signup" ? "Create account" : "Sign in")+" with Google ")],1):_vm._e(),(!_vm.email)?_c('div',{staticClass:"or"},[_c('hr'),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("OR SIGN "+_vm._s(_vm.mode === "signup" ? "UP" : "IN")+" THROUGH EMAIL")]),_c('hr')]):_vm._e(),_c('div',{staticClass:"email"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.email),expression:"email"}],staticClass:"mb-0",staticStyle:{"font-size":"12px","cursor":"pointer"},on:{"click":function($event){_vm.email = ''}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-chevron-left")]),_c('u',[_vm._v("Sign "+_vm._s(_vm.mode === "signup" ? "up" : "in")+" with Google")])],1),_c('v-text-field',{staticStyle:{"border-radius":"8px"},style:({
                borderBottom: '3px solid #272727',
                borderLeft: '1px solid #272727',
                borderTop: '1px solid #272727',
                borderRight: '3px solid #272727',
              }),attrs:{"placeholder":"Enter your email to continue..","flat":"","solo":"","hide-details":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.email && _vm.mode === 'signup')?_c('div',{staticClass:"d-flex animate__animated animate__slideInRight",staticStyle:{"gap":"10px"}},[_c('v-text-field',{staticStyle:{"border-radius":"8px"},style:({
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                }),attrs:{"placeholder":"First name","flat":"","solo":"","hide-details":""},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('v-text-field',{staticStyle:{"border-radius":"8px"},style:({
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                }),attrs:{"placeholder":"Last name","flat":"","solo":"","hide-details":""},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}})],1):_vm._e(),(_vm.email)?_c('v-text-field',{staticClass:"animate__animated animate__slideInRight",staticStyle:{"border-radius":"8px"},style:({
                borderBottom: '3px solid #272727',
                borderLeft: '1px solid #272727',
                borderTop: '1px solid #272727',
                borderRight: '3px solid #272727',
              }),attrs:{"placeholder":"Create a password","flat":"","solo":"","hide-details":"","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}):_vm._e(),(_vm.email && _vm.mode === 'signin')?_c('p',{staticClass:"animate__animated animate__slideInRight",staticStyle:{"text-align":"end","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/reset')}}},[_c('u',[_vm._v("Reset password")])]):_vm._e(),_c('v-btn',{attrs:{"elevation":"0","color":"primary","height":"50","block":"","disabled":!_vm.email || !_vm.password},on:{"click":function($event){_vm.mode === 'signup' ? _vm.signUp() : _vm.signIn()}}},[_vm._v("Continue")])],1)],1)]):_vm._e()]),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"button"}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"accent--text"},[_c('u',[_vm._v("autopilot on")])])
}]

export { render, staticRenderFns }