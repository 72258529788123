export const timezones = {
  data() {
    return {
      countries: [
        { name: "Andorra", abbr: "AD", zones: ["Europe/Andorra"] },
        { name: "United Arab Emirates", abbr: "AE", zones: ["Asia/Dubai"] },
        { name: "Afghanistan", abbr: "AF", zones: ["Asia/Kabul"] },
        {
          name: "Antigua & Barbuda",
          abbr: "AG",
          zones: ["America/Puerto_Rico", "America/Antigua"],
        },
        {
          name: "Anguilla",
          abbr: "AI",
          zones: ["America/Puerto_Rico", "America/Anguilla"],
        },
        { name: "Albania", abbr: "AL", zones: ["Europe/Tirane"] },
        { name: "Armenia", abbr: "AM", zones: ["Asia/Yerevan"] },
        {
          name: "Angola",
          abbr: "AO",
          zones: ["Africa/Lagos", "Africa/Luanda"],
        },
        {
          name: "Antarctica",
          abbr: "AQ",
          zones: [
            "Antarctica/Casey",
            "Antarctica/Davis",
            "Antarctica/Mawson",
            "Antarctica/Palmer",
            "Antarctica/Rothera",
            "Antarctica/Troll",
            "Asia/Urumqi",
            "Pacific/Auckland",
            "Pacific/Port_Moresby",
            "Asia/Riyadh",
            "Antarctica/McMurdo",
            "Antarctica/DumontDUrville",
            "Antarctica/Syowa",
            "Antarctica/Vostok",
          ],
        },
        {
          name: "Argentina",
          abbr: "AR",
          zones: [
            "America/Argentina/Buenos_Aires",
            "America/Argentina/Cordoba",
            "America/Argentina/Salta",
            "America/Argentina/Jujuy",
            "America/Argentina/Tucuman",
            "America/Argentina/Catamarca",
            "America/Argentina/La_Rioja",
            "America/Argentina/San_Juan",
            "America/Argentina/Mendoza",
            "America/Argentina/San_Luis",
            "America/Argentina/Rio_Gallegos",
            "America/Argentina/Ushuaia",
          ],
        },
        { name: "Samoa (American)", abbr: "AS", zones: ["Pacific/Pago_Pago"] },
        { name: "Austria", abbr: "AT", zones: ["Europe/Vienna"] },
        {
          name: "Australia",
          abbr: "AU",
          zones: [
            "Australia/Lord_Howe",
            "Antarctica/Macquarie",
            "Australia/Hobart",
            "Australia/Melbourne",
            "Australia/Sydney",
            "Australia/Broken_Hill",
            "Australia/Brisbane",
            "Australia/Lindeman",
            "Australia/Adelaide",
            "Australia/Darwin",
            "Australia/Perth",
            "Australia/Eucla",
          ],
        },
        {
          name: "Aruba",
          abbr: "AW",
          zones: ["America/Puerto_Rico", "America/Aruba"],
        },
        {
          name: "Åland Islands",
          abbr: "AX",
          zones: ["Europe/Helsinki", "Europe/Mariehamn"],
        },
        { name: "Azerbaijan", abbr: "AZ", zones: ["Asia/Baku"] },
        {
          name: "Bosnia & Herzegovina",
          abbr: "BA",
          zones: ["Europe/Belgrade", "Europe/Sarajevo"],
        },
        { name: "Barbados", abbr: "BB", zones: ["America/Barbados"] },
        { name: "Bangladesh", abbr: "BD", zones: ["Asia/Dhaka"] },
        { name: "Belgium", abbr: "BE", zones: ["Europe/Brussels"] },
        {
          name: "Burkina Faso",
          abbr: "BF",
          zones: ["Africa/Abidjan", "Africa/Ouagadougou"],
        },
        { name: "Bulgaria", abbr: "BG", zones: ["Europe/Sofia"] },
        { name: "Bahrain", abbr: "BH", zones: ["Asia/Qatar", "Asia/Bahrain"] },
        {
          name: "Burundi",
          abbr: "BI",
          zones: ["Africa/Maputo", "Africa/Bujumbura"],
        },
        {
          name: "Benin",
          abbr: "BJ",
          zones: ["Africa/Lagos", "Africa/Porto-Novo"],
        },
        {
          name: "St Barthelemy",
          abbr: "BL",
          zones: ["America/Puerto_Rico", "America/St_Barthelemy"],
        },
        { name: "Bermuda", abbr: "BM", zones: ["Atlantic/Bermuda"] },
        { name: "Brunei", abbr: "BN", zones: ["Asia/Kuching", "Asia/Brunei"] },
        { name: "Bolivia", abbr: "BO", zones: ["America/La_Paz"] },
        {
          name: "Caribbean NL",
          abbr: "BQ",
          zones: ["America/Puerto_Rico", "America/Kralendijk"],
        },
        {
          name: "Brazil",
          abbr: "BR",
          zones: [
            "America/Noronha",
            "America/Belem",
            "America/Fortaleza",
            "America/Recife",
            "America/Araguaina",
            "America/Maceio",
            "America/Bahia",
            "America/Sao_Paulo",
            "America/Campo_Grande",
            "America/Cuiaba",
            "America/Santarem",
            "America/Porto_Velho",
            "America/Boa_Vista",
            "America/Manaus",
            "America/Eirunepe",
            "America/Rio_Branco",
          ],
        },
        {
          name: "Bahamas",
          abbr: "BS",
          zones: ["America/Toronto", "America/Nassau"],
        },
        { name: "Bhutan", abbr: "BT", zones: ["Asia/Thimphu"] },
        {
          name: "Botswana",
          abbr: "BW",
          zones: ["Africa/Maputo", "Africa/Gaborone"],
        },
        { name: "Belarus", abbr: "BY", zones: ["Europe/Minsk"] },
        { name: "Belize", abbr: "BZ", zones: ["America/Belize"] },
        {
          name: "Canada",
          abbr: "CA",
          zones: [
            "America/St_Johns",
            "America/Halifax",
            "America/Glace_Bay",
            "America/Moncton",
            "America/Goose_Bay",
            "America/Toronto",
            "America/Iqaluit",
            "America/Winnipeg",
            "America/Resolute",
            "America/Rankin_Inlet",
            "America/Regina",
            "America/Swift_Current",
            "America/Edmonton",
            "America/Cambridge_Bay",
            "America/Yellowknife",
            "America/Inuvik",
            "America/Dawson_Creek",
            "America/Fort_Nelson",
            "America/Whitehorse",
            "America/Dawson",
            "America/Vancouver",
            "America/Panama",
            "America/Puerto_Rico",
            "America/Phoenix",
            "America/Blanc-Sablon",
            "America/Atikokan",
            "America/Creston",
          ],
        },
        {
          name: "Cocos (Keeling) Islands",
          abbr: "CC",
          zones: ["Asia/Yangon", "Indian/Cocos"],
        },
        {
          name: "Congo (Dem. Rep.)",
          abbr: "CD",
          zones: [
            "Africa/Maputo",
            "Africa/Lagos",
            "Africa/Kinshasa",
            "Africa/Lubumbashi",
          ],
        },
        {
          name: "Central African Rep.",
          abbr: "CF",
          zones: ["Africa/Lagos", "Africa/Bangui"],
        },
        {
          name: "Congo (Rep.)",
          abbr: "CG",
          zones: ["Africa/Lagos", "Africa/Brazzaville"],
        },
        { name: "Switzerland", abbr: "CH", zones: ["Europe/Zurich"] },
        { name: "Côte d'Ivoire", abbr: "CI", zones: ["Africa/Abidjan"] },
        { name: "Cook Islands", abbr: "CK", zones: ["Pacific/Rarotonga"] },
        {
          name: "Chile",
          abbr: "CL",
          zones: ["America/Santiago", "America/Punta_Arenas", "Pacific/Easter"],
        },
        {
          name: "Cameroon",
          abbr: "CM",
          zones: ["Africa/Lagos", "Africa/Douala"],
        },
        { name: "China", abbr: "CN", zones: ["Asia/Shanghai", "Asia/Urumqi"] },
        { name: "Colombia", abbr: "CO", zones: ["America/Bogota"] },
        { name: "Costa Rica", abbr: "CR", zones: ["America/Costa_Rica"] },
        { name: "Cuba", abbr: "CU", zones: ["America/Havana"] },
        { name: "Cape Verde", abbr: "CV", zones: ["Atlantic/Cape_Verde"] },
        {
          name: "Curaçao",
          abbr: "CW",
          zones: ["America/Puerto_Rico", "America/Curacao"],
        },
        {
          name: "Christmas Island",
          abbr: "CX",
          zones: ["Asia/Bangkok", "Indian/Christmas"],
        },
        {
          name: "Cyprus",
          abbr: "CY",
          zones: ["Asia/Nicosia", "Asia/Famagusta"],
        },
        { name: "Czech Republic", abbr: "CZ", zones: ["Europe/Prague"] },
        {
          name: "Germany",
          abbr: "DE",
          zones: ["Europe/Zurich", "Europe/Berlin", "Europe/Busingen"],
        },
        {
          name: "Djibouti",
          abbr: "DJ",
          zones: ["Africa/Nairobi", "Africa/Djibouti"],
        },
        {
          name: "Denmark",
          abbr: "DK",
          zones: ["Europe/Berlin", "Europe/Copenhagen"],
        },
        {
          name: "Dominica",
          abbr: "DM",
          zones: ["America/Puerto_Rico", "America/Dominica"],
        },
        {
          name: "Dominican Republic",
          abbr: "DO",
          zones: ["America/Santo_Domingo"],
        },
        { name: "Algeria", abbr: "DZ", zones: ["Africa/Algiers"] },
        {
          name: "Ecuador",
          abbr: "EC",
          zones: ["America/Guayaquil", "Pacific/Galapagos"],
        },
        { name: "Estonia", abbr: "EE", zones: ["Europe/Tallinn"] },
        { name: "Egypt", abbr: "EG", zones: ["Africa/Cairo"] },
        { name: "Western Sahara", abbr: "EH", zones: ["Africa/El_Aaiun"] },
        {
          name: "Eritrea",
          abbr: "ER",
          zones: ["Africa/Nairobi", "Africa/Asmara"],
        },
        {
          name: "Spain",
          abbr: "ES",
          zones: ["Europe/Madrid", "Africa/Ceuta", "Atlantic/Canary"],
        },
        {
          name: "Ethiopia",
          abbr: "ET",
          zones: ["Africa/Nairobi", "Africa/Addis_Ababa"],
        },
        { name: "Finland", abbr: "FI", zones: ["Europe/Helsinki"] },
        { name: "Fiji", abbr: "FJ", zones: ["Pacific/Fiji"] },
        { name: "Falkland Islands", abbr: "FK", zones: ["Atlantic/Stanley"] },
        {
          name: "Micronesia",
          abbr: "FM",
          zones: [
            "Pacific/Kosrae",
            "Pacific/Port_Moresby",
            "Pacific/Guadalcanal",
            "Pacific/Chuuk",
            "Pacific/Pohnpei",
          ],
        },
        { name: "Faroe Islands", abbr: "FO", zones: ["Atlantic/Faroe"] },
        { name: "France", abbr: "FR", zones: ["Europe/Paris"] },
        {
          name: "Gabon",
          abbr: "GA",
          zones: ["Africa/Lagos", "Africa/Libreville"],
        },
        { name: "Britain (UK)", abbr: "GB", zones: ["Europe/London"] },
        {
          name: "Grenada",
          abbr: "GD",
          zones: ["America/Puerto_Rico", "America/Grenada"],
        },
        { name: "Georgia", abbr: "GE", zones: ["Asia/Tbilisi"] },
        { name: "French Guiana", abbr: "GF", zones: ["America/Cayenne"] },
        {
          name: "Guernsey",
          abbr: "GG",
          zones: ["Europe/London", "Europe/Guernsey"],
        },
        {
          name: "Ghana",
          abbr: "GH",
          zones: ["Africa/Abidjan", "Africa/Accra"],
        },
        { name: "Gibraltar", abbr: "GI", zones: ["Europe/Gibraltar"] },
        {
          name: "Greenland",
          abbr: "GL",
          zones: [
            "America/Nuuk",
            "America/Danmarkshavn",
            "America/Scoresbysund",
            "America/Thule",
          ],
        },
        {
          name: "Gambia",
          abbr: "GM",
          zones: ["Africa/Abidjan", "Africa/Banjul"],
        },
        {
          name: "Guinea",
          abbr: "GN",
          zones: ["Africa/Abidjan", "Africa/Conakry"],
        },
        {
          name: "Guadeloupe",
          abbr: "GP",
          zones: ["America/Puerto_Rico", "America/Guadeloupe"],
        },
        {
          name: "Equatorial Guinea",
          abbr: "GQ",
          zones: ["Africa/Lagos", "Africa/Malabo"],
        },
        { name: "Greece", abbr: "GR", zones: ["Europe/Athens"] },
        {
          name: "South Georgia & the South Sandwich Islands",
          abbr: "GS",
          zones: ["Atlantic/South_Georgia"],
        },
        { name: "Guatemala", abbr: "GT", zones: ["America/Guatemala"] },
        { name: "Guam", abbr: "GU", zones: ["Pacific/Guam"] },
        { name: "Guinea-Bissau", abbr: "GW", zones: ["Africa/Bissau"] },
        { name: "Guyana", abbr: "GY", zones: ["America/Guyana"] },
        { name: "Hong Kong", abbr: "HK", zones: ["Asia/Hong_Kong"] },
        { name: "Honduras", abbr: "HN", zones: ["America/Tegucigalpa"] },
        {
          name: "Croatia",
          abbr: "HR",
          zones: ["Europe/Belgrade", "Europe/Zagreb"],
        },
        { name: "Haiti", abbr: "HT", zones: ["America/Port-au-Prince"] },
        { name: "Hungary", abbr: "HU", zones: ["Europe/Budapest"] },
        {
          name: "Indonesia",
          abbr: "ID",
          zones: [
            "Asia/Jakarta",
            "Asia/Pontianak",
            "Asia/Makassar",
            "Asia/Jayapura",
          ],
        },
        { name: "Ireland", abbr: "IE", zones: ["Europe/Dublin"] },
        { name: "Israel", abbr: "IL", zones: ["Asia/Jerusalem"] },
        {
          name: "Isle of Man",
          abbr: "IM",
          zones: ["Europe/London", "Europe/Isle_of_Man"],
        },
        { name: "India", abbr: "IN", zones: ["Asia/Kolkata"] },
        {
          name: "British Indian Ocean Territory",
          abbr: "IO",
          zones: ["Indian/Chagos"],
        },
        { name: "Iraq", abbr: "IQ", zones: ["Asia/Baghdad"] },
        { name: "Iran", abbr: "IR", zones: ["Asia/Tehran"] },
        {
          name: "Iceland",
          abbr: "IS",
          zones: ["Africa/Abidjan", "Atlantic/Reykjavik"],
        },
        { name: "Italy", abbr: "IT", zones: ["Europe/Rome"] },
        {
          name: "Jersey",
          abbr: "JE",
          zones: ["Europe/London", "Europe/Jersey"],
        },
        { name: "Jamaica", abbr: "JM", zones: ["America/Jamaica"] },
        { name: "Jordan", abbr: "JO", zones: ["Asia/Amman"] },
        { name: "Japan", abbr: "JP", zones: ["Asia/Tokyo"] },
        { name: "Kenya", abbr: "KE", zones: ["Africa/Nairobi"] },
        { name: "Kyrgyzstan", abbr: "KG", zones: ["Asia/Bishkek"] },
        {
          name: "Cambodia",
          abbr: "KH",
          zones: ["Asia/Bangkok", "Asia/Phnom_Penh"],
        },
        {
          name: "Kiribati",
          abbr: "KI",
          zones: ["Pacific/Tarawa", "Pacific/Kanton", "Pacific/Kiritimati"],
        },
        {
          name: "Comoros",
          abbr: "KM",
          zones: ["Africa/Nairobi", "Indian/Comoro"],
        },
        {
          name: "St Kitts & Nevis",
          abbr: "KN",
          zones: ["America/Puerto_Rico", "America/St_Kitts"],
        },
        { name: "Korea (North)", abbr: "KP", zones: ["Asia/Pyongyang"] },
        { name: "Korea (South)", abbr: "KR", zones: ["Asia/Seoul"] },
        { name: "Kuwait", abbr: "KW", zones: ["Asia/Riyadh", "Asia/Kuwait"] },
        {
          name: "Cayman Islands",
          abbr: "KY",
          zones: ["America/Panama", "America/Cayman"],
        },
        {
          name: "Kazakhstan",
          abbr: "KZ",
          zones: [
            "Asia/Almaty",
            "Asia/Qyzylorda",
            "Asia/Qostanay",
            "Asia/Aqtobe",
            "Asia/Aqtau",
            "Asia/Atyrau",
            "Asia/Oral",
          ],
        },
        { name: "Laos", abbr: "LA", zones: ["Asia/Bangkok", "Asia/Vientiane"] },
        { name: "Lebanon", abbr: "LB", zones: ["Asia/Beirut"] },
        {
          name: "St Lucia",
          abbr: "LC",
          zones: ["America/Puerto_Rico", "America/St_Lucia"],
        },
        {
          name: "Liechtenstein",
          abbr: "LI",
          zones: ["Europe/Zurich", "Europe/Vaduz"],
        },
        { name: "Sri Lanka", abbr: "LK", zones: ["Asia/Colombo"] },
        { name: "Liberia", abbr: "LR", zones: ["Africa/Monrovia"] },
        {
          name: "Lesotho",
          abbr: "LS",
          zones: ["Africa/Johannesburg", "Africa/Maseru"],
        },
        { name: "Lithuania", abbr: "LT", zones: ["Europe/Vilnius"] },
        {
          name: "Luxembourg",
          abbr: "LU",
          zones: ["Europe/Brussels", "Europe/Luxembourg"],
        },
        { name: "Latvia", abbr: "LV", zones: ["Europe/Riga"] },
        { name: "Libya", abbr: "LY", zones: ["Africa/Tripoli"] },
        { name: "Morocco", abbr: "MA", zones: ["Africa/Casablanca"] },
        {
          name: "Monaco",
          abbr: "MC",
          zones: ["Europe/Paris", "Europe/Monaco"],
        },
        { name: "Moldova", abbr: "MD", zones: ["Europe/Chisinau"] },
        {
          name: "Montenegro",
          abbr: "ME",
          zones: ["Europe/Belgrade", "Europe/Podgorica"],
        },
        {
          name: "St Martin (French)",
          abbr: "MF",
          zones: ["America/Puerto_Rico", "America/Marigot"],
        },
        {
          name: "Madagascar",
          abbr: "MG",
          zones: ["Africa/Nairobi", "Indian/Antananarivo"],
        },
        {
          name: "Marshall Islands",
          abbr: "MH",
          zones: ["Pacific/Tarawa", "Pacific/Kwajalein", "Pacific/Majuro"],
        },
        {
          name: "North Macedonia",
          abbr: "MK",
          zones: ["Europe/Belgrade", "Europe/Skopje"],
        },
        {
          name: "Mali",
          abbr: "ML",
          zones: ["Africa/Abidjan", "Africa/Bamako"],
        },
        { name: "Myanmar (Burma)", abbr: "MM", zones: ["Asia/Yangon"] },
        {
          name: "Mongolia",
          abbr: "MN",
          zones: ["Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan"],
        },
        { name: "Macau", abbr: "MO", zones: ["Asia/Macau"] },
        {
          name: "Northern Mariana Islands",
          abbr: "MP",
          zones: ["Pacific/Guam", "Pacific/Saipan"],
        },
        { name: "Martinique", abbr: "MQ", zones: ["America/Martinique"] },
        {
          name: "Mauritania",
          abbr: "MR",
          zones: ["Africa/Abidjan", "Africa/Nouakchott"],
        },
        {
          name: "Montserrat",
          abbr: "MS",
          zones: ["America/Puerto_Rico", "America/Montserrat"],
        },
        { name: "Malta", abbr: "MT", zones: ["Europe/Malta"] },
        { name: "Mauritius", abbr: "MU", zones: ["Indian/Mauritius"] },
        { name: "Maldives", abbr: "MV", zones: ["Indian/Maldives"] },
        {
          name: "Malawi",
          abbr: "MW",
          zones: ["Africa/Maputo", "Africa/Blantyre"],
        },
        {
          name: "Mexico",
          abbr: "MX",
          zones: [
            "America/Mexico_City",
            "America/Cancun",
            "America/Merida",
            "America/Monterrey",
            "America/Matamoros",
            "America/Chihuahua",
            "America/Ciudad_Juarez",
            "America/Ojinaga",
            "America/Mazatlan",
            "America/Bahia_Banderas",
            "America/Hermosillo",
            "America/Tijuana",
          ],
        },
        {
          name: "Malaysia",
          abbr: "MY",
          zones: ["Asia/Kuching", "Asia/Singapore", "Asia/Kuala_Lumpur"],
        },
        { name: "Mozambique", abbr: "MZ", zones: ["Africa/Maputo"] },
        { name: "Namibia", abbr: "NA", zones: ["Africa/Windhoek"] },
        { name: "New Caledonia", abbr: "NC", zones: ["Pacific/Noumea"] },
        { name: "Niger", abbr: "NE", zones: ["Africa/Lagos", "Africa/Niamey"] },
        { name: "Norfolk Island", abbr: "NF", zones: ["Pacific/Norfolk"] },
        { name: "Nigeria", abbr: "NG", zones: ["Africa/Lagos"] },
        { name: "Nicaragua", abbr: "NI", zones: ["America/Managua"] },
        {
          name: "Netherlands",
          abbr: "NL",
          zones: ["Europe/Brussels", "Europe/Amsterdam"],
        },
        { name: "Norway", abbr: "NO", zones: ["Europe/Berlin", "Europe/Oslo"] },
        { name: "Nepal", abbr: "NP", zones: ["Asia/Kathmandu"] },
        { name: "Nauru", abbr: "NR", zones: ["Pacific/Nauru"] },
        { name: "Niue", abbr: "NU", zones: ["Pacific/Niue"] },
        {
          name: "New Zealand",
          abbr: "NZ",
          zones: ["Pacific/Auckland", "Pacific/Chatham"],
        },
        { name: "Oman", abbr: "OM", zones: ["Asia/Dubai", "Asia/Muscat"] },
        { name: "Panama", abbr: "PA", zones: ["America/Panama"] },
        { name: "Peru", abbr: "PE", zones: ["America/Lima"] },
        {
          name: "French Polynesia",
          abbr: "PF",
          zones: ["Pacific/Tahiti", "Pacific/Marquesas", "Pacific/Gambier"],
        },
        {
          name: "Papua New Guinea",
          abbr: "PG",
          zones: ["Pacific/Port_Moresby", "Pacific/Bougainville"],
        },
        { name: "Philippines", abbr: "PH", zones: ["Asia/Manila"] },
        { name: "Pakistan", abbr: "PK", zones: ["Asia/Karachi"] },
        { name: "Poland", abbr: "PL", zones: ["Europe/Warsaw"] },
        {
          name: "St Pierre & Miquelon",
          abbr: "PM",
          zones: ["America/Miquelon"],
        },
        { name: "Pitcairn", abbr: "PN", zones: ["Pacific/Pitcairn"] },
        { name: "Puerto Rico", abbr: "PR", zones: ["America/Puerto_Rico"] },
        { name: "Palestine", abbr: "PS", zones: ["Asia/Gaza", "Asia/Hebron"] },
        {
          name: "Portugal",
          abbr: "PT",
          zones: ["Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores"],
        },
        { name: "Palau", abbr: "PW", zones: ["Pacific/Palau"] },
        { name: "Paraguay", abbr: "PY", zones: ["America/Asuncion"] },
        { name: "Qatar", abbr: "QA", zones: ["Asia/Qatar"] },
        {
          name: "Réunion",
          abbr: "RE",
          zones: ["Asia/Dubai", "Indian/Reunion"],
        },
        { name: "Romania", abbr: "RO", zones: ["Europe/Bucharest"] },
        { name: "Serbia", abbr: "RS", zones: ["Europe/Belgrade"] },
        {
          name: "Russia",
          abbr: "RU",
          zones: [
            "Europe/Kaliningrad",
            "Europe/Moscow",
            "Europe/Simferopol",
            "Europe/Kirov",
            "Europe/Volgograd",
            "Europe/Astrakhan",
            "Europe/Saratov",
            "Europe/Ulyanovsk",
            "Europe/Samara",
            "Asia/Yekaterinburg",
            "Asia/Omsk",
            "Asia/Novosibirsk",
            "Asia/Barnaul",
            "Asia/Tomsk",
            "Asia/Novokuznetsk",
            "Asia/Krasnoyarsk",
            "Asia/Irkutsk",
            "Asia/Chita",
            "Asia/Yakutsk",
            "Asia/Khandyga",
            "Asia/Vladivostok",
            "Asia/Ust-Nera",
            "Asia/Magadan",
            "Asia/Sakhalin",
            "Asia/Srednekolymsk",
            "Asia/Kamchatka",
            "Asia/Anadyr",
          ],
        },
        {
          name: "Rwanda",
          abbr: "RW",
          zones: ["Africa/Maputo", "Africa/Kigali"],
        },
        { name: "Saudi Arabia", abbr: "SA", zones: ["Asia/Riyadh"] },
        { name: "Solomon Islands", abbr: "SB", zones: ["Pacific/Guadalcanal"] },
        {
          name: "Seychelles",
          abbr: "SC",
          zones: ["Asia/Dubai", "Indian/Mahe"],
        },
        { name: "Sudan", abbr: "SD", zones: ["Africa/Khartoum"] },
        {
          name: "Sweden",
          abbr: "SE",
          zones: ["Europe/Berlin", "Europe/Stockholm"],
        },
        { name: "Singapore", abbr: "SG", zones: ["Asia/Singapore"] },
        {
          name: "St Helena",
          abbr: "SH",
          zones: ["Africa/Abidjan", "Atlantic/St_Helena"],
        },
        {
          name: "Slovenia",
          abbr: "SI",
          zones: ["Europe/Belgrade", "Europe/Ljubljana"],
        },
        {
          name: "Svalbard & Jan Mayen",
          abbr: "SJ",
          zones: ["Europe/Berlin", "Arctic/Longyearbyen"],
        },
        {
          name: "Slovakia",
          abbr: "SK",
          zones: ["Europe/Prague", "Europe/Bratislava"],
        },
        {
          name: "Sierra Leone",
          abbr: "SL",
          zones: ["Africa/Abidjan", "Africa/Freetown"],
        },
        {
          name: "San Marino",
          abbr: "SM",
          zones: ["Europe/Rome", "Europe/San_Marino"],
        },
        {
          name: "Senegal",
          abbr: "SN",
          zones: ["Africa/Abidjan", "Africa/Dakar"],
        },
        {
          name: "Somalia",
          abbr: "SO",
          zones: ["Africa/Nairobi", "Africa/Mogadishu"],
        },
        { name: "Suriname", abbr: "SR", zones: ["America/Paramaribo"] },
        { name: "South Sudan", abbr: "SS", zones: ["Africa/Juba"] },
        { name: "Sao Tome & Principe", abbr: "ST", zones: ["Africa/Sao_Tome"] },
        { name: "El Salvador", abbr: "SV", zones: ["America/El_Salvador"] },
        {
          name: "St Maarten (Dutch)",
          abbr: "SX",
          zones: ["America/Puerto_Rico", "America/Lower_Princes"],
        },
        { name: "Syria", abbr: "SY", zones: ["Asia/Damascus"] },
        {
          name: "Eswatini (Swaziland)",
          abbr: "SZ",
          zones: ["Africa/Johannesburg", "Africa/Mbabane"],
        },
        {
          name: "Turks & Caicos Is",
          abbr: "TC",
          zones: ["America/Grand_Turk"],
        },
        { name: "Chad", abbr: "TD", zones: ["Africa/Ndjamena"] },
        {
          name: "French Southern Territories",
          abbr: "TF",
          zones: ["Asia/Dubai", "Indian/Maldives", "Indian/Kerguelen"],
        },
        { name: "Togo", abbr: "TG", zones: ["Africa/Abidjan", "Africa/Lome"] },
        { name: "Thailand", abbr: "TH", zones: ["Asia/Bangkok"] },
        { name: "Tajikistan", abbr: "TJ", zones: ["Asia/Dushanbe"] },
        { name: "Tokelau", abbr: "TK", zones: ["Pacific/Fakaofo"] },
        { name: "East Timor", abbr: "TL", zones: ["Asia/Dili"] },
        { name: "Turkmenistan", abbr: "TM", zones: ["Asia/Ashgabat"] },
        { name: "Tunisia", abbr: "TN", zones: ["Africa/Tunis"] },
        { name: "Tonga", abbr: "TO", zones: ["Pacific/Tongatapu"] },
        { name: "Turkey", abbr: "TR", zones: ["Europe/Istanbul"] },
        {
          name: "Trinidad & Tobago",
          abbr: "TT",
          zones: ["America/Puerto_Rico", "America/Port_of_Spain"],
        },
        {
          name: "Tuvalu",
          abbr: "TV",
          zones: ["Pacific/Tarawa", "Pacific/Funafuti"],
        },
        { name: "Taiwan", abbr: "TW", zones: ["Asia/Taipei"] },
        {
          name: "Tanzania",
          abbr: "TZ",
          zones: ["Africa/Nairobi", "Africa/Dar_es_Salaam"],
        },
        {
          name: "Ukraine",
          abbr: "UA",
          zones: ["Europe/Simferopol", "Europe/Kyiv"],
        },
        {
          name: "Uganda",
          abbr: "UG",
          zones: ["Africa/Nairobi", "Africa/Kampala"],
        },
        {
          name: "US minor outlying islands",
          abbr: "UM",
          zones: [
            "Pacific/Pago_Pago",
            "Pacific/Tarawa",
            "Pacific/Honolulu",
            "Pacific/Midway",
            "Pacific/Wake",
          ],
        },
        {
          name: "United States",
          abbr: "US",
          zones: [
            "America/New_York",
            "America/Detroit",
            "America/Kentucky/Louisville",
            "America/Kentucky/Monticello",
            "America/Indiana/Indianapolis",
            "America/Indiana/Vincennes",
            "America/Indiana/Winamac",
            "America/Indiana/Marengo",
            "America/Indiana/Petersburg",
            "America/Indiana/Vevay",
            "America/Chicago",
            "America/Indiana/Tell_City",
            "America/Indiana/Knox",
            "America/Menominee",
            "America/North_Dakota/Center",
            "America/North_Dakota/New_Salem",
            "America/North_Dakota/Beulah",
            "America/Denver",
            "America/Boise",
            "America/Phoenix",
            "America/Los_Angeles",
            "America/Anchorage",
            "America/Juneau",
            "America/Sitka",
            "America/Metlakatla",
            "America/Yakutat",
            "America/Nome",
            "America/Adak",
            "Pacific/Honolulu",
          ],
        },
        { name: "Uruguay", abbr: "UY", zones: ["America/Montevideo"] },
        {
          name: "Uzbekistan",
          abbr: "UZ",
          zones: ["Asia/Samarkand", "Asia/Tashkent"],
        },
        {
          name: "Vatican City",
          abbr: "VA",
          zones: ["Europe/Rome", "Europe/Vatican"],
        },
        {
          name: "St Vincent",
          abbr: "VC",
          zones: ["America/Puerto_Rico", "America/St_Vincent"],
        },
        { name: "Venezuela", abbr: "VE", zones: ["America/Caracas"] },
        {
          name: "Virgin Islands (UK)",
          abbr: "VG",
          zones: ["America/Puerto_Rico", "America/Tortola"],
        },
        {
          name: "Virgin Islands (US)",
          abbr: "VI",
          zones: ["America/Puerto_Rico", "America/St_Thomas"],
        },
        {
          name: "Vietnam",
          abbr: "VN",
          zones: ["Asia/Bangkok", "Asia/Ho_Chi_Minh"],
        },
        { name: "Vanuatu", abbr: "VU", zones: ["Pacific/Efate"] },
        {
          name: "Wallis & Futuna",
          abbr: "WF",
          zones: ["Pacific/Tarawa", "Pacific/Wallis"],
        },
        { name: "Samoa (western)", abbr: "WS", zones: ["Pacific/Apia"] },
        { name: "Yemen", abbr: "YE", zones: ["Asia/Riyadh", "Asia/Aden"] },
        {
          name: "Mayotte",
          abbr: "YT",
          zones: ["Africa/Nairobi", "Indian/Mayotte"],
        },
        { name: "South Africa", abbr: "ZA", zones: ["Africa/Johannesburg"] },
        {
          name: "Zambia",
          abbr: "ZM",
          zones: ["Africa/Maputo", "Africa/Lusaka"],
        },
        {
          name: "Zimbabwe",
          abbr: "ZW",
          zones: ["Africa/Maputo", "Africa/Harare"],
        },
      ],
    };
  },
};
