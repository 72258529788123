<template>
  <div>
    <div class="top">
      <v-btn
        height="40"
        elevation="0"
        dark
        class="px-8"
        @click="trigger"
        :loading="$store.state.loading.media"
        >Add media</v-btn
      >
      <input
        ref="headerRef"
        type="file"
        multiple
        @change="onFileChange"
        @click="resetValue"
        accept="image/jpeg, image/png, image/jpg, video/mp4"
      />
      <v-text-field
        label="Search media ..."
        :style="{
          borderBottom: '2px solid #272727',
          borderLeft: '0.75px solid #272727',
          borderTop: '0.75px solid #272727',
          borderRight: '2px solid #272727',
        }"
        flat
        solo
        dense
        hide-details=""
        style="border-radius: 8px; max-width: 200px"
        v-model="search"
        clearable
      >
      </v-text-field>
      <v-select
        label="Tag"
        solo
        style="border-radius: 8px; max-width: 200px"
        :style="{
          borderBottom: '2px solid #272727',
          borderLeft: '0.75px solid #272727',
          borderTop: '0.75px solid #272727',
          borderRight: '2px solid #272727',
        }"
        hide-details=""
        dense
        item-text="name"
        return-object
        flat
        v-model="tag"
        :items="tags"
        clearable
      ></v-select>
      <v-btn-toggle
        v-model="mode"
        dense
        style="border-radius: 6px"
        :style="{
          borderBottom: '2px solid #272727',
          borderLeft: '0.75px solid #272727',
          borderTop: '0.75px solid #272727',
          borderRight: '2px solid #272727',
        }"
      >
        <v-btn value="list" height="38">
          <v-icon>mdi-list-box</v-icon>
        </v-btn>

        <v-btn height="38" value="cards">
          <v-icon>mdi-card-multiple</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
    <div class="mb-10" v-if="mode === 'list'"><MediaList :tag="tag" /></div>
    <div class="media" v-if="mode === 'cards'">
      <MediaCard v-for="m in filtered" :key="m.uid" :m="m" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  //   deleteObject,
} from "firebase/storage";
// import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "@/firebase";
import MediaCard from "@/components/media/MediaCard.vue";
import MediaList from "@/components/media/MediaList.vue";

export default {
  components: {
    MediaCard,
    MediaList,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      media: (state) => state.media.media,
      tags: (state) => state.media.tags,
    }),
    filtered() {
      let list = this.media;

      if (this.tag) {
        list = list.filter((i) => i.tags.includes(this.tag));
      }

      return list;
    },
  },
  data() {
    return {
      mode: "list",
      search: "",
      tag: "",
    };
  },
  methods: {
    async onFileChange() {
      this.$store.commit("setLoading", { type: "media", val: true });
      var files = this.$refs.headerRef.files;
      const uploads = [...files];

      for (let x in uploads) {
        this.uploadFiles(uploads[x]);
      }
    },
    resetValue(event) {
      event.target.value = "";
    },
    trigger() {
      this.$refs.headerRef.click();
    },
    async uploadFiles(upload) {
      console.log(upload);
      var fileName = upload.name;
      var fileExtension = fileName.split(".").pop();
      const filesize = Math.floor(upload.size / 1024);

      function getType(ext) {
        switch (ext.toLowerCase()) {
          case "jpg":
          case "gif":
          case "bmp":
          case "png":
          case "jpeg":
            return "image";
        }

        switch (ext.toLowerCase()) {
          case "m4v":
          case "avi":
          case "mpg":
          case "mp4":
            // etc
            return "video";
        }
        return false;
      }

      const storage = getStorage();

      const metadata = {
        contentType: upload.type,
      };
      const docRef = await addDoc(
        collection(db, "workspaces", this.userProfile.workspace, "media"),
        {
          title: fileName,
          extension: fileExtension,
          created_at: new Date(),
          created_by: this.userProfile.uid,
          created_by_email: this.userProfile.email,
          workspace: this.userProfile.workspace,
          size: filesize,
          posts: [],
          templates: [],
          tags: [],
          class: "media",
          type: getType(fileExtension),
        }
      );
      const storageRef = ref(
        storage,
        `${this.userProfile.workspace}/media/${docRef.id}.${fileExtension}`
      );

      await uploadBytesResumable(storageRef, upload, metadata);

      const downloadURL = await getDownloadURL(storageRef);
      const updateRef = doc(
        db,
        "workspaces",
        this.userProfile.workspace,
        "media",
        docRef.id
      );

      await updateDoc(updateRef, {
        uid: docRef.id,
        url: downloadURL,
      });

      this.$store.commit("setNotification", {
        show: true,
        text: "Media added!",
        type: "primary",
        icon: "",
      });

      this.$store.dispatch("fetchMedia");
      this.$store.commit("setLoading", { type: "media", val: false });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
