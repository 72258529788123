var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup-container",class:{
      mob: _vm.$vuetify.breakpoint.smAndDown,
      desk: !_vm.$vuetify.breakpoint.smAndDown,
    }},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"sub-container form",style:({
          width: _vm.$vuetify.breakpoint.smAndDown ? '80%' : '60%',
          paddingBottom: _vm.$vuetify.breakpoint.smAndDown ? '0' : '130px',
          marginTop: _vm.$vuetify.breakpoint.smAndDown ? '30px' : '130px',
        })},[_c('v-img',{staticClass:"mb-8",attrs:{"width":"50","src":require("@/assets/logos/icon-dark.svg"),"contain":""}}),_c('div',[_c('h1',{style:({
              fontSize: _vm.$vuetify.breakpoint.smAndDown ? '35px' : '40px',
            })},[_vm._v(" Reset your password ")]),(!_vm.sent)?_c('v-text-field',{staticClass:"my-4",staticStyle:{"border-radius":"8px"},style:({
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }),attrs:{"placeholder":"Email","flat":"","solo":"","hide-details":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}):_vm._e(),(_vm.sent)?_c('p',[_vm._v(" An email has been sent to "+_vm._s(_vm.email)+". Click the button in the e-mail to continue. ")]):_vm._e(),_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","elevation":"0","height":"50","color":"primary"},on:{"click":_vm.resetPassword}},[_vm._v("Reset password")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }