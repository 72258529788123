var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-4",staticStyle:{"height":"100vh","overflow":"auto"}},[(!_vm.loading.admin.backlog && _vm.item)?_c('v-dialog',{attrs:{"width":"500","transition":"dialog-bottom-transition","persistent":""},on:{"input":_vm.changeDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }),attrs:{"flat":"","width":"500"}},[_c('div',{staticClass:"pa-6"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Ticket")]),_c('v-text-field',{staticClass:"mb-2",staticStyle:{"border-radius":"6px"},attrs:{"placeholder":"Title","hide-details":"","solo":"","flat":"","background-color":"smoke"},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}}),_c('v-textarea',{staticStyle:{"border-radius":"6px"},attrs:{"placeholder":"Description","hide-details":"","solo":"","flat":"","background-color":"smoke"},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}}),_c('div',{staticClass:"d-flex justify-space-between mt-6",staticStyle:{"gap":"10px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(_vm.item.uid)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1),_c('div',[_c('v-btn',{staticClass:"px-6 mr-2",attrs:{"elevation":"0","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")]),_c('v-btn',{staticClass:"px-6",attrs:{"elevation":"0","color":"button"},on:{"click":function($event){_vm.update(_vm.item);
                  _vm.dialog = false;}}},[_vm._v("Save")])],1)],1)],1)])],1):_vm._e(),_c('Backlog',{attrs:{"publicView":false},on:{"open":_vm.open}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }