var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"mb-4"},[_vm._v("Workspace")]),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"20px","flex-wrap":"wrap"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
            backgroundColor: '#D3F987',
          }),attrs:{"flat":"","max-width":"500px"}},[_c('div',{staticClass:"px-6 py-6",staticStyle:{"display":"grid","grid-template-columns":"auto 60px"}},[_c('div',[_c('h2',[_vm._v("Subscription")]),_c('p',{staticClass:"ma-0",staticStyle:{"font-size":"14px"}},[_vm._v(" You're currently on the "),_c('u',[_vm._v("free Beta plan.")]),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information")])],1)],1)]),_c('div',{staticClass:"pl-4 d-flex align-center"},[_c('v-img',{attrs:{"width":"50","src":require("@/assets/icons/payment.svg")}})],1)])])],1),_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{staticClass:"pa-6",style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }),attrs:{"flat":"","max-width":"500px"}},[_c('div',{staticClass:"d-flex justify-space-between mb-2"},[_c('h2',[_vm._v("Details")]),(_vm.edit)?_c('v-btn',{attrs:{"elevation":"0"}},[_vm._v("Save")]):_vm._e()],1),_c('div',{class:{
              'd-flex justify-space-between': !_vm.$vuetify.breakpoint.smAndDown,
              'd-flex flex-column': _vm.$vuetify.breakpoint.smAndDown,
            }},[_c('div',{style:({ width: _vm.$vuetify.breakpoint.smAndDown ? '100%' : '49%' })},[_c('p',{staticClass:"mb-2 mt-4",staticStyle:{"font-size":"10px"}},[_vm._v("Workspace name")]),_c('v-text-field',{staticClass:"mb-2",staticStyle:{"border-radius":"8px"},attrs:{"label":"Workspace name","hide-details":"","solo":"","flat":"","background-color":"smoke"},on:{"change":function($event){_vm.edit = true}},model:{value:(_vm.workspace.name),callback:function ($$v) {_vm.$set(_vm.workspace, "name", $$v)},expression:"workspace.name"}})],1),_c('div',{style:({ width: _vm.$vuetify.breakpoint.smAndDown ? '100%' : '49%' })},[_c('p',{staticClass:"mb-2 mt-4",staticStyle:{"font-size":"10px"}},[_vm._v("Timezone")]),_c('v-select',{staticStyle:{"border-radius":"8px"},attrs:{"label":"Timezone","items":_vm.timezones,"solo":"","hide-details":"","flat":"","background-color":"smoke"},model:{value:(_vm.workspace.timezone),callback:function ($$v) {_vm.$set(_vm.workspace, "timezone", $$v)},expression:"workspace.timezone"}})],1)])])],1),_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }),attrs:{"flat":"","max-width":"500px"}},[_c('div',{staticClass:"d-flex justify-space-between mb-2 px-6 pt-6"},[_c('h2',[_vm._v("Team")]),_c('InviteDialog')],1),_c('v-data-table',{staticClass:"px-2 mb-6",attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":"100","sort-by":"email","hide-default-header":"","hide-default-footer":_vm.items.length > 0},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""}},[(item.role != 'admin')?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}},{key:`item.permissions`,fn:function({ item }){return [_c('v-chip-group',[(item.permissions.social.create)?_c('v-chip',{attrs:{"small":""}},[_vm._v("Create")]):_vm._e(),(item.permissions.social.publish)?_c('v-chip',{attrs:{"small":""}},[_vm._v("Publish")]):_vm._e()],1)]}}],null,true)})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }