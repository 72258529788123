var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup-container",class:{
      mob: _vm.$vuetify.breakpoint.smAndDown,
      desk: !_vm.$vuetify.breakpoint.smAndDown,
    }},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"logo"},[_c('v-img',{attrs:{"width":"40","src":require("@/assets/logos/icon-dark.svg"),"contain":""}}),_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v("Stix")])],1),_c('div',{staticClass:"sub-container form",style:({
          width: _vm.$vuetify.breakpoint.smAndDown ? '80%' : '60%',
          paddingBottom: _vm.$vuetify.breakpoint.smAndDown ? '0' : '130px',
          marginTop: _vm.$vuetify.breakpoint.smAndDown ? '30px' : '130px',
        })},[(_vm.userProfile.workspace)?_c('div',[_c('v-btn',{staticClass:"mb-4",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-arrow-left")]),_vm._v("Back to dashboard")],1)],1):_vm._e(),_c('div',{staticClass:"steps"},[_c('v-btn',{style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
              backgroundColor: '#D3F987',
            }),attrs:{"outlined":"","rounded":"","elevation":"0","small":""}}),_c('v-btn',{style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
              backgroundColor: _vm.step > 1 ? '#D3F987' : '',
            }),attrs:{"outlined":"","small":"","rounded":"","elevation":"0"}}),_c('v-btn',{style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
              backgroundColor: _vm.step > 2 ? '#D3F987' : '',
            }),attrs:{"small":"","outlined":"","rounded":"","elevation":"0"}}),_c('v-btn',{style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
              backgroundColor: _vm.step > 3 ? '#D3F987' : '',
            }),attrs:{"outlined":"","small":"","rounded":"","elevation":"0"}}),_c('v-btn',{style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
              backgroundColor: _vm.step > 4 ? '#D3F987' : '',
            }),attrs:{"outlined":"","small":"","rounded":"","elevation":"0"}})],1),_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('div',[_c('h1',{style:({
                  fontSize: _vm.$vuetify.breakpoint.smAndDown ? '35px' : '40px',
                })},[_vm._v(" Let's create your workspace ")]),_c('p',{staticClass:"my-2"},[_vm._v(" Your workspace is where you will create, review, publish and edit all your social media activities, together with your colleagues. ")]),_c('v-text-field',{staticClass:"mb-2 mt-4",style:({
                  borderBottom: '2px solid #272727',
                  borderLeft: '0.75px solid #272727',
                  borderTop: '0.75px solid #272727',
                  borderRight: '2px solid #272727',
                  borderRadius: '8px',
                }),attrs:{"solo":"","placeholder":"Workspace name..","flat":"","hide-details":""},model:{value:(_vm.workspace),callback:function ($$v) {_vm.workspace=$$v},expression:"workspace"}}),_c('v-btn',{staticClass:"mt-4 px-6",attrs:{"height":"50","elevation":"0","color":"button","disabled":!_vm.workspace},on:{"click":function($event){_vm.step = 2}}},[_vm._v(" Continue "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1)]),_c('v-window-item',{attrs:{"value":2}},[_c('div',[_c('h1',{style:({
                  fontSize: _vm.$vuetify.breakpoint.smAndDown ? '35px' : '40px',
                })},[_vm._v(" How are you planning to use Stix? ")]),_c('div',{staticClass:"options mt-4"},[_c('v-card',{staticClass:"px-6 pt-2 pb-5 animate__animated animate__slideInRight",attrs:{"color":"smoke","flat":"","outlined":""}},[_c('v-select',{staticClass:"mb-4",attrs:{"label":"How big is your company?","items":_vm.sizes,"hide-details":""},model:{value:(_vm.company_size),callback:function ($$v) {_vm.company_size=$$v},expression:"company_size"}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"placeholder":"e.g. Marketing Specialist","label":"What is your job title?","hide-details":""},model:{value:(_vm.job_title),callback:function ($$v) {_vm.job_title=$$v},expression:"job_title"}}),_c('v-text-field',{attrs:{"label":"What is your company's website?","hide-details":""},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}})],1)],1),_c('v-btn',{staticClass:"mt-4 px-6",attrs:{"height":"50","elevation":"0","color":"button","disabled":!_vm.job_title || !_vm.website || !_vm.company_size},on:{"click":function($event){_vm.step = 3}}},[_vm._v(" Continue "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1)]),_c('v-window-item',{attrs:{"value":3}},[_c('div',[_c('h1',{style:({
                  fontSize: _vm.$vuetify.breakpoint.smAndDown ? '35px' : '40px',
                })},[_vm._v(" About "+_vm._s(_vm.workspace)+" ")]),_c('p',{staticClass:"mt-4 mb-2",staticStyle:{"font-size":"10px"}},[_vm._v(" Which country & timezone is your business located? ")]),_c('div',[_c('v-autocomplete',{staticStyle:{"border-radius":"8px"},attrs:{"menu-props":{ offsetY: true },"label":"Country","items":_vm.sorted_countries,"item-text":"name","return-object":"","solo":"","hide-details":"","flat":"","background-color":"smoke"},on:{"change":_vm.checkTZ},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),(_vm.country && _vm.country.zones.length > 1)?_c('v-autocomplete',{staticClass:"mt-2",staticStyle:{"border-radius":"8px"},attrs:{"menu-props":{ offsetY: true },"label":"Timezone","items":_vm.country.zones,"item-text":"name","solo":"","hide-details":"","flat":"","background-color":"smoke"},model:{value:(_vm.timezone),callback:function ($$v) {_vm.timezone=$$v},expression:"timezone"}}):_vm._e(),_c('div',[_c('p',{staticClass:"mt-4 mb-2",staticStyle:{"font-size":"10px"}},[_vm._v(" A description of your company: ")]),_c('v-textarea',{staticClass:"mb-2",style:({
                      borderBottom: '2px solid #272727',
                      borderLeft: '0.75px solid #272727',
                      borderTop: '0.75px solid #272727',
                      borderRight: '2px solid #272727',
                      borderRadius: '8px',
                    }),attrs:{"solo":"","placeholder":"A short description..","flat":"","hide-details":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('div',[_c('v-btn',{staticClass:"mt-4 px-6",attrs:{"height":"50","elevation":"0","color":"button"},on:{"click":function($event){_vm.step = 4}}},[_vm._v(" Continue "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1)])]),_c('v-window-item',{attrs:{"value":4}},[_c('div',[_c('h1',{style:({
                  fontSize: _vm.$vuetify.breakpoint.smAndDown ? '35px' : '40px',
                })},[_vm._v(" Help Stix AI help you ")]),_c('div',[_c('p',{staticClass:"mt-4 mb-2",staticStyle:{"font-size":"10px"}},[_vm._v(" A description of your services: ")]),_c('v-textarea',{staticClass:"mb-2",style:({
                    borderBottom: '2px solid #272727',
                    borderLeft: '0.75px solid #272727',
                    borderTop: '0.75px solid #272727',
                    borderRight: '2px solid #272727',
                    borderRadius: '8px',
                  }),attrs:{"solo":"","placeholder":"Describe your products or service","flat":"","hide-details":""},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}}),_c('p',{staticClass:"mt-4 mb-2",staticStyle:{"font-size":"10px"}},[_vm._v(" A description of your target audience: ")]),_c('v-textarea',{staticClass:"mb-2",style:({
                    borderBottom: '2px solid #272727',
                    borderLeft: '0.75px solid #272727',
                    borderTop: '0.75px solid #272727',
                    borderRight: '2px solid #272727',
                    borderRadius: '8px',
                  }),attrs:{"solo":"","placeholder":"Describe your target audience","flat":"","hide-details":""},model:{value:(_vm.audience),callback:function ($$v) {_vm.audience=$$v},expression:"audience"}})],1),_c('div',[_c('v-btn',{staticClass:"mt-4 px-6",attrs:{"height":"50","elevation":"0","color":"button"},on:{"click":function($event){_vm.step = 5}}},[_vm._v(" Continue "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1)])]),_c('v-window-item',{attrs:{"value":5}},[_c('div',[_c('h1',{style:({
                  fontSize: _vm.$vuetify.breakpoint.smAndDown ? '35px' : '40px',
                })},[_vm._v(" Let's invite your colleagues ")]),_vm._l((_vm.invites),function(i,index){return _c('div',{key:index,staticClass:"mt-2"},[_c('v-text-field',{staticClass:"mb-2 mt-2",style:({
                    borderBottom: '2px solid #272727',
                    borderLeft: '0.75px solid #272727',
                    borderTop: '0.75px solid #272727',
                    borderRight: '2px solid #272727',
                    borderRadius: '8px',
                  }),attrs:{"solo":"","placeholder":"peter@yourcompany.com","flat":"","hide-details":""},model:{value:(i.email),callback:function ($$v) {_vm.$set(i, "email", $$v)},expression:"i.email"}})],1)}),_c('v-btn',{staticClass:"mb-4 mt-2",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.invites.push({ email: '' })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('v-btn',{staticClass:"mt-4 px-6",attrs:{"height":"50","elevation":"0","color":"button","disabled":!_vm.invites[0].email},on:{"click":function($event){return _vm.createWorkspace(false)}}},[_vm._v(" Invite & Continue "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","height":"50"},on:{"click":function($event){return _vm.createWorkspace(true)}}},[_vm._v("Skip & continue")])],1)],2)])],1)],1)]),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"button"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }