<template>
  <div class="d-flex flex-column flex-wrap" style="gap: 15px">
    <v-card
      v-if="workspace"
      flat
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
      max-width="500"
    >
      <div>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="
            font-size: 10pt;
            font-weight: 600;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
          "
        >
          Workspace
        </v-sheet>
        <v-divider class="primary"></v-divider>
      </div>

      <div class="px-6 pb-6 pt-2">
        <div
          :class="{
            'd-flex justify-space-between': !$vuetify.breakpoint.smAndDown,
            'd-flex flex-column': $vuetify.breakpoint.smAndDown,
          }"
        >
          <div
            :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : '49%' }"
          >
            <p style="font-size: 10px" class="mb-2 mt-4">Workspace name</p>
            <v-text-field
              class="mb-2"
              label="Workspace name"
              hide-details=""
              v-model="workspace_name"
              solo
              flat
              background-color="smoke"
              style="border-radius: 8px"
            ></v-text-field>
          </div>
          <div
            :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : '49%' }"
          >
            <p style="font-size: 10px" class="mb-2 mt-4">Country & Timezone</p>
            <v-autocomplete
              :menu-props="{ offsetY: true }"
              label="Country"
              v-model="country"
              :items="sorted_countries"
              item-text="name"
              return-object
              solo
              hide-details=""
              flat
              background-color="smoke"
              style="border-radius: 8px"
              @change="checkTZ"
            ></v-autocomplete>
            <v-autocomplete
              class="mt-2"
              v-if="workspace.country && workspace.country.zones.length > 0"
              :menu-props="{ offsetY: true }"
              label="Timezone"
              v-model="timezone"
              :items="workspace.country.zones"
              item-text="name"
              solo
              hide-details=""
              flat
              background-color="smoke"
              style="border-radius: 8px"
            ></v-autocomplete>
          </div>
        </div>

        <div class="mt-0">
          <p style="font-size: 10px" class="mb-2">Keywords</p>
          <div class="d-flex justify-space-between">
            <v-text-field
              label="Add keywords"
              solo
              v-model="keyword"
              flat
              @keypress="enter"
              class="mb-4"
              hide-details=""
              dense
              style="border-radius: 6px"
              background-color="smoke"
              hint="Press enter to add"
            >
              <template v-slot:append>
                <v-btn
                  icon
                  x-small
                  class="primary"
                  elevation="0"
                  @click="addKeyword"
                  ><v-icon small color="white">mdi-plus</v-icon></v-btn
                >
              </template>
            </v-text-field>
          </div>
        </div>

        <div class="keys d-flex flex-wrap" style="gap: 5px">
          <div v-for="k in workspace.about.keywords" :key="k">
            <v-chip
              color="smoke"
              @click:close="$store.dispatch('removeKeyword', k)"
              close
            >
              {{ k }}
            </v-chip>
          </div>
        </div>
      </div>
    </v-card>

    <v-card
      v-if="workspace"
      flat
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
      max-width="500"
    >
      <div>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="
            font-size: 10pt;
            font-weight: 600;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
          "
        >
          Branding
        </v-sheet>
        <v-divider class="primary"></v-divider>
      </div>
      <div class="px-6 pt-6">
        <div style="gap: 10px; display: grid; grid-template-columns: 1fr 1fr">
          <div>
            <p class="mb-1 font-weight-medium" style="font-size: 12px">
              Brand name
            </p>
            <v-text-field
              solo
              v-model="brand_name"
              flat
              background-color="smoke"
              style="border-radius: 6px"
              hide-details=""
              value="Stix"
            ></v-text-field>
          </div>
          <div>
            <p class="mb-1 font-weight-medium" style="font-size: 12px">Font</p>
            <v-select
              label="Font"
              solo
              v-model="font"
              :items="fonts"
              flat
              item-text="name"
              hide-details=""
              background-color="smoke"
              style="border-radius: 6px"
            >
              <template v-slot:item="{ item }">
                <span :style="{ 'font-family': item.font }">{{
                  item.name
                }}</span>
              </template>

              <template v-slot:selection="{ item }">
                <span :style="{ 'font-family': item.font }">{{
                  item.name
                }}</span>
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <div class="px-6 py-4">
        <div class="d-flex flex-wrap" style="gap: 20px">
          <div class="d-flex" style="gap: 20px">
            <div>
              <p class="mb-1 font-weight-medium" style="font-size: 12px">
                Light logo
              </p>
              <v-hover v-slot="{ hover }">
                <v-card
                  class="pa-4"
                  :class="{ primary: logo_light }"
                  flat
                  outlined
                  width="80"
                  height="80"
                >
                  <div
                    v-if="!logo_light"
                    class="d-flex align-center justify-center"
                    style="height: 100%"
                  >
                    <v-btn
                      elevation="0"
                      @click="trigger('light')"
                      :loading="$store.state.loading.media"
                      icon
                      ><v-icon size="18">mdi-upload</v-icon></v-btn
                    >
                    <input
                      ref="headerRef"
                      type="file"
                      @change="onFileChange('logo')"
                      @click="resetValue"
                      accept="image/*"
                    />
                  </div>
                  <v-img
                    v-if="logo_light"
                    :src="logo_light.url"
                    class="primary"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex align-center justify-center"
                        style="height: 100%"
                      >
                        <v-btn
                          small
                          class="smoke"
                          elevation="0"
                          @click="logo_light = null"
                          :loading="$store.state.loading.media"
                          icon
                        >
                          <v-icon size="18">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-card>
              </v-hover>
            </div>
            <div>
              <p class="mb-1 font-weight-medium" style="font-size: 12px">
                Dark logo
              </p>
              <v-hover v-slot="{ hover }">
                <v-card class="pa-2" flat outlined width="80" height="80">
                  <div
                    v-if="!logo_dark"
                    class="d-flex align-center justify-center"
                    style="height: 100%"
                  >
                    <v-btn
                      elevation="0"
                      @click="trigger('dark')"
                      :loading="$store.state.loading.media"
                      icon
                      ><v-icon size="18">mdi-upload</v-icon></v-btn
                    >
                    <input
                      ref="headerRef"
                      type="file"
                      @change="onFileChange('logo')"
                      @click="resetValue"
                      accept="image/*"
                    />
                  </div>
                  <v-img v-if="logo_dark" :src="logo_dark.url">
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex align-center justify-center"
                        style="height: 100%"
                      >
                        <v-btn
                          small
                          class="smoke"
                          elevation="0"
                          @click="logo_dark = null"
                          :loading="$store.state.loading.media"
                          icon
                        >
                          <v-icon size="18">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-card>
              </v-hover>
            </div>
          </div>
        </div>
      </div>

      <div class="px-6 pb-6">
        <div class="d-flex flex-wrap" style="gap: 20px">
          <div>
            <p class="mb-1 font-weight-medium" style="font-size: 12px">
              Primary color
            </p>
            <v-card
              flat
              outlined
              width="80"
              height="80"
              class="d-flex align-center justify-center"
              :style="{ backgroundColor: primary }"
            >
              <ColorPickerBrand @color="(c) => (primary = c)" />
            </v-card>
          </div>
          <div>
            <p class="mb-1 font-weight-medium" style="font-size: 12px">
              Secondary
            </p>
            <v-card
              flat
              outlined
              width="80"
              height="80"
              class="d-flex align-center justify-center"
              :style="{ backgroundColor: secondary }"
            >
              <ColorPickerBrand @color="(c) => (secondary = c)" />
            </v-card>
          </div>
          <div>
            <p class="mb-1 font-weight-medium" style="font-size: 12px">
              Button
            </p>
            <v-card
              flat
              outlined
              width="80"
              height="80"
              class="d-flex align-center justify-center"
              :style="{ backgroundColor: button }"
            >
              <ColorPickerBrand @color="(c) => (button = c)" />
            </v-card>
          </div>
          <div>
            <p class="mb-1 font-weight-medium" style="font-size: 12px">Text</p>
            <v-card
              flat
              outlined
              width="80"
              height="80"
              class="d-flex align-center justify-center"
              :style="{ backgroundColor: text }"
            >
              <ColorPickerBrand @color="(c) => (text = c)" />
            </v-card>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { timezones } from "@/mixins/timezones";
import ColorPickerBrand from "@/components/ColorPickerBrand.vue";
import { addDoc, getDoc, updateDoc, doc, collection } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db } from "@/firebase";

export default {
  mixins: [timezones],
  components: {
    ColorPickerBrand,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      loading: (state) => state.loading,
    }),
    sorted_countries() {
      let list = this.countries;
      list.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
      return list;
    },
    logo_light: {
      get() {
        return this.workspace.brand.logo_light;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "brand.logo_light",
          value: value,
        });
      },
    },
    logo_dark: {
      get() {
        return this.workspace.brand.logo_dark;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "brand.logo_dark",
          value: value,
        });
      },
    },
    country: {
      get() {
        return this.workspace.country;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "country",
          value: value,
        });
      },
    },
    timezone: {
      get() {
        return this.workspace.timezone;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "timezone",
          value: value,
        });
        // To DO: if timezone changes, then it should change the Timezone of all posts that are active and/scheduled in the future.
      },
    },
    workspace_name: {
      get() {
        return this.workspace.about.name;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "about.name",
          value: value,
        });
      },
    },
    primary: {
      get() {
        return this.workspace.brand.primary;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "brand.primary",
          value: value,
        });
      },
    },
    secondary: {
      get() {
        return this.workspace.brand.secondary;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "brand.secondary",
          value: value,
        });
      },
    },
    button: {
      get() {
        return this.workspace.brand.button;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "brand.button",
          value: value,
        });
      },
    },
    text: {
      get() {
        return this.workspace.brand.text;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "brand.text",
          value: value,
        });
      },
    },
    font: {
      get() {
        return this.workspace.brand.font;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "brand.font",
          value: value,
        });
      },
    },
    brand_name: {
      get() {
        return this.workspace.brand.name;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "brand.name",
          value: value,
        });
      },
    },
  },
  data() {
    return {
      fonts: [
        {
          name: "Inter",
          font: "inter",
        },
        {
          name: "Playfair Display",
          font: "playfair display",
        },
      ],
      keyword: "",
      uploading: null,
    };
  },
  methods: {
    addKeyword() {
      if (this.keyword != "") {
        this.$store.dispatch("addKeyword", this.keyword);
        this.keyword = "";
      }
    },
    checkTZ(v) {
      if (v.zones.length > 0) {
        this.timezone = v.zones[0];
      }
    },
    enter(v) {
      if (v.key === "Enter" && this.keyword != "") {
        this.$store.dispatch("addKeyword", this.keyword);
        this.keyword = "";
      }
    },
    async onFileChange(type) {
      // this.$store.commit("setLoading", { type: "media", val: true });
      var files = this.$refs.headerRef.files;
      const upload = files[0];
      var fileName = upload.name;
      var fileExtension = fileName.split(".").pop();
      const filesize = Math.floor(upload.size / 1024);

      const storage = getStorage();

      const metadata = {
        contentType: upload.type,
      };
      const docRef = await addDoc(
        collection(db, "workspaces", this.userProfile.workspace, "brand"),
        {
          title: fileName,
          extension: fileExtension,
          created_at: new Date(),
          created_by: this.userProfile.uid,
          created_by_email: this.userProfile.email,
          workspace: this.userProfile.workspace,
          size: filesize,
          type: type,
        }
      );
      const storageRef = ref(
        storage,
        `${this.userProfile.workspace}/brand/${docRef.id}.${fileExtension}`
      );

      await uploadBytesResumable(storageRef, upload, metadata);

      const downloadURL = await getDownloadURL(storageRef);
      const updateRef = doc(
        db,
        "workspaces",
        this.userProfile.workspace,
        "brand",
        docRef.id
      );

      await updateDoc(updateRef, {
        uid: docRef.id,
        url: downloadURL,
      });

      const docSnap = await getDoc(
        doc(db, "workspaces", this.userProfile.workspace, "brand", docRef.id)
      );

      if (docSnap.exists()) {
        this[`logo_${this.uploading}`] = docSnap.data();
      }

      this.uploading = null;
      this.$store.commit("setNotification", {
        show: true,
        text: `${type} added!`,
        type: "primary",
        icon: "",
      });

      // this.$store.commit("setLoading", { type: "media", val: false });
    },
    setPrimary(v) {
      console.log(v);
      this.primary = v;
    },
    resetValue(event) {
      event.target.value = "";
    },
    trigger(v) {
      this.uploading = v;
      this.$refs.headerRef.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.keywords {
  gap: 10px;
  flex-wrap: wrap;
}

input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
}
</style>
