var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup-container",class:{
      mob: _vm.$vuetify.breakpoint.smAndDown,
      desk: !_vm.$vuetify.breakpoint.smAndDown,
    }},[(_vm.overlay)?_c('v-overlay',{staticClass:"animate__animated animate__slideInRight text-center d-flex justify-center align-center flex-column",attrs:{"value":_vm.overlay,"color":"button","opacity":"1"}},[_c('p',{staticClass:"primary--text mb-4"},[_vm._v("Creating your awesome workspace..")]),_c('v-progress-linear',{staticClass:"mx-auto",staticStyle:{"width":"100px"},attrs:{"rounded":"","indeterminate":"","color":"primary"}})],1):_vm._e(),_c('div',{staticClass:"left"},[_c('div',{staticClass:"logo"},[_c('v-img',{attrs:{"src":require("@/assets/icon.svg"),"contain":""}}),_c('b',{staticStyle:{"font-size":"18px"}},[_vm._v("Stix")])],1),_c('div',{staticClass:"sub-container form",style:({
          width: _vm.$vuetify.breakpoint.smAndDown ? '80%' : '60%',
          paddingBottom: _vm.$vuetify.breakpoint.smAndDown ? '0' : '130px',
          marginTop: _vm.$vuetify.breakpoint.smAndDown ? '30px' : '130px',
        })},[_c('div',[_c('h1',{style:({
              fontSize: _vm.$vuetify.breakpoint.smAndDown ? '35px' : '40px',
            })},[_vm._v(" Verify your email ")]),_c('p',[_vm._v(" An email has been sent to "+_vm._s(_vm.email)+". Click the button in the e-mail to continue. ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"elevation":"0","height":"50"}},[_vm._v("Resend e-mail")])],1)])]),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"button"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }