<template>
  <div style="gap: 20px; padding: 0 44px">
    <div class="d-flex py-3 justify-space-between sticky" style="gap: 20px">
      <div style="gap: 20px; display: grid; grid-template-columns: 200px 200px">
        <CategorySelect :category.sync="category" />
        <AccountsSelect :selected_accounts.sync="selected_accounts" />
      </div>
      <div
        class="d-flex justify-center align-center"
        style="gap: 20px"
        v-if="$route.path.includes('/calendar')"
      >
        <v-btn
          :disabled="this.$store.state.schedules.window === 0"
          @click="previous"
          icon
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <span
          style="
            font-size: 16px;
            font-weight: 600;
            width: 85px;
            text-align: center;
          "
          >{{ $store.state.schedules.month }}</span
        >
        <v-btn
          :disabled="
            this.$store.state.schedules.window ===
            this.$store.state.schedules.calendar.length - 1
          "
          icon
          @click="next"
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
        <v-btn text small @click="today">Today</v-btn>
      </div>
    </div>

    <v-card
      flat
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
      class="calendarcard"
      ><div>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="
            font-size: 10pt;
            font-weight: 600;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
          "
        >
          Calendar
        </v-sheet>
        <v-divider class="primary"></v-divider>
      </div>
      <v-window v-model="window">
        <v-window-item v-for="(n, i) in calendar" :key="i">
          <div class="calendar">
            <div class="day" v-for="(d, index) in n.days" :key="index">
              <div>
                <div class="day-title" :class="{ today: d.today }">
                  <span> {{ d.text }}</span>
                </div>
                <v-divider></v-divider>
              </div>

              <div class="pa-2">
                <v-btn
                  elevation="0"
                  small
                  text
                  :disabled="d.past && !d.today"
                  block
                  @click="$router.push(`/post?scheduled=${d.unix}`)"
                  >Add post</v-btn
                >
              </div>
              <div class="px-2 posts" v-if="!loading.calendar">
                <div v-for="p in d.posts" :key="p.uid">
                  <Post
                    :post="p"
                    v-if="showPost(p.post)"
                    :calendar_uid="d.uid"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Post from "@/components/post/CalendarPostCard.vue";
import AccountsSelect from "@/components/selects/accountSelect.vue";
import CategorySelect from "@/components/selects/categorySelect.vue";

export default {
  components: {
    Post,
    AccountsSelect,
    CategorySelect,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      calendar: (state) => state.schedules.calendar,
      week: (state) => state.schedules.week,
      accounts: (state) => state.accounts,
      categories: (state) => state.posts.categories,
      window: (state) => state.schedules.window,
    }),
  },
  data() {
    return {
      selected_accounts: [],
      category: "",
    };
  },
  methods: {
    test() {
      this.$store.dispatch("getCalendarDays");
    },
    previous() {
      this.$store.commit("previousWeek");
    },
    next() {
      this.$store.commit("nextWeek");
    },
    showPost(p) {
      if (this.selected_accounts.length === 0 && !this.category) {
        return true;
      } else if (this.category && this.selected_accounts.length === 0) {
        return p.category === this.category.uid;
      } else if (!this.category && this.selected_accounts.length > 0) {
        let show = false;
        this.selected_accounts.forEach((a) => {
          p.accounts.forEach((pa) => {
            if (pa.id === a.id) {
              show = true;
            }
          });
        });
        return show;
      } else if (this.category && this.selected_accounts.length > 0) {
        let show = false;

        this.selected_accounts.forEach((a) => {
          p.accounts.forEach((pa) => {
            if (pa.id === a.id && p.category === this.category.uid) {
              show = true;
            }
          });
        });

        return show;
      } else {
        return false;
      }
    },
    today() {
      this.$store.commit("today");
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
