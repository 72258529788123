var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar-nav-icon',_vm._g(_vm._b({},'v-app-bar-nav-icon',attrs,false),on)):_vm._e()]}}])},[_c('v-list',{style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
          borderRadius: '6px',
        })},[_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,staticClass:"px-4",attrs:{"link":""},on:{"click":function($event){return _vm.$router.push(item.route)}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-title',[_vm._v(_vm._s(item.title)+" ")])],1)}),_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item',{staticClass:"px-4",attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-logout")]),_c('v-list-item-title',[_vm._v("Sign out ")])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }