<template>
  <div class="d-flex flex-wrap flex-column">
    <v-card
      v-if="workspace"
      flat
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
      max-width="500"
      class="mb-4"
    >
      <div>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="
            font-size: 10pt;
            font-weight: 600;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
          "
        >
          Tokens
        </v-sheet>
        <v-divider class="primary"></v-divider>
      </div>
      <div class="d-flex justify-space-between px-6 py-4">
        <div>
          <p
            class="text-left"
            style="margin-bottom: 0; font-size: 18pt; font-weight: 700"
          >
            {{ workspace.counter.tokens.toLocaleString() }}
          </p>
          <p class="text-left" style="font-size: 9pt; margin-bottom: 0">
            Stix AI tokens left
          </p>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex flex-column justify-end align-end">
          <p class="text-right" style="font-size: 9pt; margin-bottom: 0">
            Renewal of<br />
            100.000 tokens <br />
            in <span style="font-weight: 700">{{ daysLeft }}</span> days.
          </p>
        </div>
      </div>
    </v-card>

    <v-card
      v-if="workspace"
      flat
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
      max-width="500"
    >
      <div>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="
            font-size: 10pt;
            font-weight: 600;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
          "
        >
          Finetuning
        </v-sheet>
        <v-divider class="primary"></v-divider>
      </div>

      <div class="px-6 pb-4 pt-2">
        <div>
          <p style="font-size: 10px" class="mb-2 mt-4">
            About your company
            <v-tooltip bottom color="primary" max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="ml-1" v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <div class="pa-2">
                <span class="white--text">
                  To have Stix AI be your best assistant, it is important that
                  Stix AI knows your business.</span
                >
              </div>
            </v-tooltip>
          </p>
          <div class="d-flex justify-space-between">
            <v-textarea
              placeholder="Describe your company"
              solo
              flat
              class="mb-4"
              v-model="company"
              rows="3"
              hide-details=""
              dense
              style="border-radius: 6px"
              background-color="smoke"
              hint="Press enter to add"
            ></v-textarea>
          </div>
        </div>

        <div>
          <p style="font-size: 10px" class="mb-2">
            About your product/service
            <v-tooltip bottom color="primary" max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="ml-1" v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <div class="pa-2">
                <span class="white--text">
                  To have Stix AI be your best assistant, it is important that
                  Stix AI knows your business.</span
                >
              </div>
            </v-tooltip>
          </p>
          <div class="d-flex justify-space-between">
            <v-textarea
              placeholder="Describe your product/service"
              solo
              flat
              class="mb-4"
              v-model="product"
              hide-details=""
              rows="3"
              dense
              style="border-radius: 6px"
              background-color="smoke"
              hint="Press enter to add"
            ></v-textarea>
          </div>
        </div>

        <div>
          <p style="font-size: 10px" class="mb-2">
            Your target audience
            <v-tooltip bottom color="primary" max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="ml-1" v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <div class="pa-2">
                <span class="white--text">
                  To have Stix AI be your best assistant, it is important that
                  Stix AI knows your target audience.</span
                >
              </div>
            </v-tooltip>
          </p>
          <div class="d-flex justify-space-between">
            <v-textarea
              placeholder="Describe your target audience"
              solo
              flat
              class="mb-4"
              v-model="target"
              rows="3"
              hide-details=""
              dense
              style="border-radius: 6px"
              background-color="smoke"
              hint="Press enter to add"
            ></v-textarea>
          </div>
        </div>

        <div>
          <p style="font-size: 10px" class="mb-2">
            Tone of voice
            <v-tooltip bottom color="primary" max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="ml-1" v-bind="attrs" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <div class="pa-2">
                <span class="white--text"
                  >Set your preferences for every time you ask Stix AI to help
                  writing posts. You can always adjust every single post.</span
                >
              </div>
            </v-tooltip>
          </p>
          <v-text-field
            solo
            style="border-radius: 8px"
            hide-details=""
            height="50"
            flat
            v-model="tone"
            background-color="smoke"
            class="mb-4"
          ></v-text-field>
        </div>

        <div>
          <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px">
            <div>
              <p style="font-size: 10px" class="mb-2 mt-2">Default language</p>
              <v-autocomplete
                solo
                style="border-radius: 8px"
                hide-details=""
                :items="languages"
                item-text="name"
                item-value="name"
                height="50"
                flat
                v-model="language"
                background-color="smoke"
              ></v-autocomplete>
            </div>

            <div>
              <p style="font-size: 10px" class="mb-2 mt-2">
                Use emoji's in posts
                <v-tooltip bottom color="primary" max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small class="ml-1" v-bind="attrs" v-on="on"
                      >mdi-information</v-icon
                    >
                  </template>
                  <div class="pa-2">
                    <span class="white--text"
                      >Set your preferences for every time you ask Stix AI to
                      help writing posts. You can always adjust every single
                      post.</span
                    >
                  </div>
                </v-tooltip>
              </p>
              <v-switch
                color="accent"
                class="mt-0 pt-0"
                hide-details=""
                v-model="emojis"
              ></v-switch>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import { languages } from "@/mixins/languages";

export default {
  mixins: [languages],
  components: {},
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      loading: (state) => state.loading,
    }),
    product: {
      get() {
        return this.workspace.about.product;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "about.product",
          value: value,
        });
      },
    },
    company: {
      get() {
        return this.workspace.about.company;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "about.company",
          value: value,
        });
      },
    },
    target: {
      get() {
        return this.workspace.about.target;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "about.target",
          value: value,
        });
      },
    },
    emojis: {
      get() {
        return this.workspace.preferences.emojis;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "preferences.emojis",
          value: value,
        });
      },
    },
    tone: {
      get() {
        return this.workspace.preferences.tone;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "preferences.tone",
          value: value,
        });
      },
    },
    language: {
      get() {
        return this.workspace.preferences.language;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "preferences.language",
          value: value,
        });
      },
    },
    country: {
      get() {
        return this.workspace.country;
      },
      set(value) {
        this.$store.dispatch("updateWorkspace", {
          type: "country",
          value: value,
        });
      },
    },
    daysLeft() {
      const today = DateTime.now();
      const next = DateTime.fromObject({
        day: 1,
        month: today.month < 12 ? today.month + 1 : 1,
        year: today.month < 12 ? today.year : today.year + 1,
      });
      const diff = next.diffNow("days");
      return Math.ceil(diff.values.days);
    },
  },
  data() {
    return {
      keyword: "",
      keywords: [],
    };
  },
  methods: {
    enter(v) {
      if (v.key === "Enter" && this.keyword != "") {
        this.$store.dispatch("addTag", this.keyword);
        this.keyword = "";
      }
    },
    remove(i) {
      this.$store.dispatch("removeTag", this.workspace.keywords[i]);
    },
  },
};
</script>

<style lang="scss" scoped>
.keywords {
  gap: 10px;
  flex-wrap: wrap;
}
</style>
