<template>
  <v-card
    width="250"
    flat
    :style="{
      borderBottom: '2px solid #272727',
      borderLeft: '0.75px solid #272727',
      borderTop: '0.75px solid #272727',
      borderRight: '2px solid #272727',
    }"
  >
    <v-img
      style="border-radius: 6px 6px 0 0"
      :aspect-ratio="16 / 9"
      :src="m.url"
    ></v-img>
    <div class="pa-4 subtitle">
      <div>
        <p class="mb-1 line-clamp" style="font-size: 11pt">{{ m.title }}</p>
        <p class="mb-0" style="font-size: 10px; font-weight: 600; opacity: 0.4">
          {{ Number.parseFloat(m.size / 1024).toFixed(1) }}MB &#183;
          {{ m.extension.toUpperCase() }}
        </p>
      </div>
      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link class="px-4" @click="preview(m.url)">
            <v-icon small class="mr-3">mdi-open-in-new</v-icon>
            <v-list-item-title>View</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            class="px-4"
            @click="$store.dispatch('deleteMedia', m.uid)"
          >
            <v-icon small class="mr-3">mdi-delete</v-icon>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["m"],
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    preview(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  display: grid;
  grid-template-columns: auto 36px;
  gap: 10px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
