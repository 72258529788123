import { db } from "@/firebase";
import {
  getDocs,
  collection,
  getDoc,
  doc,
  collectionGroup,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();

const admin = {
  state: {
    calendar: [],
    notifications: [],
    users: [],
    workspaces: [],
    posts: [],
    backlog: [],
    emails: [],
    articles: [],
  },
  mutations: {
    setAdminUsers(state, val) {
      state.users = val;
    },
    setAdminWorkspaces(state, val) {
      state.workspaces = val;
    },
    setAdminNotifications(state, val) {
      state.notifications = val;
    },
    setAdminCalendar(state, val) {
      state.calendar = val;
    },
    setAdminPosts(state, val) {
      state.posts = val;
    },
    setAdminBacklog(state, val) {
      state.backlog = val;
    },
    setAdminEmails(state, val) {
      state.emails = val;
    },
    setAdminArticles(state, val) {
      state.articles = val;
    },
  },
  actions: {
    async fetchAdmin({ dispatch }) {
      await dispatch("fetchAdminUsers");
      await dispatch("fetchAdminWorkspaces");
      await dispatch("fetchAdminNotifications");
      await dispatch("fetchAdminCalendar");
      await dispatch("fetchAdminBacklog");
      await dispatch("fetchAdminEmails");
      // await dispatch("fetchAdminPosts");
    },
    async fetchAdminUsers({ commit }) {
      commit("setLoading", { type: "admin.users", val: true });
      let users = [];

      const querySnapshot = await getDocs(collection(db, "users"));

      querySnapshot.forEach(async (d) => {
        let user = d.data();
        let workspace_names = [];
        for (let x in user.workspaces) {
          const docSnap = await getDoc(
            doc(db, "workspaces", user.workspaces[x])
          );
          if (docSnap.exists()) {
            workspace_names.push(docSnap.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        }
        user.workspaces = workspace_names;
        users.push(user);
      });

      users.sort((a, b) =>
        a.joined < b.joined ? 1 : b.joined < a.joined ? -1 : 0
      );

      commit("setAdminUsers", users);
      commit("setLoading", { type: "admin.users", val: false });
    },
    async fetchAdminWorkspaces({ commit }) {
      commit("setLoading", { type: "admin.workspaces", val: true });
      let workspaces = [];

      const querySnapshot = await getDocs(collection(db, "workspaces"));

      querySnapshot.forEach(async (d) => {
        let workspace = d.data();
        workspaces.push(workspace);
      });

      workspaces.sort((a, b) =>
        a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
      );

      commit("setAdminWorkspaces", workspaces);
      commit("setLoading", { type: "admin.workspaces", val: false });
    },
    async fetchAdminArticles({ commit }) {
      commit("setLoading", { type: "admin.articles", val: true });
      const a = httpsCallable(functions, "fetchArticles");
      const articles = await a();
      commit("setAdminArticles", articles.data);
      commit("setLoading", { type: "admin.articles", val: false });
    },
    async fetchAdminNotifications({ commit }) {
      commit("setLoading", { type: "admin.notifications", val: true });
      let notifications = [];

      const querySnapshot = await getDocs(collection(db, "notifications"));

      querySnapshot.forEach(async (d) => {
        let workspace = d.data();
        notifications.push(workspace);
      });

      notifications.sort((a, b) =>
        a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
      );

      commit("setAdminNotifications", notifications);
      commit("setLoading", { type: "admin.notifications", val: false });
    },
    async fetchAdminBacklog({ commit }) {
      commit("setLoading", { type: "admin.backlog", val: true });
      let backlog = {
        requested: [],
        backlog: [],
        in_development: [],
        ready_for_review: [],
        done: [],
      };

      const querySnapshot = await getDocs(collection(db, "backlog"));

      querySnapshot.forEach(async (d) => {
        let state = d.data().status.replaceAll(" ", "_");
        backlog[state].push(d.data());
      });

      for (const [key, value] of Object.entries(backlog)) {
        if (key) {
          value.sort((a, b) =>
            a.index < b.index ? -1 : b.index < a.index ? 1 : 0
          );
        }
      }

      commit("setAdminBacklog", backlog);
      commit("setLoading", { type: "admin.backlog", val: false });
    },
    async fetchAdminEmails({ commit }) {
      commit("setLoading", { type: "admin.emails", val: true });
      let emails = [];

      const querySnapshot = await getDocs(collection(db, "mail"));

      querySnapshot.forEach(async (d) => {
        let e = d.data();
        e.uid = d.id;
        emails.push(e);
      });

      // emails.sort((a, b) =>
      //   a.delivery.endTime < b.delivery.endTime
      //     ? -1
      //     : b.delivery.endTime < a.delivery.endTime
      //     ? 1
      //     : 0
      // );

      commit("setAdminEmails", emails);
      commit("setLoading", { type: "admin.emails", val: false });
    },
    async fetchAdminCalendar({ commit }) {
      commit("setLoading", { type: "admin.calendar", val: true });
      let calendar = [];

      const querySnapshot = await getDocs(collection(db, "calendar"));

      querySnapshot.forEach(async (d) => {
        calendar.push(d.data());
      });

      calendar.sort((a, b) => (a.unix < b.unix ? -1 : b.unix < a.unix ? 1 : 0));

      commit("setAdminCalendar", calendar);
      commit("setLoading", { type: "admin.calendar", val: false });
    },
    async fetchAdminPosts({ commit }) {
      commit("setLoading", { type: "admin.posts", val: true });
      let posts = [];

      const museums = query(
        collectionGroup(db, "posts", where("uid", "!=", null))
      );
      const querySnapshot = await getDocs(museums);
      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        posts.push(doc.data());
      });

      posts.sort((a, b) =>
        a.created_at < b.created_at ? -1 : b.created_at < a.created_at ? 1 : 0
      );

      commit("setAdminPosts", posts);
      commit("setLoading", { type: "admin.posts", val: false });
    },
    async updateBacklog({ commit, state }, v) {
      commit("setLoading", { type: "admin.backlog", val: true });
      let backlog = state.backlog;
      console.log(v);

      if (v.event.moved) {
        const index = backlog[v.state].findIndex(
          (i) => i.uid === v.event.moved.element.uid
        );
        console.log(index);
      } else if (v.event.removed) {
        const index = backlog[v.state].findIndex(
          (i) => i.uid === v.event.removed.element.uid
        );
        backlog[v.state].splice(index, 1);
      } else if (v.event.added) {
        let el = v.event.added.element;
        el.status = v.state.replaceAll("_", " ");
        backlog[v.state].push(el);
      }

      await Promise.all(
        backlog[v.state].map(async (item) => {
          await updateDoc(doc(db, "backlog", item.uid), item);
        })
      );

      commit("setAdminBacklog", backlog);
      commit("setLoading", { type: "admin.backlog", val: false });
    },
  },
  getters: {},
};

export default admin;
