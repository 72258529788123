<template>
  <v-card
    max-width="700"
    flat
    :style="{
      borderBottom: '3px solid #272727',
      borderLeft: '1px solid #272727',
      borderTop: '1px solid #272727',
      borderRight: '3px solid #272727',
    }"
  >
    <div>
      <v-sheet
        class="d-flex justify-center align-center py-2"
        color="accent"
        style="
          font-size: 10pt;
          font-weight: 600;
          border-top-right-radius: 7px;
          border-top-left-radius: 7px;
        "
      >
        Media
      </v-sheet>
      <v-divider class="primary"></v-divider>
    </div>
    <v-data-table
      :headers="headers"
      :items="filtered"
      :items-per-page="5"
      hide-default-header
      @click:row="selectMedia"
      style="cursor: pointer"
      :search="search_media"
      :loading="media_loading"
      calculate-widths
    >
      <template v-slot:[`item.url`]="{ item }">
        <v-hover v-slot="{ hover }">
          <div style="width: 140px">
            <v-img
              :src="item.thumbnail ? item.thumbnail : item.url"
              height="100"
              width="140"
              contain
            >
              <div
                @click="preview(item.url)"
                v-if="hover"
                class="d-flex align-center justify-center transition-fast-in-fast-out"
                style="height: 100%"
              >
                <v-btn class="smoke" icon small
                  ><v-icon color="primary" small>mdi-open-in-new</v-icon></v-btn
                >
              </div>
            </v-img>
          </div>
        </v-hover>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <div>
          <p class="mb-1">{{ item.title }}</p>
          <p
            class="mb-0"
            style="font-size: 10px; font-weight: 600; opacity: 0.4"
          >
            {{ Number.parseFloat(item.size / 1024).toFixed(1) }}MB &#183;
            {{ item.extension.toUpperCase() }} &#183;
            {{ item.created_by_email.toUpperCase() }} &#183;
            {{ formatDate(item.created_at) }}
          </p>
          <div class="d-flex flex-wrap mt-1" style="gap: 5px">
            <v-chip
              color="info"
              x-small
              v-for="(c, index) in item.tags"
              :key="index"
              >{{ c }}</v-chip
            >
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link class="px-4" @click="preview(item.url)">
              <v-icon small class="mr-3">mdi-open-in-new</v-icon>
              <v-list-item-title>View</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              class="px-4"
              @click="$store.dispatch('deleteMedia', item.uid)"
            >
              <v-icon small class="mr-3">mdi-delete</v-icon>
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { db } from "@/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  props: ["drawer", "tag"],
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      accounts: (state) => state.accounts,
      categories: (state) => state.posts.categories,
      media: (state) => state.media.media,
      media_loading: (state) => state.loading.media,
    }),
    filtered() {
      let list = this.media;
      if (this.tag) {
        list = list.filter((i) => i.tags.includes(this.tag));
      }
      return list;
    },
  },
  data() {
    return {
      headers: [
        { text: "Image", value: "url", align: "start" },
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },

        { text: "Actions", value: "actions", align: "end" },
      ],
      items: [],
      loading: false,
      next_page: "",
      search: "",
      search_media: "",
      tab: 0,
    };
  },
  methods: {
    deleteItem(i) {
      if (i.posts.length > 0) {
        this.$store.commit("setNotification", {
          show: true,
          text: "This file is still used in some posts. Please remove (the images from) these posts first.",
          type: "error",
          icon: "",
          multiline: true,
        });
      } else {
        console.log(i);
        const storage = getStorage();

        const storageRef = ref(
          storage,
          `${i.workspace}/${i.uid}.${i.extension}`
        );

        deleteObject(storageRef)
          .then(async () => {
            await deleteDoc(doc(db, "workspaces", i.workspace, "media", i.uid));
            this.$store.dispatch("fetchMedia");
          })
          .then(() => {
            this.$store.commit("setNotification", {
              show: true,
              text: "File deleted!",
              type: "primary",
              icon: "",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    change(v) {
      if (!v) {
        this.$emit("close");
      }
    },
    preview(url) {
      window.open(url, "_blank");
    },
    async searchMedia() {
      this.loading = true;
      this.tab = 1;
      const functions = getFunctions();
      const get = httpsCallable(functions, "getPexels");
      const res = await get({ query: this.search });
      this.items = [];
      this.items = res.data.photos;
      this.next_page = res.data.next_page;
      this.nextPage();
      this.loading = false;
    },
    async nextPage() {
      const functions = getFunctions();
      const get = httpsCallable(functions, "getNextPexels");
      const res = await get({ url: this.next_page });

      res.data.photos.forEach((p) => {
        this.items.push(p);
      });
      this.next_page = res.data.next_page;
    },
    formatDate(d) {
      return moment(d.toDate()).format("DD-MM-YYYY");
    },
    async onFileChange() {
      var files = this.$refs.headerRef.files;
      const uploads = [...files];

      for (let x in uploads) {
        this.uploadFiles(uploads[x]);
      }
    },
    setPreviewSize(size) {
      this.$store.dispatch("setPreviewSize", size);
    },
    selectMedia(i) {
      this.$emit("media", {
        url: i.uid ? i.url : i.src.large,
        type: "stock",
        uid: i.uid ? i.uid : "",
        title: i.uid ? i.title : "",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
