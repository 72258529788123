<template>
  <div class="feed-container" v-if="accounts.length > 0">
    <div class="left smoke animate__animated animate__slideInLeft">
      <v-list nav class="smoke lister overflow-auto">
        <v-list-item
          v-for="a in accounts"
          :key="a.id"
          link
          class="justify-center"
          @click="
            filtered.find((c) => c.id === a.id) != undefined
              ? removeFeed(a.id)
              : addFeed(a.id)
          "
          :style="{
            opacity: filtered.find((c) => c.id === a.id) != undefined ? 1 : 0.6,
          }"
        >
          <div class="d-flex flex-column pb-2 pt-4">
            <v-badge overlap color="accent" left avatar>
              <v-avatar size="40">
                <img :src="a.profile_picture" alt="John"
              /></v-avatar>
              <template v-slot:badge>
                <v-img
                  style="border: 0.5px solid #fff; border-radius: 50px"
                  height="20"
                  width="20"
                  :src="require(`@/assets/${a.type}.png`)"
                ></v-img>
              </template>
            </v-badge>

            <p
              class="primary--text mt-1 mb-0 text-center"
              style="font-size: 12px; font-weight: 500"
            >
              {{ a.name }}
            </p>
          </div>
        </v-list-item>
      </v-list>
    </div>
    <div class="feed">
      <div class="feeds" v-if="!loading.accounts">
        <div class="list" v-for="a in filtered" :key="a.id">
          <v-card
            flat
            :style="{
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }"
            class="d-flex justify-center align-center mb-2 py-2 px-4"
          >
            <v-img
              max-width="25"
              height="25"
              contain
              :src="require(`@/assets/${a.type}.png`)"
              class="mr-2"
            ></v-img>
            <span style="font-size: 14px">{{ a.name }}</span>
          </v-card>
          <div v-if="a.type === 'facebook'" class="list-items">
            <div
              v-for="i in a.feed"
              :key="i.id"
              :style="{
                borderBottom: '3px solid #272727',
                borderLeft: '1px solid #272727',
                borderTop: '1px solid #272727',
                borderRight: '3px solid #272727',
                borderRadius: '10px',
              }"
            >
              <FacebookPreview
                :account="a"
                :outlined="true"
                :message="i.message"
                :channels="accounts"
                :comments="i.comments.data"
                :image="
                  i.attachments ? i.attachments.data[0].media.image.src : null
                "
                :type="i.attachments ? i.attachments.data[0].type : null"
                :likes="i.likes"
                :id="i.id"
                :date="i.created_time"
                :options="true"
              />
            </div>
          </div>

          <div v-else-if="a.type === 'instagram'" class="list-items">
            <div
              v-for="i in a.feed"
              :key="i.id"
              :style="{
                borderBottom: '3px solid #272727',
                borderLeft: '1px solid #272727',
                borderTop: '1px solid #272727',
                borderRight: '3px solid #272727',
                borderRadius: '10px',
              }"
            >
              <InstagramPreview :post="i" :account="a" :outlined="true" />
            </div>
          </div>

          <div v-if="a.type === 'linkedin'">test</div>
        </div>
      </div>
      <div v-else class="pt-10">
        <v-progress-circular
          indeterminate
          size="30"
          color="primary"
          style="z-index: 99"
        ></v-progress-circular>
      </div>
    </div>
  </div>
  <div v-else style="padding: 20px 44px">
    <h1 class="mr-8">Feed</h1>
    <p style="opacity: 0.6; margin-top: 10px">
      No accounts have been setup yet. Click
      <span style="cursor: pointer" @click="$router.push('/accounts')"
        ><u>here</u></span
      >
      to start!
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FacebookPreview from "@/components/preview/PreviewFacebook.vue";
import InstagramPreview from "@/components/preview/instagramPreview.vue";

export default {
  data() {
    return {
      channel: null,
      feed: null,
    };
  },
  components: {
    FacebookPreview,
    InstagramPreview,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      accounts: (state) => state.accounts,
      categories: (state) => state.posts.categories,
      posts: (state) => state.posts.posts,
    }),
    filtered() {
      let list = this.accounts;

      list = list.filter((a) =>
        this.userProfile.preferences.feed.includes(a.id)
      );

      return list;
    },
  },
  methods: {
    addFeed(id) {
      this.$store.dispatch("updateFeed", {
        type: "add",
        value: id,
      });
    },
    removeFeed(id) {
      this.$store.dispatch("updateFeed", {
        type: "remove",
        value: id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
