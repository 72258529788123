import { db } from "@/firebase";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  addDoc,
} from "firebase/firestore";

const topics = {
  state: {
    show: false,
    topics: [],
    tags: [],
  },
  mutations: {
    addTopic(state, val) {
      state.topics.unshift(val);
    },
    setTopics(state, val) {
      state.topics = val;
    },
    setTopicTags(state, val) {
      state.tags = val;
    },
    setTopicsShow(state, val) {
      state.show = val;
    },
    addTag(state, val) {
      val.forEach((t) => {
        if (!state.tags.includes(t)) {
          state.tags.unshift(t);
        }
      });
      state.tags.sort((a, b) => (a < b ? -1 : b < a ? 1 : 0));
    },
    updateTopic(state, val) {
      let i = state.topics.findIndex((t) => t.uid === val.uid);
      state.topics[i].topic = val.topic;
      state.topics[i].tags = val.tags;
    },
    removeTopic(state, val) {
      let i = state.topics.findIndex((t) => t.uid === val);
      state.topics.splice(i, 1);
    },
  },
  actions: {
    async favoriteTopic({ commit, dispatch, rootState }, val) {
      const ref = doc(
        db,
        "workspaces",
        rootState.workspace.workspace.uid,
        "topics",
        val.uid
      );

      if (val.value) {
        await updateDoc(ref, {
          favorites: arrayUnion(rootState.users.userProfile.uid),
        });
      } else {
        await updateDoc(ref, {
          favorites: arrayRemove(rootState.users.userProfile.uid),
        });
      }

      commit("setNotification", {
        show: true,
        text: `Topic updated!`,
        type: "primary",
        icon: "",
      });

      dispatch("fetchTopics");
    },
    async fetchTopics({ commit, rootState }) {
      commit("setLoading", { type: "topics", val: true });
      let topics = [];
      let tags = [];
      const querySnapshot = await getDocs(
        collection(
          db,
          "workspaces",
          rootState.workspace.workspace.uid,
          "topics"
        )
      );
      querySnapshot.forEach((doc) => {
        topics.push(doc.data());
        doc.data().tags.forEach((t) => {
          if (!tags.includes(t)) {
            tags.push(t);
          }
        });
      });

      topics.sort((a, b) =>
        a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
      );

      tags.sort((a, b) => (a < b ? -1 : b < a ? 1 : 0));

      commit("setTopicTags", tags);
      commit("setTopics", topics);
      commit("setLoading", { type: "topics", val: false });
    },
    async saveTopic({ commit, rootState }, val) {
      commit("setLoading", { type: "topics", val: true });
      if (val.uid) {
        await updateDoc(
          doc(
            db,
            "workspaces",
            rootState.users.userProfile.workspace,
            "topics",
            val.uid
          ),
          {
            topic: val.topic,
            tags: val.tags,
          }
        );
        commit("updateTopic", val);
        commit("setNotification", {
          show: true,
          text: "Topic updated!",
          type: "primary",
          icon: "",
        });
      } else {
        let new_topic = {
          topic: val.topic,
          created_by_email: rootState.users.userProfile.email,
          created_by: rootState.users.userProfile.uid,
          created_at: new Date(),
          favorites: [],
          tags: val.tags ? val.tags : [],
        };
        const docRef = await addDoc(
          collection(
            db,
            "workspaces",
            rootState.users.userProfile.workspace,
            "topics"
          ),
          new_topic
        );
        await updateDoc(
          doc(
            db,
            "workspaces",
            rootState.users.userProfile.workspace,
            "topics",
            docRef.id
          ),
          {
            uid: docRef.id,
          }
        );
        new_topic.uid = docRef.id;
        commit("addTopic", new_topic);
        commit("setNotification", {
          show: true,
          text: "Topic added!",
          type: "primary",
          icon: "",
        });
      }

      commit("addTag", val.tags);
      commit("setLoading", { type: "topics", val: false });
    },
    async deleteTopic({ commit, rootState }, val) {
      commit("setLoading", { type: "topics", val: true });
      await deleteDoc(
        doc(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "topics",
          val
        )
      );
      commit("setNotification", {
        show: true,
        text: `Topic deleted!`,
        type: "primary",
        icon: "",
      });

      commit("removeTopic", val);
      commit("setLoading", { type: "topics", val: false });
    },
  },
  getters: {},
};

export default topics;
