<template>
  <div>
    <div>
      <div v-if="!$vuetify.breakpoint.smAndDown">
        <div class="top">
          <v-btn
            @click="$router.push('/post')"
            height="40"
            elevation="0"
            color="primary"
            class="px-8"
            >Add Post</v-btn
          >
          <v-text-field
            label="Search posts ..."
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }"
            flat
            solo
            dense
            hide-details=""
            style="border-radius: 8px; max-width: 200px"
            v-model="query.search"
            clearable
            @click:clear="clearSearch"
            @change="changeSearch"
          >
          </v-text-field>
          <v-select
            v-model="query.recurring"
            @change="changeRecurring"
            @click:clear="clearRecurring"
            flat
            solo
            clearable
            dense
            hide-details=""
            label="Recurring"
            style="border-radius: 8px; max-width: 200px"
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }"
            :items="recurring_items"
            placeholder="Filter on recurring"
          >
            <template v-slot:item="{ item }">
              <div
                class="d-flex align-center"
                style="width: 100%; font-size: 13px"
              >
                <v-icon
                  small
                  class="mr-2"
                  v-if="item.text === 'Recurring'"
                  color="button darken-2"
                  >mdi-leaf</v-icon
                >
                <v-icon
                  small
                  class="mr-2"
                  v-if="item.text === 'One-time'"
                  color="smoke darken-2"
                  >mdi-numeric-1-circle</v-icon
                >
                {{ item.text }}
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center" style="width: 100%">
                <v-icon
                  small
                  class="mr-2"
                  v-if="item.text === 'Recurring'"
                  color="button darken-2"
                  >mdi-leaf</v-icon
                >
                <v-icon
                  small
                  class="mr-2"
                  v-if="item.text === 'One-time'"
                  color="smoke darken-2"
                  >mdi-numeric-1-circle</v-icon
                >
                {{ item.text }}
              </div>
            </template>
          </v-select>

          <v-select
            flat
            solo
            dense
            hide-details=""
            @change="changeCategory"
            @click:clear="clearCategory"
            label="Category"
            style="border-radius: 8px; max-width: 200px"
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }"
            :items="categories"
            item-value="uid"
            v-model="query.category"
            item-text="name"
            placeholder="Filter on category"
            value="All"
            clearable
            :loading="loading.categories"
          >
          </v-select>
        </div>

        <div class="posts" v-if="filtered_posts.length > 0">
          <div v-for="p in filtered_posts" :key="p.uid">
            <PostsCard :p="p" @delete="deletePost" />
          </div>
        </div>
        <p
          v-if="posts.length === 0 && !loading.posts"
          style="opacity: 0.6; margin-top: 20px"
        >
          Whoops! No posts found yet..
        </p>
      </div>
    </div>
    <div class="mb-4">
      <ConfirmDialog :dialog="confirm_dialog" @confirm="confirm" />

      <div
        class="d-flex justify-space-between"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <h1 class="mb-4">Posts</h1>
        <v-btn
          @click="$router.push('/post')"
          class="mb-4"
          elevation="0"
          color="button"
          >Add new post</v-btn
        >
      </div>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <p style="font-size: 10px" class="mb-2">Search</p>
        <div class="d-flex">
          <v-text-field
            style="border-radius: 8px"
            label="Search"
            height="50"
            solo
            hide-details=""
            flat
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }"
          ></v-text-field>
          <FilterSheet />
        </div>
      </div>
    </div>

    <div
      v-if="$vuetify.breakpoint.smAndDown && posts.length > 0"
      class="cards_mobile"
    >
      <ContentCard v-for="i in posts" :key="i.uid" :item="i" />
      <p style="text-align: center; opacity: 0.6; margin-top: 20px">
        &#128075; That's it!
      </p>
    </div>

    <p
      v-if="posts.length === 0 && $vuetify.breakpoint.smAndDown"
      style="text-align: center; opacity: 0.6; margin-top: 20px"
    >
      Whoops! No posts found yet..
    </p>

    <div></div>
  </div>
</template>

<script>
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "@/firebase";
import FilterSheet from "@/components/FilterSheet.vue";
import { mapState } from "vuex";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import ContentCard from "@/components/ContentCard.vue";
import PostsCard from "@/components/post/PostsCard.vue";

export default {
  components: {
    FilterSheet,
    ConfirmDialog,
    ContentCard,
    PostsCard,
  },
  mounted() {
    if (this.$route.query.category) {
      this.query.category = this.$route.query.category;
    } else {
      delete this.query.category;
    }
    if (this.$route.query.recurring) {
      this.query.recurring = this.$route.query.recurring === "true";
    } else {
      delete this.query.recurring;
    }
    if (this.$route.query.search) {
      this.query.search = this.$route.query.search;
    } else {
      delete this.query.search;
    }
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      accounts: (state) => state.accounts,
      categories: (state) => state.posts.categories,
      posts: (state) => state.posts.posts,
    }),
    filtered_posts() {
      let items = this.posts;

      if (this.query.category && items.length > 0) {
        items = items.filter((p) => {
          return p.category && p.category.uid === this.query.category;
        });
      }

      if (this.query.search && items.length > 0) {
        items = items.filter((p) => {
          let found = false;
          p.variations.forEach((v) => {
            if (
              v.text.toLowerCase().includes(this.query.search.toLowerCase())
            ) {
              found = true;
            }
          });
          return found;
        });
      }

      if (this.query.recurring != null && items.length > 0) {
        items = items.filter((p) => {
          return p.recurring === this.query.recurring;
        });
      }
      return items;
    },
  },
  data() {
    return {
      confirm_dialog: false,
      colormenu: false,
      filter_channel: null,
      headers: [
        {
          text: "Category",
          value: "category",
          width: "500px",
        },
        { text: "Image", value: "image" },
        {
          text: "Message",
          align: "start",
          value: "message",
        },
        { text: "", value: "details" },

        { text: "Actions", value: "actions" },
      ],
      menu: false,
      query: {
        category: null,
        search: null,
        recurring: null,
      },
      recurring_items: [
        {
          text: "Recurring",
          value: true,
        },
        {
          text: "One-time",
          value: false,
        },
      ],
      search: "",
      selected: [],
      tab: 0,
    };
  },
  methods: {
    clearCategory() {
      delete this.query.category;
      this.$router.replace({
        name: "Posts",
        query: this.query,
      });
    },
    changeCategory(v) {
      if (v) {
        this.query.category = v;
        this.$router.replace({
          name: "Posts",
          query: this.query,
        });
      }
    },
    clearRecurring() {
      delete this.query.recurring;
      this.$router.replace({
        name: "Posts",
        query: this.query,
      });
    },
    changeRecurring(v) {
      if (v != null) {
        this.query.recurring = v;
        this.$router.replace({
          name: "Posts",
          query: this.query,
        });
      }
    },
    clearSearch() {
      delete this.query.search;
      this.$router.replace({
        name: "Posts",
        query: this.query,
      });
    },
    changeSearch(v) {
      if (v) {
        this.query.search = v;
        this.$router.replace({
          name: "Posts",
          query: this.query,
        });
      } else {
        this.clearSearch();
      }
    },
    confirm(v) {
      console.log(v);
      this.confirm_dialog = false;
    },
    deletePost(v) {
      console.log(v);
    },
    deleteSelected() {
      this.selected.forEach(async (i) => {
        await deleteDoc(
          doc(
            db,
            "workspaces",
            this.$store.state.userProfile.workspace,
            "social",
            "content",
            i.category.name,
            i.uid
          )
        );
      });
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    select(v) {
      if (this.selected.includes(v)) {
        const ind = this.selected.findIndex((i) => {
          i === v;
        });
        this.selected.splice(ind, 1);
      } else {
        this.selected.push(v);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
