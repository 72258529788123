<template>
  <lottie
    :options="defaultOptions"
    :height="height"
    :width="width"
    v-on:animCreated="handleAnimation"
  />
</template>

<script>
import Lottie from "vue-lottie";
import * as animationData from "@/assets/astronaut.json";

export default {
  components: {
    Lottie,
  },
  data() {
    return {
      animationSpeed: 1,
      defaultOptions: {
        animationData: animationData.default,
        autoplay: true,
        loop: true,
      },
      height: 0,
      loaded: false,
      loading: false,
      width: 0,
    };
  },
  mounted() {},
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleResize({ width, height }) {
      this.width = width;
      this.height = height;
    },
    stop: function () {
      this.anim.stop();
    },
    play: function () {
      this.anim.play();
    },
    pause: function () {
      this.anim.pause();
    },
    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed);
    },
  },
};
</script>

<style scoped></style>
