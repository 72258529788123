<template>
  <div style="width: 100%">
    <v-card
      :width="$vuetify.breakpoint.smAndDown ? '100%' : 330"
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
      elevation="0"
    >
      <v-img
        :height="$vuetify.breakpoint.smAndDown ? 140 : 200"
        src="@/assets/pexels/1.jpeg"
      >
        <v-btn
          block
          elevation="0"
          color="error"
          tile
          style="position: absolute; bottom: 0; width: 100%"
          v-if="item.status === 'paused'"
          ><v-icon small color="white">mdi-pause</v-icon> Paused</v-btn
        >
      </v-img>
      <div class="px-6 pt-6">
        <p class="mb-2">
          {{ item.variations.find((i) => i.type === "main").message }}
        </p>
        <p style="font-size: 10px; font-weight: 600; letter-spacing: 1px">
          {{ item.variations.length }} VARIATIONS
        </p>
      </div>
      <div class="d-flex px-6 pb-6">
        <v-chip
          class="px-4 mr-4 font-weight-medium"
          :color="item.category.color"
          style="height: 36px; border-radius: 50px"
        >
          <span
            :style="{
              color: item.category.color === '#272727' ? '#fff' : '#272727',
            }"
            >{{ item.category.name }}</span
          ></v-chip
        >
        <v-badge overlap color="accent" left avatar>
          <v-avatar
            size="36"
            :style="{
              border: '1px solid' + '#f0f0f0',
            }"
          >
            <img src="@/assets/braingineers.png" alt="John"
          /></v-avatar>
          <template v-slot:badge>
            <v-img
              style="border: 0.5px solid #fff; border-radius: 50px"
              height="20"
              width="20"
              src="@/assets/facebook.png"
            ></v-img>
          </template>
        </v-badge>
        <v-spacer></v-spacer>
        <v-btn icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      elevation: 0,
      select: false,
      show: false,
    };
  },
  props: ["item"],
  methods: {},
};
</script>

<style lang="scss" scoped></style>
