<template>
  <div>
    <v-dialog
      width="500"
      v-model="dialog"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="$store.state.users.userProfile.role != 'admin'"
          v-bind="attrs"
          v-on="on"
          elevation="0"
        >
          <v-icon small class="mr-2">mdi-account-plus</v-icon>
          <span style="font-size: 12px">Invite users</span>
        </v-btn>
      </template>

      <v-card
        flat
        width="500"
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <div class="pa-6">
          <h2 class="mb-4">Invite users</h2>
          <div
            class="email mb-2"
            v-for="(invite, index) in invites"
            :key="index"
          >
            <v-text-field
              placeholder="name@company.com"
              v-model="invite.email"
              hide-details=""
              solo
              style="border-radius: 6px"
              flat
              background-color="smoke"
            ></v-text-field>
            <v-select
              label="Role"
              style="border-radius: 6px"
              solo
              flat
              hide-details=""
              background-color="smoke"
              :items="filteredRoles"
              v-model="invite.role"
            >
              <template v-slot:selection="{ item }">
                {{ capitalize(item) }}
              </template>
              <template v-slot:item="{ item }">
                {{ capitalize(item) }}
              </template>
            </v-select>
          </div>
          <div class="d-flex justify-space-between mt-6">
            <v-btn elevation="0" @click="addInvite"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
            <div class="d-flex" style="gap: 10px">
              <v-btn
                elevation="0"
                text
                class="px-6"
                @click="
                  sent = false;
                  dialog = false;
                "
                >Close</v-btn
              >
              <v-btn elevation="0" class="px-6" color="button" @click="invite()"
                >Send</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { collection, addDoc } from "firebase/firestore";
import { db } from "@/firebase";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";

export default {
  props: [],
  components: {},
  computed: {
    filteredRoles() {
      let roles = [];
      let user_role = this.$store.state.users.userProfile.role;

      if (user_role === "editor") {
        roles.push("editor");
      } else if (user_role === "manager") {
        roles.push(["editor", "manager"]);
      } else {
        roles.push(["editor", "manager", "admin"]);
      }

      return this.roles;
    },
  },
  data() {
    return {
      dialog: false,
      invites: [
        {
          email: "",
          role: "",
          invited: true,
        },
      ],
      roles: ["admin", "manager", "editor"],

      sent: false,
    };
  },
  methods: {
    addInvite() {
      this.invites.push({
        email: "",
      });
    },
    capitalize(string) {
      let firstCharacter = string.charAt(0);
      firstCharacter = firstCharacter.toUpperCase();
      let capitalizedString = firstCharacter + string.slice(1);
      return capitalizedString;
    },
    open() {
      this.dialog = true;
    },
    async invite() {
      let emails = [];
      this.invites.forEach((e) => emails.push(e.email));
      await addDoc(collection(db, "mail"), {
        bcc: emails,
        from: "Stix <info@stix.social>",
        template: {
          name: "colleague-invite",
          data: {
            first_name: this.$store.state.users.userProfile.first_name,
            url: `https://app.stix.social/access?ws=${this.$store.state.users.userProfile.workspace}&name=${this.$store.state.workspace.workspace.name}`,
          },
        },
      });

      const ref = doc(
        db,
        "workspaces",
        this.$store.state.users.userProfile.workspace
      );

      for (let x in this.invites) {
        await updateDoc(ref, {
          invites: arrayUnion(this.invites[x]),
        });
      }

      this.$store.dispatch("fetchWorkspace");
      this.$store.commit("setNotification", {
        show: true,
        text: "Invite are sent!",
        type: "primary",
        icon: "",
      });

      this.sent = true;
      this.dialog = false;
      this.invites = [
        {
          email: "",
          role: "",
          invited: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.email {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 5px;
}
</style>
