var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-bottom-sheet',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","elevation":"0","dark":""}},'v-btn',attrs,false),on),[_vm._v("Add category")])]}}],null,false,461937138),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"240px","color":"#f0f0f0"}},[_c('div',{staticClass:"pt-6 pb-4 px-6"},[_c('v-text-field',{staticStyle:{"border-radius":"8px"},style:({
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }),attrs:{"placeholder":"Category name here..","flat":"","solo":"","hide-details":""},model:{value:(_vm.new_category.name),callback:function ($$v) {_vm.$set(_vm.new_category, "name", $$v)},expression:"new_category.name"}}),_c('h3',{staticClass:"mt-4"},[_vm._v("Pick category color")]),_vm._l((_vm.colors),function(c,index){return _c('v-btn',{key:index,staticClass:"mt-2",class:{ smoke: _vm.new_category.color === c },attrs:{"icon":""},on:{"click":function($event){_vm.new_category.color = c}}},[_c('v-icon',{attrs:{"color":c}},[_vm._v(_vm._s(c === _vm.new_category.color ? "mdi-check-circle" : "mdi-circle"))])],1)})],2),_c('div',{staticClass:"d-flex justify-center",staticStyle:{"gap":"10px"}},[_c('v-btn',{attrs:{"elevation":"0","color":"button"},on:{"click":_vm.addCategory}},[_vm._v("Add category")]),_c('v-btn',{attrs:{"elevation":"0","color":"primary","text":""},on:{"click":function($event){_vm.menu = !_vm.menu}}},[_vm._v("Cancel")])],1)])],1):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown && _vm.type === 'button')?_c('v-btn',{attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){_vm.menu = true}}},[_vm._v("Add category")]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown && _vm.type === 'plus')?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.menu = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown && _vm.type === 'list')?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.menu = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-dialog',{attrs:{"persistent":"","width":"350","transition":"dialog-bottom-transition","value":_vm.menu}},[_c('v-card',{style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }),attrs:{"flat":""}},[_c('div',{staticClass:"px-6 py-6"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"placeholder":"Category name","solo":"","flat":"","background-color":"smoke","hide-details":""},model:{value:(_vm.new_category.name),callback:function ($$v) {_vm.$set(_vm.new_category, "name", $$v)},expression:"new_category.name"}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"placeholder":"Short description","solo":"","flat":"","background-color":"smoke","hide-details":""},model:{value:(_vm.new_category.description),callback:function ($$v) {_vm.$set(_vm.new_category, "description", $$v)},expression:"new_category.description"}}),_vm._l((_vm.colors),function(c,index){return _c('v-btn',{key:index,class:{ smoke: _vm.new_category.color === c },attrs:{"icon":""},on:{"click":function($event){_vm.new_category.color = c}}},[_c('v-icon',{attrs:{"color":c}},[_vm._v(_vm._s(c === _vm.new_category.color ? "mdi-check-circle" : "mdi-circle"))])],1)})],2),_c('v-divider'),_c('v-card-actions',{staticClass:"px-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"elevation":"0","color":"button","loading":_vm.saving},on:{"click":_vm.addCategory}},[_vm._v("Save")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }