<template>
  <v-overlay
    z-index="99"
    color="primary"
    opacity="0.9"
    :value="$store.state.loading.general"
    class="d-flex justify-center align-center"
  >
    <v-progress-circular
      :size="73"
      :width="3"
      indeterminate
      color="smoke darken-4"
    >
      <v-avatar color="primary" size="70">
        <div>
          <v-img contain width="30" src="@/assets/logo/logo-white.svg"></v-img>
        </div>
      </v-avatar>
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
    };
  },
  mounted() {
    // setTimeout(function () {
    //   this.tab = 1;
    // }, 500);
  },
};
</script>
