<template>
  <v-snackbar
    v-model="$store.state.notification.show"
    bottom
    max-width="400"
    :timeout="$store.state.notification.multiline ? 7000 : 4000"
    :color="$store.state.notification.type"
    :light="$store.state.notification.type != 'primary'"
    :dark="$store.state.notification.type === 'primary'"
    :multi-line="
      $store.state.notification.multiline
        ? $store.state.notification.multiline
        : false
    "
  >
    {{ $store.state.notification.text }}

    <template
      v-if="$store.state.notification.redirect"
      v-slot:action="{ attrs }"
    >
      <v-btn
        color="button"
        text
        v-bind="attrs"
        @click="$router.push(`${$store.state.notification.redirect}`)"
      >
        {{ $store.state.notification.action_text }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  methods: {
    close() {
      this.$store.commit("setNotification", {
        status: false,
        text: "",
        type: "",
      });
    },
  },
};
</script>
