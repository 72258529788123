<template>
  <BacklogView />
</template>

<script>
import BacklogView from "@/components/BacklogView.vue";
export default {
  components: {
    BacklogView,
  },
};
</script>
