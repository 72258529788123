var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.accounts.length > 0)?_c('div',{staticClass:"feed-container"},[_c('div',{staticClass:"left smoke animate__animated animate__slideInLeft"},[_c('v-list',{staticClass:"smoke lister overflow-auto",attrs:{"nav":""}},_vm._l((_vm.accounts),function(a){return _c('v-list-item',{key:a.id,staticClass:"justify-center",style:({
            opacity: _vm.filtered.find((c) => c.id === a.id) != undefined ? 1 : 0.6,
          }),attrs:{"link":""},on:{"click":function($event){_vm.filtered.find((c) => c.id === a.id) != undefined
              ? _vm.removeFeed(a.id)
              : _vm.addFeed(a.id)}}},[_c('div',{staticClass:"d-flex flex-column pb-2 pt-4"},[_c('v-badge',{attrs:{"overlap":"","color":"accent","left":"","avatar":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-img',{staticStyle:{"border":"0.5px solid #fff","border-radius":"50px"},attrs:{"height":"20","width":"20","src":require(`@/assets/${a.type}.png`)}})]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"size":"40"}},[_c('img',{attrs:{"src":a.profile_picture,"alt":"John"}})])],1),_c('p',{staticClass:"primary--text mt-1 mb-0 text-center",staticStyle:{"font-size":"12px","font-weight":"500"}},[_vm._v(" "+_vm._s(a.name)+" ")])],1)])}),1)],1),_c('div',{staticClass:"feed"},[(!_vm.loading.accounts)?_c('div',{staticClass:"feeds"},_vm._l((_vm.filtered),function(a){return _c('div',{key:a.id,staticClass:"list"},[_c('v-card',{staticClass:"d-flex justify-center align-center mb-2 py-2 px-4",style:({
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }),attrs:{"flat":""}},[_c('v-img',{staticClass:"mr-2",attrs:{"max-width":"25","height":"25","contain":"","src":require(`@/assets/${a.type}.png`)}}),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(a.name))])],1),(a.type === 'facebook')?_c('div',{staticClass:"list-items"},_vm._l((a.feed),function(i){return _c('div',{key:i.id,style:({
                borderBottom: '3px solid #272727',
                borderLeft: '1px solid #272727',
                borderTop: '1px solid #272727',
                borderRight: '3px solid #272727',
                borderRadius: '10px',
              })},[_c('FacebookPreview',{attrs:{"account":a,"outlined":true,"message":i.message,"channels":_vm.accounts,"comments":i.comments.data,"image":i.attachments ? i.attachments.data[0].media.image.src : null,"type":i.attachments ? i.attachments.data[0].type : null,"likes":i.likes,"id":i.id,"date":i.created_time,"options":true}})],1)}),0):(a.type === 'instagram')?_c('div',{staticClass:"list-items"},_vm._l((a.feed),function(i){return _c('div',{key:i.id,style:({
                borderBottom: '3px solid #272727',
                borderLeft: '1px solid #272727',
                borderTop: '1px solid #272727',
                borderRight: '3px solid #272727',
                borderRadius: '10px',
              })},[_c('InstagramPreview',{attrs:{"post":i,"account":a,"outlined":true}})],1)}),0):_vm._e(),(a.type === 'linkedin')?_c('div',[_vm._v("test")]):_vm._e()],1)}),0):_c('div',{staticClass:"pt-10"},[_c('v-progress-circular',{staticStyle:{"z-index":"99"},attrs:{"indeterminate":"","size":"30","color":"primary"}})],1)])]):_c('div',{staticStyle:{"padding":"20px 44px"}},[_c('h1',{staticClass:"mr-8"},[_vm._v("Feed")]),_c('p',{staticStyle:{"opacity":"0.6","margin-top":"10px"}},[_vm._v(" No accounts have been setup yet. Click "),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/accounts')}}},[_c('u',[_vm._v("here")])]),_vm._v(" to start! ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }