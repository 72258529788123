<template>
  <div class="feed-container">
    <div class="left primary animate__animated animate__slideInLeft">
      <v-btn outlined block dark small
        ><v-icon x-small class="mr-1">mdi-plus</v-icon> New chat</v-btn
      >
      <v-list nav class="px-0 primary lister overflow-auto">
        <v-list-item class="px-0" dark v-for="c in chats" :key="c.uid" link>
          <span class="chat-text-item white--text" style="font-size: 10pt">
            {{ c.title }}
          </span>
          <v-spacer></v-spacer>
          <v-btn icon small><v-icon small>mdi-pencil</v-icon></v-btn>
          <v-btn icon small><v-icon small>mdi-delete</v-icon></v-btn>
        </v-list-item>
      </v-list>
    </div>
    <div style="height: 100vh; overflow-y: scroll">
      <div class="feeds" v-if="!loading.chats">
        <div
          v-for="m in chats[0].messages"
          :key="m.uid"
          class="chat-response"
          :class="{ 'smoke darken-1': m.role === 'user' }"
        >
          <div class="response">
            <v-img
              v-if="m.role === 'assistant'"
              max-width="20"
              height="20"
              src="@/assets/icons/openai.svg"
            ></v-img>
            <v-icon
              color="primary"
              style="justify-content: start"
              size="20"
              v-else
              >mdi-account</v-icon
            >
            <div>
              {{ m.content }}
            </div>
            <v-btn elevation="0" v-if="m.role === 'assistant'"
              >Use for post
            </v-btn>
          </div>
        </div>

        <v-btn elevation="0"> Regenerate response</v-btn>
        <div class="d-flex align-center white py-2">
          <v-text-field
            hide-details=""
            solo
            placeholder="Send a message"
            flat
          ></v-text-field>
          <v-btn class="mx-4" icon><v-icon small>mdi-send</v-icon></v-btn>
        </div>
      </div>
      <div v-else class="pt-10">
        <v-progress-circular
          indeterminate
          size="25"
          color="primary"
          style="z-index: 99"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      accounts: (state) => state.accounts,
      categories: (state) => state.posts.categories,
      posts: (state) => state.posts.posts,
      chats: (state) => state.chats.chats,
    }),
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
