import { render, staticRenderFns } from "./library_posts.vue?vue&type=template&id=53c3f367&scoped=true&"
import script from "./library_posts.vue?vue&type=script&lang=js&"
export * from "./library_posts.vue?vue&type=script&lang=js&"
import style0 from "./library_posts.vue?vue&type=style&index=0&id=53c3f367&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c3f367",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VIcon,VSelect,VTextField})
