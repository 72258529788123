<template>
  <div>
    <h1 class="mb-4">Workspace</h1>
    <div class="d-flex flex-column" style="gap: 20px; flex-wrap: wrap">
      <div style="width: 100%">
        <v-card
          flat
          max-width="500px"
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
            backgroundColor: '#D3F987',
          }"
        >
          <div
            class="px-6 py-6"
            style="display: grid; grid-template-columns: auto 60px"
          >
            <div>
              <h2>Subscription</h2>
              <p style="font-size: 14px" class="ma-0">
                You're currently on the <u>free Beta plan.</u>
                <v-btn icon small class="ml-2"
                  ><v-icon small>mdi-information</v-icon></v-btn
                >
              </p>
            </div>
            <div class="pl-4 d-flex align-center">
              <v-img width="50" src="@/assets/icons/payment.svg"></v-img>
            </div>
          </div>
        </v-card>
      </div>

      <div style="width: 100%">
        <v-card
          flat
          class="pa-6"
          max-width="500px"
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
        >
          <div class="d-flex justify-space-between mb-2">
            <h2>Details</h2>
            <v-btn elevation="0" v-if="edit">Save</v-btn>
          </div>
          <div
            :class="{
              'd-flex justify-space-between': !$vuetify.breakpoint.smAndDown,
              'd-flex flex-column': $vuetify.breakpoint.smAndDown,
            }"
          >
            <div
              :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : '49%' }"
            >
              <p style="font-size: 10px" class="mb-2 mt-4">Workspace name</p>
              <v-text-field
                @change="edit = true"
                class="mb-2"
                label="Workspace name"
                hide-details=""
                v-model="workspace.name"
                solo
                flat
                background-color="smoke"
                style="border-radius: 8px"
              ></v-text-field>
            </div>
            <div
              :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : '49%' }"
            >
              <p style="font-size: 10px" class="mb-2 mt-4">Timezone</p>
              <v-select
                label="Timezone"
                v-model="workspace.timezone"
                :items="timezones"
                solo
                hide-details=""
                flat
                background-color="smoke"
                style="border-radius: 8px"
              ></v-select>
            </div>
          </div>
        </v-card>
      </div>

      <div style="width: 100%">
        <v-card
          flat
          max-width="500px"
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
        >
          <div class="d-flex justify-space-between mb-2 px-6 pt-6">
            <h2>Team</h2>
            <InviteDialog />
          </div>
          <v-data-table
            class="px-2 mb-6"
            :headers="headers"
            :items="items"
            mobile-breakpoint="100"
            sort-by="email"
            hide-default-header
            :hide-default-footer="items.length > 0"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon>
                <v-icon color="primary" v-if="item.role != 'admin'" small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.permissions`]="{ item }">
              <v-chip-group>
                <v-chip small v-if="item.permissions.social.create"
                  >Create</v-chip
                >
                <v-chip small v-if="item.permissions.social.publish"
                  >Publish</v-chip
                >
              </v-chip-group>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InviteDialog from "@/components/InviteDialog.vue";

export default {
  components: {
    InviteDialog,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.userProfile,
      workspace: (state) => state.workspace,
      loading: (state) => state.loading,
    }),
  },

  data() {
    return {
      edit: false,
      headers: [
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Role",
          align: "end",
          sortable: false,
          value: "role",
        },
        {
          text: "Permissions",
          align: "end",
          sortable: false,
          value: "permissions",
        },
        {
          text: "Actions",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      items: [
        {
          email: "maxvankaathoven@gmail.com",
          role: "admin",
          permissions: {
            social: {
              create: true,
              publish: false,
            },
          },
        },
        {
          email: "maxvankaathoven@gmail.com",
          role: "member",
          permissions: {
            social: {
              create: true,
              publish: true,
            },
          },
        },
      ],

      timezones: ["UTC"],
    };
  },
};
</script>

<style lang="scss" scoped></style>
