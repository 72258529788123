<template>
  <div>
    <v-dialog
      width="300"
      style="border-radius: 10px"
      :value="dialog"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card
        flat
        width="300"
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
        class="pa-6"
      >
        <h2 class="text-center">{{ text }}</h2>
        <div class="d-flex justify-center mt-4" style="gap: 10px">
          <v-btn elevation="0" class="px-6" @click="$emit('confirm', false)"
            >No</v-btn
          >
          <v-btn
            elevation="0"
            class="px-6"
            color="button"
            @click="$emit('confirm', true)"
            >Yes</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog", "text"],
};
</script>

<style lang="scss" scoped>
.email {
  margin-bottom: 15px;
}

::v-deep .v-dialog {
  border-radius: 10px;
}
</style>
