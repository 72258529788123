<template>
  <div class="home-wrapper">
    <div class="d-flex mb-6 align-end" style="gap: 20px" v-if="userProfile">
      <h1>Hi {{ userProfile.first_name }}!</h1>
      <v-spacer></v-spacer>
      <v-select
        v-if="userProfile && accounts.length > 0"
        solo
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
        dense
        flat
        style="border-radius: 8px; max-width: 180px"
        hide-details=""
        v-model="period"
        :items="periods"
        item-text="text"
      ></v-select>
    </div>

    <div
      v-if="
        userProfile &&
        (categories.length === 0 ||
          schedules.length === 0 ||
          posts.length === 0)
      "
    >
      <div class="start mb-6">
        <v-card
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
          flat
          class="start-card"
        >
          <div>
            <v-sheet
              class="px-6 d-flex justify-center align-center py-2"
              color="accent"
              style="
                font-size: 10pt;
                font-weight: 600;
                border-top-right-radius: 7px;
                border-top-left-radius: 7px;
              "
            >
              Accounts
            </v-sheet>
            <v-divider class="primary"> </v-divider>
          </div>
          <div class="pa-6" style="width: 100%">
            <v-card elevation="1" class="start-image px-2 platforms">
              <v-img
                max-width="20"
                contain
                :src="require('@/assets/facebook.png')"
              ></v-img>
              <v-img
                max-width="20"
                contain
                :src="require('@/assets/instagram.png')"
              ></v-img>
              <v-img
                max-width="20"
                contain
                :src="require('@/assets/linkedin.png')"
              ></v-img>
              <v-img
                max-width="20"
                contain
                :src="require('@/assets/platforms/tiktok.png')"
              ></v-img>
              <v-img
                max-width="20"
                contain
                :src="require('@/assets/platforms/x.png')"
              ></v-img>
              <v-img
                max-width="20"
                contain
                :src="require('@/assets/platforms/youtube.png')"
              ></v-img>
            </v-card>
          </div>
          <div class="pt-2 px-6 pb-6">
            <div class="d-flex" style="gap: 15px" v-if="accounts.length === 0">
              <v-btn
                @click="$router.push('/accounts')"
                height="40"
                width="40"
                outlined
                icon
                large
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <h1 class="start-title">Connect my social account</h1>
            </div>
            <div class="d-flex" style="gap: 15px" v-else>
              <v-avatar size="40" color="success darken-1">
                <v-icon size="24" color="white">mdi-check</v-icon>
              </v-avatar>
              <h1 class="start-title">Connected my social account</h1>
            </div>
            <p class="start-sub mt-4">
              Connect your social media accounts to start publishing posts.
            </p>
            <div
              class="d-flex pt-2 flex-column align-center justify-center"
              style="gap: 8px"
            >
              <v-btn
                @click="$router.push('/accounts')"
                :color="accounts.length > 0 ? 'smoke' : 'primary'"
                small
                elevation="0"
                ><v-icon class="mr-1" x-small>mdi-facebook</v-icon>Connect
                account</v-btn
              >
              <v-btn color="primary lighten-2" x-small elevation="0" text
                >Demo</v-btn
              >
            </div>
          </div>
        </v-card>
        <v-card
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
          flat
          class="start-card"
        >
          <div>
            <v-sheet
              class="px-6 d-flex justify-center align-center py-2"
              color="accent"
              style="
                font-size: 10pt;
                font-weight: 600;
                border-top-right-radius: 7px;
                border-top-left-radius: 7px;
              "
            >
              Categories
            </v-sheet>
            <v-divider class="primary"> </v-divider>
          </div>
          <div class="pa-6" style="width: 100%">
            <v-card
              elevation="1"
              class="start-image px-2 d-flex justify-space-around"
            >
              <v-icon size="40">mdi-shape</v-icon>
            </v-card>
          </div>
          <div class="pt-2 px-6 pb-6">
            <div
              class="d-flex"
              style="gap: 15px"
              v-if="categories.length === 0"
            >
              <v-btn
                height="40"
                width="40"
                outlined
                icon
                large
                @click="$router.push('/categories?new=true')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <h1 class="start-title">Create my first category</h1>
            </div>
            <div class="d-flex" style="gap: 15px" v-else>
              <v-avatar size="40" color="success darken-1">
                <v-icon size="24" color="white">mdi-check</v-icon>
              </v-avatar>
              <h1 class="start-title">Created my first category</h1>
            </div>
            <p class="start-sub mt-4">
              With 'Categories' you can add posts related to eachother to be
              published.
            </p>
            <div
              class="d-flex pt-2 flex-column align-center justify-center"
              style="gap: 8px"
            >
              <v-btn
                @click="$router.push('/categories?new=true')"
                :color="categories.length > 0 ? 'smoke' : 'primary'"
                small
                elevation="0"
                ><v-icon class="mr-1" x-small>mdi-shape</v-icon>Create
                category</v-btn
              >
              <v-btn color="primary lighten-2" x-small elevation="0" text
                >Demo</v-btn
              >
            </div>
          </div>
        </v-card>

        <v-card
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
          flat
          class="start-card"
        >
          <div>
            <v-sheet
              class="px-6 d-flex justify-center align-center py-2"
              color="accent"
              style="
                font-size: 10pt;
                font-weight: 600;
                border-top-right-radius: 7px;
                border-top-left-radius: 7px;
              "
            >
              Schedules
            </v-sheet>
            <v-divider class="primary"> </v-divider>
          </div>
          <div class="pa-6" style="width: 100%">
            <v-card
              elevation="1"
              class="start-image px-2 d-flex justify-space-around"
            >
              <v-icon size="40">mdi-book-clock-outline</v-icon>
            </v-card>
          </div>
          <div class="pt-2 px-6 pb-6">
            <div class="d-flex" style="gap: 15px" v-if="schedules.length === 0">
              <v-btn
                @click="$router.push('/schedule?new=true')"
                height="40"
                width="40"
                outlined
                icon
                large
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <h1 class="start-title">Create my first schedule</h1>
            </div>
            <div class="d-flex" style="gap: 15px" v-else>
              <v-avatar size="40" color="success darken-1">
                <v-icon size="24" color="white">mdi-check</v-icon>
              </v-avatar>
              <h1 class="start-title">My first schedule created!</h1>
            </div>
            <p class="start-sub mt-4">
              With 'Schedules' your posts will be automatically published,
              renewed and recycled.
            </p>
            <div
              class="d-flex pt-2 flex-column align-center justify-center"
              style="gap: 8px"
            >
              <v-btn
                @click="$router.push('/schedule?new=true')"
                :color="schedules.length > 0 ? 'smoke' : 'primary'"
                small
                elevation="0"
                ><v-icon class="mr-1" x-small>mdi-book-clock-outline</v-icon
                >Create schedule</v-btn
              >
              <v-btn color="primary lighten-2" x-small elevation="0" text
                >Demo</v-btn
              >
            </div>
          </div>
        </v-card>

        <v-card
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
          flat
          class="start-card"
        >
          <div>
            <v-sheet
              class="px-6 d-flex justify-center align-center py-2"
              color="accent"
              style="
                font-size: 10pt;
                font-weight: 600;
                border-top-right-radius: 7px;
                border-top-left-radius: 7px;
              "
            >
              Post
            </v-sheet>
            <v-divider class="primary"> </v-divider>
          </div>
          <div class="pa-6" style="width: 100%">
            <v-card
              elevation="1"
              class="start-image px-2 d-flex justify-space-around"
            >
              <v-icon size="40">mdi-pencil</v-icon>
            </v-card>
          </div>
          <div class="pt-2 px-6 pb-6">
            <div class="d-flex" style="gap: 15px" v-if="posts.length === 0">
              <v-btn
                height="40"
                width="40"
                outlined
                icon
                large
                @click="$router.push('/post')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <h1 class="start-title">Write my first post</h1>
            </div>
            <div class="d-flex" style="gap: 15px" v-else>
              <v-avatar size="40" color="success darken-1">
                <v-icon size="24" color="white">mdi-check</v-icon>
              </v-avatar>
              <h1 class="start-title">Connected my social accounts</h1>
            </div>
            <p class="start-sub mt-4">
              Leverage our powerful Stix A.I. to write posts in minutes, or get
              help with ideation.
            </p>
            <div
              class="d-flex pt-2 flex-column align-center justify-center"
              style="gap: 8px"
            >
              <v-btn
                @click="$router.push('/post')"
                :color="posts.length > 0 ? 'smoke' : 'primary'"
                small
                elevation="0"
                ><v-icon class="mr-1" x-small>mdi-send</v-icon>Write post</v-btn
              >
              <v-btn color="primary lighten-2" x-small elevation="0" text
                >Demo</v-btn
              >
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <div v-else-if="userProfile">
      <v-card
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
        min-width="500"
        outlined
        flat
      >
        <div>
          <v-sheet
            class="d-flex justify-center align-center py-2"
            color="accent"
            style="
              font-size: 10pt;
              font-weight: 600;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            "
          >
            Summary
          </v-sheet>
          <v-divider class="primary"></v-divider>
        </div>

        <div class="cards pa-8">
          <div class="">
            <div class="d-flex mb-2">
              <v-icon color="primary" class="mr-2" small>mdi-send</v-icon>
              <h2 style="font-weight: 500">Posts</h2>
            </div>
            <p style="font-size: 44pt; font-weight: 700" class="mb-2">
              {{ impressions.today }}
            </p>
            <div class="d-flex align-center">
              <div
                class="mr-2"
                :style="{
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                  borderRadius: '20px',
                }"
              >
                <v-chip color="success">
                  <v-icon x-small class="mr-1">{{
                    impressions.today - impressions[period] > 0
                      ? "mdi-trending-up"
                      : impressions.today - impressions[period] < 0
                      ? "mdi-trending-down"
                      : "mdi-trending-neutral"
                  }}</v-icon>
                  <p class="mb-0">
                    <b
                      ><span v-if="impressions.today - impressions[period] > 0"
                        >+</span
                      >{{ impressions.today - impressions[period] }}</b
                    >
                  </p>
                </v-chip>
              </div>
            </div>
          </div>

          <div class="">
            <div class="d-flex mb-2">
              <v-icon color="primary" class="mr-2" small>mdi-eye</v-icon>
              <h2 style="font-weight: 500">Impressions</h2>
            </div>
            <p style="font-size: 44pt; font-weight: 700" class="mb-2">
              {{ impressions.today }}
            </p>
            <div class="d-flex align-center">
              <div
                class="mr-2"
                :style="{
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                  borderRadius: '20px',
                }"
              >
                <v-chip color="success">
                  <v-icon x-small class="mr-1">{{
                    impressions.today - impressions[period] > 0
                      ? "mdi-trending-up"
                      : impressions.today - impressions[period] < 0
                      ? "mdi-trending-down"
                      : "mdi-trending-neutral"
                  }}</v-icon>
                  <p class="mb-0">
                    <b
                      ><span v-if="impressions.today - impressions[period] > 0"
                        >+</span
                      >{{ impressions.today - impressions[period] }}</b
                    >
                  </p>
                </v-chip>
              </div>
            </div>
          </div>

          <div>
            <div class="d-flex mb-2">
              <v-icon color="primary" class="mr-2" small
                >mdi-cursor-default-click</v-icon
              >
              <h2 style="font-weight: 500">Engagements</h2>
            </div>
            <p style="font-size: 44pt; font-weight: 700" class="mb-2">
              {{ engagements.today }}
            </p>
            <div class="d-flex align-center">
              <div
                class="mr-2"
                :style="{
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                  borderRadius: '20px',
                }"
              >
                <v-chip color="success">
                  <v-icon x-small class="mr-1">{{
                    engagements.today - engagements[period] > 0
                      ? "mdi-trending-up"
                      : engagements.today - engagements[period] < 0
                      ? "mdi-trending-down"
                      : "mdi-trending-neutral"
                  }}</v-icon>
                  <p class="mb-0">
                    <b
                      ><span v-if="engagements.today - engagements[period] > 0"
                        >+</span
                      >{{ engagements.today - engagements[period] }}</b
                    >
                  </p>
                </v-chip>
              </div>
            </div>
          </div>

          <div>
            <div class="d-flex mb-2">
              <v-icon color="primary" class="mr-2" small>mdi-heart</v-icon>
              <h2 style="font-weight: 500">Followers</h2>
            </div>
            <p style="font-size: 44pt; font-weight: 700" class="mb-2">
              {{ followers.today }}
            </p>
            <div class="d-flex align-center">
              <div
                class="mr-2"
                :style="{
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                  borderRadius: '20px',
                }"
              >
                <v-chip color="success">
                  <v-icon x-small class="mr-1">{{
                    followers.today - followers[period] > 0
                      ? "mdi-trending-up"
                      : followers.today - followers[period] < 0
                      ? "mdi-trending-down"
                      : "mdi-trending-neutral"
                  }}</v-icon>
                  <p class="mb-0">
                    <b
                      ><span v-if="followers.today - followers[period] > 0"
                        >+</span
                      >{{ followers.today - followers[period] }}</b
                    >
                  </p>
                </v-chip>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <div class="list">
        <div class="account-row px-8">
          <p class="mb-0" style="font-size: 9pt">Account</p>
          <p class="mb-0" style="font-size: 9pt">Posts</p>
          <p class="mb-0" style="font-size: 9pt">Impressions</p>
          <p class="mb-0" style="font-size: 9pt">Engagements</p>
          <p class="mb-0" style="font-size: 9pt">Followers</p>
        </div>
        <div v-for="a in accounts" :key="a.id">
          <v-card
            :style="{
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }"
            class="account-row py-4 px-8 align-center"
            outlined
            flat
          >
            <div class="d-flex align-center" style="gap: 10px">
              <v-badge overlap color="accent" left avatar class="mb-1 mt-2">
                <v-avatar size="35"> <img :src="a.profile_picture" /></v-avatar>
                <template v-slot:badge>
                  <v-img
                    style="border: 0.5px solid #fff; border-radius: 50px"
                    height="20"
                    width="20"
                    :src="require(`@/assets/${a.type}.png`)"
                  ></v-img>
                </template>
              </v-badge>

              <p class="mb-0" style="font-weight: 400; font-size: 11pt">
                {{ a.name }}
              </p>
            </div>

            <div class="d-flex align-center" style="gap: 10px">
              <p
                class="mb-1"
                style="font-weight: 700; font-size: 14pt"
                v-if="a.insights"
              >
                222{{ a.insights.impressions.today }}
              </p>
              <v-chip small color="success">
                <p class="mb-0" style="font-weight: 500">+12</p>
              </v-chip>
            </div>

            <div class="d-flex align-center" style="gap: 10px">
              <p
                class="mb-1"
                style="font-weight: 700; font-size: 14pt"
                v-if="a.insights"
              >
                {{ a.insights.impressions.today }}
              </p>
              <v-chip small color="success">
                <p class="mb-0" style="font-weight: 500">+12</p>
              </v-chip>
            </div>

            <div class="d-flex align-center" style="gap: 10px">
              <p
                v-if="a.insights"
                class="mb-1"
                style="font-weight: 700; font-size: 14pt"
              >
                {{ a.insights.engagements.today }}
              </p>
              <v-chip small color="success">
                <p class="mb-0" style="font-weight: 500">+12</p>
              </v-chip>
            </div>

            <div class="d-flex align-center" style="gap: 10px">
              <p
                v-if="a.insights"
                class="mb-1"
                style="font-weight: 700; font-size: 14pt"
              >
                {{ a.insights.followers.today }}
              </p>
              <v-chip small color="success">
                <p class="mb-0" style="font-weight: 500">+12</p>
              </v-chip>
            </div>

            <div class="d-flex align-end" style="gap: 10px">
              <v-btn @click="$router.push(`/accounts?edit=${a.id}`)" icon>
                <v-icon color="primary" small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn @click="open(a.url)" icon>
                <v-icon color="primary" small>mdi-open-in-new</v-icon>
              </v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <div class="articles d-flex mt-6 mb-10">
      <v-card
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
        flat
        class="start-card"
        width="100%"
      >
        <div>
          <v-sheet
            class="px-6 d-flex justify-center align-center py-2"
            color="accent"
            style="
              font-size: 10pt;
              font-weight: 600;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            "
          >
            Latest articles
          </v-sheet>
          <v-divider class="primary"></v-divider>
        </div>
        <v-data-table
          :headers="headers"
          :items="articles"
          :items-per-page="3"
          class="elevation-1"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:[`item.title`]="{ item }">
            <div
              class="py-4 px-2 d-flex align-center"
              style="font-weight: 500; font-size: 12pt"
            >
              {{ item.title }}
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn elevation="0" @click="open(item.url)" small>
              Read more
            </v-btn>
          </template></v-data-table
        >
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      accounts: (state) => state.accounts,
      articles: (state) => state.admin.articles,
      schedules: (state) => state.schedules.schedules,
      posts: (state) => state.posts.posts,
      categories: (state) => state.posts.categories,
    }),
    impressions() {
      let impressions = {
        today: 0,
        last_1d: 0,
        last_7d: 0,
        last_14d: 0,
        last_30d: 0,
      };

      this.accounts.forEach((a) => {
        if (a.insights) {
          impressions.today = impressions.today + a.insights.impressions.today;
          impressions.last_1d =
            impressions.last_1d + a.insights.impressions.last_1d;
          impressions.last_7d =
            impressions.last_7d + a.insights.impressions.last_7d;
          impressions.last_14d =
            impressions.last_14d + a.insights.impressions.last_14d;
          impressions.last_30d =
            impressions.last_30d + a.insights.impressions.last_30d;
        }
      });
      return impressions;
    },
    engagements() {
      let engagements = {
        today: 0,
        last_1d: 0,
        last_7d: 0,
        last_14d: 0,
        last_30d: 0,
      };

      this.accounts.forEach((a) => {
        if (a.insights) {
          engagements.today = engagements.today + a.insights.engagements.today;
          engagements.last_1d =
            engagements.last_1d + a.insights.engagements.last_1d;
          engagements.last_7d =
            engagements.last_7d + a.insights.engagements.last_7d;
          engagements.last_14d =
            engagements.last_14d + a.insights.engagements.last_14d;
          engagements.last_30d =
            engagements.last_30d + a.insights.engagements.last_30d;
        }
      });
      return engagements;
    },
    followers() {
      let followers = {
        today: 0,
        last_1d: 0,
        last_7d: 0,
        last_14d: 0,
        last_30d: 0,
      };

      this.accounts.forEach((a) => {
        if (a.insights) {
          followers.today = followers.today + a.insights.followers.today;
          followers.last_1d = followers.last_1d + a.insights.followers.last_1d;
          followers.last_7d = followers.last_7d + a.insights.followers.last_7d;
          followers.last_14d =
            followers.last_14d + a.insights.followers.last_14d;
          followers.last_30d =
            followers.last_30d + a.insights.followers.last_30d;
        }
      });
      return followers;
    },
  },
  data: () => ({
    account: "all accounts",
    account_items: ["all accounts"],
    dashboard_items: [],
    panel: [],
    headers: [
      {
        text: "Title",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "", value: "actions", align: "end" },
    ],
    periods: [
      {
        text: "Yesterday",
        value: "last_1d",
      },
      {
        text: "Last week",
        value: "last_7d",
      },
      {
        text: "Two weeks",
        value: "last_14d",
      },
      {
        text: "30 days",
        value: "last_30d",
      },
    ],
    period: "last_30d",
    time: "today",
    time_items: ["today", "this week", "this month"],
  }),
  methods: {
    open(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
