<template>
  <v-navigation-drawer
    :value="show"
    app
    color="primary"
    temporary
    right
    width="600"
    style="z-index: 8"
    @input="change"
  >
    <div class="pa-8">
      <div class="d-flex justify-space-between align-center">
        <h1 class="white--text mt-6">Topics</h1>
        <v-btn @click="show = false" icon color="white"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>

      <p class="mb-4 white--text" style="font-size: 12px">
        Browse through your own topics or ask Stix AI to generate new topic
        suggestions for your business.
      </p>

      <v-tabs
        class="ml-2"
        height="35"
        background-color="transparent"
        hide-slider
        active-class="active-tab"
        v-model="tab"
      >
        <v-tab class="tab mr-1">Topics</v-tab>
      </v-tabs>
      <v-card flat class="pt-4" color="white">
        <div v-if="tab === 0">
          <div class="d-flex justify-space-between align-center mb-6 px-4">
            <AddTopic ref="edittopic" />

            <v-text-field
              class="px-4"
              solo
              flat
              label="Search"
              hide-details=""
              dense
              background-color="smoke"
              style="border-radius: 6px"
              prepend-inner-icon="mdi-magnify"
              v-model="search"
            ></v-text-field>
            <v-select
              label="Tags"
              background-color="smoke"
              solo
              dense
              style="border-radius: 8px; width: 150px"
              hide-details=""
              flat
              :items="tags"
              clearable
              v-model="tags_filter"
            ></v-select>
          </div>
          <div
            v-if="loading"
            style="width: 100%"
            class="d-flex justify-center mt-6"
          >
            <v-progress-circular
              indeterminate
              color="accent"
              width="2"
            ></v-progress-circular>
          </div>
          <v-data-table
            :headers="headers"
            :items="filtered"
            :items-per-page="5"
            hide-default-header
            style="cursor: pointer"
            :search="search"
            :loading="loading"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link class="px-4" @click="use(item.topic)">
                    <v-icon small class="mr-3">mdi-send</v-icon>
                    <v-list-item-title>Use</v-list-item-title>
                  </v-list-item>
                  <v-list-item link class="px-4" @click="edit(item)">
                    <v-icon small class="mr-3">mdi-pencil</v-icon>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item link class="px-4" @click="deleteItem(item.uid)">
                    <v-icon small class="mr-3">mdi-delete</v-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:[`item.tags`]="{ item }">
              <div class="d-flex flex-wrap" style="gap: 3px">
                <v-chip
                  x-small
                  color="info"
                  v-for="(t, index) in item.tags"
                  :key="index"
                  >{{ t }}</v-chip
                >
              </div></template
            >
          </v-data-table>
        </div>
      </v-card>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import AddTopic from "@/components/AddTopic.vue";

export default {
  components: {
    AddTopic,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      accounts: (state) => state.accounts,
      categories: (state) => state.posts.categories,
      topics: (state) => state.topics.topics,
      loading: (state) => state.loading.topics,
      tags: (state) => state.topics.tags,
    }),
    show: {
      get() {
        return this.$store.state.topics.show;
      },
      set(value) {
        this.$store.commit("setTopicsShow", value);
      },
    },
    filtered() {
      let filtered = this.topics;
      if (this.tags_filter) {
        filtered = filtered.filter((topic) => {
          return topic.tags.includes(this.tags_filter);
        });
      }
      return filtered;
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "topic",
        },
        { text: "Tags", value: "tags", align: "start" },
        { text: "Actions", value: "actions", align: "end" },
      ],

      search: "",
      tab: 0,
      tags_filter: "",
    };
  },
  methods: {
    change(v) {
      if (!v) {
        this.show = false;
      }
    },
    deleteItem(i) {
      this.$store.dispatch("deleteTopic", i);
    },
    edit(i) {
      const child = this.$refs.edittopic;
      child.edit(i);
    },
    use(v) {
      this.$store.commit("setPostTopic", v);
      this.$store.commit("setPostAskChatDrawer", true);
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  background-color: white;
  border-radius: 6px 6px 0 0;
  text-transform: none;
  font-size: 12px;
  opacity: 0.6;
}
.active-tab {
  opacity: 1;
}

.stockphotos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 60vh;
  overflow: scroll;
  &.small {
    img {
      max-height: 100px;
    }
  }

  &.medium {
    img {
      max-height: 150px;
    }
  }

  &.large {
    img {
      max-width: 100%;
    }
  }

  img {
    border-radius: 6px;
  }

  img:hover {
    outline: 2px solid #cfb8ff;
  }
}
</style>
