<template>
  <v-card
    :width="$vuetify.breakpoint.smAndDown ? '100%' : 300"
    class="card"
    height="90"
    flat
    :style="{
      borderBottom: '3px solid #272727',
      borderLeft: '1px solid #272727',
      borderTop: '1px solid #272727',
      borderRight: '3px solid #272727',
    }"
  >
    <div
      :class="`${a.type}`"
      class="d-flex align-center justify-center"
      style="height: 100%; border-radius: 7px 0 0 5px"
    >
      <v-img
        height="20"
        width="20"
        contain
        :src="require(`@/assets/${a.type}.png`)"
      ></v-img>
    </div>

    <div class="d-flex justify-space-between align-center">
      <div class="d-flex align-center" style="gap: 10px">
        <v-avatar size="35"> <img :src="a.profile_picture" /></v-avatar>
        <p class="mb-0" style="font-size: 16px; font-weight: 600">
          {{ a.name }}
        </p>
      </div>
      <v-chip v-if="a.access_token_valid" color="success" small
        ><v-icon small>mdi-check</v-icon></v-chip
      >
      <v-chip v-else color="error" small>Disconnected</v-chip>
    </div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="ml-1"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="primary"
          ><v-icon small>mdi-dots-vertical</v-icon></v-btn
        >
      </template>

      <v-card>
        <v-list>
          <!-- <v-list-item link>
            <v-list-item-icon
              ><v-icon color="primary" small
                >mdi-import</v-icon
              ></v-list-item-icon
            >
            Import posts
          </v-list-item> -->

          <v-list-item @click="revoke" link> Edit permissions </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-card>
</template>

<script>
export default {
  props: ["a"],
  data() {
    return {};
  },
  methods: {
    revoke() {
      this.$emit(this.a.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: grid;
  gap: 10px;
  grid-template-columns: 40px auto 60px;
  align-items: center;
}
</style>
