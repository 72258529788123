<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small v-bind="attrs" v-on="on">
        <v-icon small>mdi-format-color-fill</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-color-picker
        v-model="color"
        dot-size="17"
        mode="rgba"
        swatches-max-height="187"
      ></v-color-picker>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="menu = false"> Cancel </v-btn>
        <v-btn
          color="primary"
          text
          @click="
            menu = false;
            $emit('color', { color: color, index: index });
          "
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: ["index"],
  data() {
    return {
      color: "",
      menu: false,
    };
  },
};
</script>
