<template>
  <v-dialog fullscreen v-model="show" transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="text-h5">
        Use Google's location service?
      </v-card-title>

      <v-card-text>
        {{ item.text }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="show = false">
          Disagree
        </v-btn>

        <v-btn color="green darken-1" text @click="show = false"> Agree </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      sheet: true,
      show: false,
    };
  },
  methods: {
    set(v) {
      this.show = v;
    },
  },
};
</script>
