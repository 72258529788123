var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-system-bar',{staticClass:"d-flex justify-center align-center",attrs:{"height":"40","absolute":"","color":"primary"}},[_c('p',{staticClass:"mb-0 white--text",staticStyle:{"font-size":"10pt","cursor":"pointer"}},[_c('u',[_vm._v("www.stix.social")])])]),_c('div',{staticClass:"demo"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"d-flex align-center mb-4",staticStyle:{"gap":"5px"}},[_c('v-img',{attrs:{"max-width":"40","max-height":"40","contain":"","src":require('@/assets/logo/logo-dark.svg')}}),_vm._m(0),_c('v-img',{attrs:{"max-width":"40","max-height":"40","contain":"","src":require('@/assets/logo/logo-dark.svg')}})],1),_c('v-card',{style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }),attrs:{"flat":"","outlined":""}},[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600"},attrs:{"color":"accent"}},[_vm._v(" Note ")]),_c('v-divider',{staticClass:"primary"}),_c('div',{staticClass:"pa-4"},[_c('p',{staticClass:"mb-2",staticStyle:{"font-weight":"600","font-size":"10pt"}},[_vm._v(" Hi "+_vm._s(_vm.target.first_name)+", ")]),_c('p',{staticClass:"mb-2",staticStyle:{"font-size":"10pt"}},[_vm._v(" As we spoke before, I’ve prepared a custom demo setup for "+_vm._s(_vm.target.company)+". Let’s talk after you have checked this out!. ")]),_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"10pt"}},[_vm._v(" Cheers, "),_c('br'),_vm._v(" Max ")])])],1),_c('v-card',{staticClass:"mt-4",style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
            borderRadius: '10px',
          }),attrs:{"flat":""}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.menuItem),callback:function ($$v) {_vm.menuItem=$$v},expression:"menuItem"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.$router.push(item.route)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)],1),_c('div',{staticClass:"mt-4",style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
            borderRadius: '10px',
          }),on:{"click":function($event){return _vm.$router.push('/access')}}},[_c('v-btn',{staticClass:"primary--text",staticStyle:{"font-size":"10pt"},attrs:{"block":"","elevation":"0","color":"button"}},[_vm._v("Create my account")])],1),_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","small":""}},[_vm._v("Share with my colleagues "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-share")])],1),_c('v-spacer'),_vm._m(1)],1),_c('transition',{attrs:{"name":"slide-fade"}},[_c('router-view',{staticClass:"desktop"})],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"mx-2"},[_c('b',[_vm._v("&")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"font-size":"10pt"}},[_vm._v(" Questions? "),_c('u',[_vm._v("max@stix.social")])])
}]

export { render, staticRenderFns }