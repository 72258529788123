<template>
  <div class="accounts-container">
    <!-- <ImportPosts /> -->
    <div class="d-flex mb-4">
      <h1 class="mr-8">
        Accounts
        <span
          v-if="!loading.accounts || accounts.length > 0"
          class="font-weight-regular"
          >({{ accounts.length }})</span
        >
      </h1>

      <v-menu transition="slide-y-transition" bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" elevation="0" color="primary"
            >Add account <v-icon class="ml-4">mdi-chevron-down</v-icon></v-btn
          >
        </template>
        <v-list>
          <v-list-item link @click="Facebook">
            <v-list-item-icon
              ><v-img
                height="30"
                contain
                width="30"
                src="@/assets/facebook.png"
              ></v-img
            ></v-list-item-icon>
            <v-list-item-title>Facebook</v-list-item-title>
          </v-list-item>

          <v-list-item link @click="instagram">
            <v-list-item-icon
              ><v-img
                height="30"
                contain
                width="30"
                src="@/assets/instagram.png"
              ></v-img
            ></v-list-item-icon>
            <v-list-item-title>Instagram</v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-icon
              ><v-img
                height="30"
                contain
                width="30"
                src="@/assets/linkedin.png"
              ></v-img
            ></v-list-item-icon>
            <v-list-item-title>Linkedin</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div v-if="!$store.state.loading.accounts">
      <v-alert
        prominent
        type="error"
        v-for="a in accounts.filter((acc) => acc.access_token_valid === false)"
        :key="a.id"
      >
        <v-row align="center">
          <v-col class="grow">
            Permissions for your {{ a.type }} account '{{ a.name }}' have been
            revoked.
          </v-col>
          <v-col class="shrink d-flex" style="gap: 10px">
            <v-btn
              v-if="a.type === 'facebook' || a.type === 'instagram'"
              elevation="0"
              @click="Facebook"
              >Re-authorize</v-btn
            >
            <v-btn outlined @click="deleteAccount(a.id)">Delete account</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <div
      v-if="!$store.state.loading.accounts && accounts.length > 0"
      class="accounts-list"
    >
      <div
        class="d-flex flex-column"
        :class="{ mobile: $vuetify.breakpoint.smAndDown }"
      >
        <h2 class="mb-2">Facebook</h2>
        <div class="accounts">
          <div
            v-for="a in accounts.filter((a) => a.type === 'facebook')"
            :key="a.id"
          >
            <AccountCard :a="a" @facebook="Facebook" />
          </div>
        </div>
      </div>

      <div class="insta" :class="{ mobile: $vuetify.breakpoint.smAndDown }">
        <h2 class="mb-2">Instagram</h2>
        <div class="accounts">
          <div
            v-for="a in accounts.filter((a) => a.type === 'instagram')"
            :key="a.id"
          >
            <AccountCard :a="a" @instagram="instagram" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="$store.state.loading.accounts && !$store.state.loading.general"
    >
      <v-progress-circular
        indeterminate
        size="30"
        color="primary"
        style="z-index: 99"
      ></v-progress-circular>
    </div>

    <p
      v-else-if="
        !$store.state.loading.accounts &&
        !loading &&
        accounts &&
        accounts.length === 0
      "
      style="opacity: 0.6; margin-top: 20px"
    >
      No accounts have been setup yet. Click the button 'Add account' to start!
    </p>
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import { mapState } from "vuex";
import AccountCard from "@/components/AccountCard.vue";
// import ImportPosts from "@/components/importPosts/importPosts.vue";

// TODO Revoke should revoke the page permissions via an api call, then remove the account from database. Now user has to manually revoke it.
// TODO For this you need an app token. Store this in environment variables.

export default {
  components: {
    AccountCard,
    // ImportPosts,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading.general,
      accounts: (state) => state.accounts,
    }),
    instagram_accounts() {
      let list = this.accounts;
      list = list.filter((a) => a.type === "instagram");
      return list;
    },
    facebook_accounts() {
      let list = this.accounts;
      list = list.filter((a) => a.type === "facebook");
      return list;
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.$route.query.edit) {
      let a = this.accounts.find((a) => a.id === this.$route.query.edit);
      if (a.type === "facebook" || a.type === "instagram") {
        this.Facebook();
      }
      //TO DO: add actions for other socials
    }
  },
  methods: {
    deleteAccount(id) {
      let workspace = this.userProfile.workspace;
      const functions = getFunctions();
      const deleteAccount = httpsCallable(functions, "deleteAccount");
      deleteAccount({
        id: id,
        workspace: workspace,
      });
    },
    instagram() {
      const vm = this;
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            console.log(response);
            vm.instagramAccess();
          });
        } else {
          console.log(response);
          vm.instagramAccess();
        }
      });
    },
    instagramAccess() {
      const vm = this;
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            console.log(response);

            // update facebook access profiles
            const functions = getFunctions();
            const updateFacebookAccess = httpsCallable(
              functions,
              "updateFacebookAccess"
            );
            updateFacebookAccess({
              id: response.authResponse.userID,
              access_token: response.authResponse.accessToken,
            });
            vm.$store.dispatch("fetchAccounts");
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          scope: " instagram_basic, instagram_content_publish",
          enable_profile_selector: true,
        }
      );
    },
    Facebook() {
      const vm = this;
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            console.log(response);
            vm.FacebookAccess();
          });
        } else {
          console.log(response);
          vm.FacebookAccess();
        }
      });
    },
    FacebookAccess() {
      const vm = this;
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            console.log(response);
            // update facebook access profiles
            const functions = getFunctions();
            const updateFacebookAccess = httpsCallable(
              functions,
              "updateFacebookAccess"
            );
            updateFacebookAccess({
              id: response.authResponse.userID,
              access_token: response.authResponse.accessToken,
            });
            vm.$store.dispatch("fetchAccounts");
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          scope:
            "public_profile,email,pages_show_list,pages_manage_posts, pages_manage_engagement,pages_manage_metadata, pages_messaging, pages_read_engagement, read_insights",
          enable_profile_selector: true,
        }
      );
    },
    async status() {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          console.log(response.authResponse);
        }
      });
    },
    logout() {
      window.FB.logout(function (response) {
        console.log(response);
      });
    },
    async longTermToken() {},
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
