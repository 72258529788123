<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="admin_workspaces"
      :items-per-page="-1"
      class="smoke"
      hide-default-footer
      :search="search"
      :loading="loading.admin.workspaces"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="pa-4"
          solo
          flat
          style="border-radius: 6px"
          hide-details=""
        ></v-text-field>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="smoke lighten-1" elevation="0" @click="openInFb(item.uid)"
          ><v-icon>mdi-firebase</v-icon></v-btn
        >
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip small>{{ item.type }}</v-chip>
      </template>

      <template v-slot:[`item.tokens`]="{ item }">
        <v-text-field
          v-model.number="item.counter.tokens"
          solo
          flat
          background-color="white"
          style="border-radius: 6px; max-width: 120px"
          hide-details=""
          dense
          type="number"
          @change="updateTokens(item.uid, item.counter.tokens)"
        ></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapState } from "vuex";
import { db } from "@/firebase";
import { doc, updateDoc } from "firebase/firestore";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      admin_users: (state) => state.admin.users,
      admin_workspaces: (state) => state.admin.workspaces,
    }),
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          value: "about.name",
        },
        {
          text: "Type",
          align: "start",
          value: "type",
        },
        {
          text: "Members",
          align: "start",
          value: "members.length",
        },
        {
          text: "Tokens",
          align: "start",
          value: "tokens",
        },
        {
          text: "Timezone",
          align: "start",
          value: "timezone",
        },
        {
          text: "",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    openInFb(uid) {
      let production = window.location.origin.includes("app.stix.social");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "one-startup-stack" : "stix-staging-f240f"
      }/firestore/data/~2Fworkspaces~2F${uid}`;
      window.open(url, "_blank");
    },
    async updateTokens(uid, tokens) {
      const ref = doc(db, "workspaces", uid);
      await updateDoc(ref, {
        "counter.tokens": tokens,
      });
      this.$store.commit("setNotification", {
        show: true,
        text: "Tokens updated",
        type: "primary",
        icon: "",
      });
    },
  },
};
</script>
