<template>
  <div>
    <v-card
      flat
      :style="{
        borderBottom: '2px solid #272727',
        borderLeft: '0.75px solid #272727',
        borderTop: '0.75px solid #272727',
        borderRight: '2px solid #272727',
      }"
      v-if="!post.post"
    >
      <v-sheet
        style="
          font-size: 9pt;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        "
        class="text-center py-1"
        :color="categories.find((c) => c.uid === post.category).color"
        :style="{
          color:
            categories.find((c) => c.uid === post.category).color === '#272727'
              ? 'white'
              : '#272727',
        }"
      >
        Scheduled
      </v-sheet>
      <v-divider class="primary"></v-divider>
      <div class="pa-3" style="font-size: 10pt">
        This slot for
        <b>{{ categories.find((c) => c.uid === post.category).name }}</b> will
        be filled automatically.
      </div>
    </v-card>

    <v-card
      flat
      :style="{
        borderBottom: '2px solid #272727',
        borderLeft: '0.75px solid #272727',
        borderTop: '0.75px solid #272727',
        borderRight: '2px solid #272727',
      }"
      v-if="post.post"
    >
      <div v-if="post.schedule || post.published || post.unix">
        <v-sheet
          v-if="post.published"
          style="
            font-size: 9pt;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            color: #272727;
          "
          class="text-center py-1 d-flex align-center justify-center"
          color="success"
        >
          <v-icon color="primary" small class="mr-1">mdi-check-circle</v-icon>
          {{ published_at }}
        </v-sheet>

        <v-sheet
          v-else-if="post.schedule || post.unix"
          style="
            font-size: 9pt;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
          "
          class="text-center py-1 d-flex align-center justify-center"
          :color="categories.find((c) => c.uid === post.post.category).color"
          :style="{
            color:
              categories.find((c) => c.uid === post.post.category).color ===
              '#272727'
                ? 'white'
                : '#272727',
          }"
        >
          <v-icon color="primary" small class="mr-1">mdi-clock</v-icon>
          {{ scheduled_at }}
        </v-sheet>

        <v-divider class="primary"></v-divider>
      </div>
      <!-- <v-chip color="success" small v-if="published"
        ><v-icon small class="mr-1">mdi-check</v-icon>Published
        {{ getTime(post.time) }}</v-chip
      > -->

      <div class="d-flex align-center pa-3" style="gap: 10px" v-if="post.post">
        <v-badge overlap color="accent" left avatar>
          <v-avatar size="30">
            <img
              :src="post.post.accounts[0].profile_picture"
              :alt="post.post.accounts[0].name"
          /></v-avatar>
          <template v-slot:badge>
            <v-img
              style="border: 0.5px solid #fff; border-radius: 50px"
              height="20"
              width="20"
              :src="require(`@/assets/${post.post.accounts[0].type}.png`)"
            ></v-img>
          </template>
        </v-badge>

        <p
          class="mb-0"
          style="font-size: 9pt; font-weight: 600"
          v-if="post.post.accounts.length > 1"
        >
          +{{ post.post.accounts.length - 1 }}
        </p>

        <v-spacer></v-spacer>
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon small
              ><v-icon small>mdi-dots-vertical</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item
              link
              class="px-4"
              @click="$router.push(`/post?id=${post.post.uid}`)"
            >
              <v-icon small color="primary" class="mr-3">mdi-pencil</v-icon>
              <v-list-item-title>Edit </v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              class="px-4"
              @click="$store.dispatch('setPost', post.post.uid)"
            >
              <v-icon small color="primary" class="mr-3">mdi-eye</v-icon>
              <v-list-item-title>Preview </v-list-item-title>
            </v-list-item>
            <v-list-item link class="px-4">
              <v-icon small color="primary" class="mr-3">mdi-forward</v-icon>
              <v-list-item-title>Skip this post</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="userProfile.superadmin"
              link
              class="px-4"
              @click="openInFb(workspace.uid, post.post.uid)"
            >
              <v-icon small color="primary" class="mr-3">mdi-firebase</v-icon>
              <v-list-item-title>Firebase </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div
        v-if="
          (post.post && post.post.link_preview) ||
          (post.post && post.post.media)
        "
        class="mb-3"
      >
        <img
          style="max-width: 100%; object-fit: contain"
          :src="
            post.post.link_preview
              ? post.post.link_preview.image
              : post.post.media.url
          "
        />
      </div>

      <div class="px-3 pb-3" v-if="post.post">
        <p
          class="mb-0"
          :class="{
            'line-clamp-short': post.post.media || post.post.link_preview,
            'line-clamp': !post.post.media && !post.post.link_preview,
          }"
          style="font-size: 14px"
        >
          {{ post.post.variation.text }}
        </p>
      </div>
      <v-divider></v-divider>
      <div class="pa-3 d-flex align-center flex-wrap" style="gap: 5px">
        <v-chip
          small
          label
          :color="categories.find((c) => c.uid === post.post.category).color"
          class="primary--text font-weight-medium"
          ><span
            :style="{
              color:
                categories.find((c) => c.uid === post.post.category).color ===
                '#272727'
                  ? 'white'
                  : '#272727',
            }"
            >{{
              categories.find((c) => c.uid === post.post.category).name
            }}</span
          ></v-chip
        >
        <v-spacer></v-spacer>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";

export default {
  props: {
    calendar_uid: {
      type: String,
      default: null,
    },
    post: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      categories: (state) => state.posts.categories,
      workspace: (state) => state.workspace.workspace,
    }),
    published_at() {
      let time = null;
      if (this.post.published_date) {
        time = DateTime.fromSeconds(this.post.published_date).setZone(
          this.workspace.timezone
        );
      }
      return time ? time.toFormat("HH:mm") : null;
    },
    scheduled_at() {
      let time = null;
      if (this.post.unix) {
        time = DateTime.fromSeconds(this.post.unix).setZone(
          this.workspace.timezone
        );
      }
      return time ? time.toFormat("HH:mm") : null;
    },
  },
  data() {
    return {};
  },
  methods: {
    openInFb(ws, post) {
      let production = window.location.origin.includes("app.stix.social");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "one-startup-stack" : "stix-staging-f240f"
      }/firestore/data/~2Fworkspaces~2F${ws}~2Fposts~2F${post}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-short {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
