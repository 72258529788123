<template>
  <div>
    <v-card flat :outlined="outlined">
      <div class="preview_top pa-4">
        <div class="top_left d-flex align-center">
          <v-avatar size="40" class="mr-2" color="smoke">
            <v-img :src="account.profile_picture"></v-img>
          </v-avatar>
          <div class="d-flex flex-column justify-center">
            <span
              class="mb-0"
              style="font-size: 15px; color: #050505; font-weight: 600"
            >
              {{ account ? account.name : "" }}
            </span>
            <p class="mb-0" style="font-size: 13px">
              {{ formatTime(post.timestamp) }}
            </p>
          </div>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-1"
                v-bind="attrs"
                v-on="on"
                elevation="0"
                color="primary"
                ><v-icon small>mdi-dots-vertical</v-icon></v-btn
              >
            </template>

            <v-card>
              <v-list>
                <v-list-item link @click="openPost">
                  Open in Instagram
                </v-list-item>
                <v-list-item link @click="importPost">
                  Import post
                </v-list-item>
                <v-list-item link> Save struture </v-list-item>
                <v-list-item link> Delete post </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </div>

      <div v-if="post && post.media_url">
        <p class="px-4 mb-1 text-end" style="font-size: 11px; opacity: 0.6">
          <i>Image can look different on Instagram</i>
        </p>
        <v-img :src="post.media_url" :aspect-ratio="1.91 / 1">
          <div
            v-if="delete_image"
            style="height: 100%"
            class="pa-2 d-flex justify-end align-end"
          >
            <v-btn
              class="image-delete"
              icon
              color="primary"
              @click="$emit('delete_image')"
              ><v-icon size="20">mdi-delete</v-icon></v-btn
            >
          </div>
        </v-img>
      </div>

      <div class="d-flex px-4 mb-2 pt-4" style="gap: 7px">
        <v-icon color="primary">mdi-heart-outline</v-icon>
        <v-icon color="primary">mdi-chat-outline</v-icon>
        <v-icon color="primary">mdi-send-outline</v-icon>
        <v-spacer></v-spacer>
        <v-icon color="primary">mdi-bookmark-outline</v-icon>
      </div>
      <div class="px-4 pb-4">
        <p class="mb-1" style="font-size: 12px; font-weight: 600">
          {{ post.like_count }} Likes
        </p>
        <p class="mb-0" style="font-size: 14px">
          <span style="font-weight: 600" class="mr-2">{{ account.name }}</span>
          <span style="">{{ post.caption }}</span>
        </p>
      </div>
      <v-divider></v-divider>
      <div v-if="comment" class="pa-4 d-flex">
        <div class="mr-1">
          <v-avatar size="30" class="mr-2" color="smoke">
            <v-img :src="account.profile_picture"></v-img>
          </v-avatar>
        </div>
        <div class="d-flex flex-column">
          <span style="font-weight: 600; font-size: 10pt" class="mr-2">{{
            account.name
          }}</span>

          <span style="font-weight: 500; font-size: 10pt" class="mr-2">{{
            comment
          }}</span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { getFunctions, httpsCallable } from "firebase/functions";
import { DateTime } from "luxon";
import { mapState } from "vuex";
const functions = getFunctions();
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db } from "@/firebase";
import {
  collection,
  arrayUnion,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

export default {
  props: [
    "account",
    "post",
    "outlined",
    "open_link",
    "delete_image",
    "comment",
  ],
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
    }),
  },
  methods: {
    async importPost() {
      let time = DateTime.fromISO(this.post.timestamp);
      let original = await fetch(this.post.media_url);
      let upload = await original.blob();
      upload.name = this.post.id;
      console.log(upload);
      var fileExtension = upload.type.split("/")[1];
      var fileName = upload.name + "." + fileExtension;
      const filesize = Math.floor(upload.size / 1024);

      function isType(fileExtension) {
        switch (fileExtension.toLowerCase()) {
          case "m4v":
            return "video";
          case "avi":
            return "video";
          case "mpg":
            return "video";
          case "mp4":
            return "video";
          case "jpg":
            return "image";
          case "gif":
            return "image";
          case "bmp":
            return "image";
          case "png":
            return "image";
          case "jpeg":
            return "image";
        }
        return null;
      }

      const storage = getStorage();

      const metadata = {
        contentType: upload.type,
      };
      const media = {
        title: fileName,
        extension: fileExtension,
        class: "media",
        created_at: new Date(),
        created_by: this.userProfile.uid,
        created_by_email: this.userProfile.email,
        workspace: this.userProfile.workspace,
        size: filesize,
        posts: [],
        templates: [],
        tags: [],
        type: isType(fileExtension),
      };
      const docRef = await addDoc(
        collection(db, "workspaces", this.userProfile.workspace, "media"),
        media
      );
      const storageRef = ref(
        storage,
        `${this.userProfile.workspace}/media/${docRef.id}.${fileExtension}`
      );

      await uploadBytesResumable(storageRef, upload, metadata);

      const downloadURL = await getDownloadURL(storageRef);
      const updateRef = doc(
        db,
        "workspaces",
        this.userProfile.workspace,
        "media",
        docRef.id
      );

      await updateDoc(updateRef, {
        uid: docRef.id,
        url: downloadURL,
      });

      this.$store.dispatch("fetchMedia");

      let post = {
        accounts: [this.account.id],
        category: "",
        created: new Date(),
        created_by: this.userProfile.uid,
        created_by_email: this.userProfile.email,
        recurring: null,
        scheduled: false,
        schedule_date: null,
        last_publish: time.toSeconds(),
        status: "draft",
        media: { url: downloadURL, uid: docRef.id, ...media },
        link_preview: null,
        comment: "",
        workspace: this.userProfile.workspace,
        variations: [
          {
            text: this.post.caption,
            type: "main",
          },
        ],
        uid: null,
        timezone: this.workspace.timezone,
      };

      console.log(post);
      const addPost = httpsCallable(functions, "addPost");
      const res = await addPost(post);
      console.log(res);

      await updateDoc(
        doc(db, "workspaces", this.userProfile.workspace, "media", docRef.id),
        {
          posts: arrayUnion(res.data),
        }
      );

      this.$store.commit("setNotification", {
        show: true,
        text: "Your post has been added!",
        type: "primary",
        redirect: `/post?id=${res.data}`,
        action_text: "Open",
      });
    },
    openPost() {
      window.open(this.post.permalink, "_blank");
    },
    formatTime(t) {
      return moment(t).format("DD MMMM YYYY");
    },
    async uploadFiles(url) {
      let upload = fetch(url);

      console.log(upload);
      var fileName = upload.name;
      var fileExtension = fileName.split(".").pop();
      const filesize = Math.floor(upload.size / 1024);

      function isType(fileExtension) {
        switch (fileExtension.toLowerCase()) {
          case "m4v":
            return "video";
          case "avi":
            return "video";
          case "mpg":
            return "video";
          case "mp4":
            return "video";
          case "jpg":
            return "image";
          case "gif":
            return "image";
          case "bmp":
            return "image";
          case "png":
            return "image";
          case "jpeg":
            return "image";
        }
        return null;
      }

      const storage = getStorage();

      const metadata = {
        contentType: upload.type,
      };
      const docRef = await addDoc(
        collection(db, "workspaces", this.userProfile.workspace, "media"),
        {
          title: fileName,
          extension: fileExtension,
          class: "media",
          created_at: new Date(),
          created_by: this.userProfile.uid,
          created_by_email: this.userProfile.email,
          workspace: this.userProfile.workspace,
          size: filesize,
          posts: [],
          templates: [],
          tags: [],
          type: isType(fileExtension),
        }
      );
      const storageRef = ref(
        storage,
        `${this.userProfile.workspace}/media/${docRef.id}.${fileExtension}`
      );

      await uploadBytesResumable(storageRef, upload, metadata);

      const downloadURL = await getDownloadURL(storageRef);
      const updateRef = doc(
        db,
        "workspaces",
        this.userProfile.workspace,
        "media",
        docRef.id
      );

      await updateDoc(updateRef, {
        uid: docRef.id,
        url: downloadURL,
      });

      this.$store.commit("setNotification", {
        show: true,
        text: "Media added!",
        type: "primary",
        icon: "",
      });

      this.$store.dispatch("fetchMedia");
      this.search_loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-delete {
  background-color: rgba($color: #fff, $alpha: 0.4);
}
</style>
