import { db } from "@/firebase";
import { getDoc, doc } from "firebase/firestore";

const template = {
  state: {
    align_items: null,
    aspect_ratio: {
      text: "Original",
      value: null,
    },
    background: {
      type: "",
      uid: "",
      url: "",
    },
    blocks: [],
    created_at: null,
    created_by: null,
    created_by_email: null,
    justify_content: null,
    loading: false,
    name: null,
    padding: {
      x: 0,
      y: 0,
    },
    tags: [],
    thumbnail: null,
    uid: null,
    editor: false,
  },
  mutations: {
    setTemplateAspectRatio(state, val) {
      state.aspect_ratio = val;
    },
    setTemplateEditor(state, val) {
      state.editor = val;
    },
    setTemplateName(state, val) {
      state.name = val;
    },
    setTemplatePadding(state, val) {
      state.padding = val;
    },
    setTemplateBlocks(state, val) {
      state.blocks = val;
    },
    setTemplateAlignItems(state, val) {
      state.align_items = val;
    },
    setTemplateTags(state, val) {
      state.tags = val;
    },
    setTemplateCreatedAt(state, val) {
      state.created_at = val;
    },
    setTemplateCreatedBy(state, val) {
      state.created_by = val;
    },
    setTemplateCreatedByEmail(state, val) {
      state.created_by_email = val;
    },
    setTemplateJustifyContent(state, val) {
      state.justify_content = val;
    },
    setTemplateUid(state, val) {
      state.uid = val;
    },
    setTemplateBackground(state, val) {
      state.background = val;
    },
    setTemplateThumbnail(state, val) {
      state.thumbnail = val;
    },
    setTemplateLoading(state, val) {
      state.loading = val;
    },
    setTemplateBlock(state, val) {
      state.blocks[val.index].media = val;
    },
    clearTemplate(state) {
      state.align_items = null;
      state.aspect_ratio = {
        text: "Original",
        value: null,
      };
      state.background = {
        type: "",
        uid: "",
        url: "",
      };
      state.blocks = [];
      state.created_at = null;
      state.created_by = null;
      state.created_by_email = null;
      state.justify_content = null;
      state.padding = {
        x: 0,
        y: 0,
      };
      state.tags = [];
      state.thumbnail = null;
      state.editor = false;
      state.uid = null;
      state.name = null;
    },
  },
  actions: {
    async setTemplate({ commit, rootState }, val) {
      commit("setTemplateLoading", true);
      const t = await getDoc(
        doc(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "templates",
          val
        )
      );
      const template = t.data();

      commit("setTemplateAlignItems", template.align_items);
      commit("setTemplateAspectRatio", template.aspect_ratio);
      commit("setTemplateCreatedBy", template.created_by);
      commit("setTemplateCreatedByEmail", template.created_by_email);
      commit("setTemplateBlocks", template.blocks);
      commit("setTemplateCreatedAt", template.created_at);
      commit("setTemplateJustifyContent", template.justify_content);
      commit("setTemplateName", template.name);
      commit("setTemplatePadding", template.padding);
      commit("setTemplateTags", template.tags);
      commit("setTemplateThumbnail", template.thumbnail);
      commit("setTemplateUid", template.uid);
      commit("setTemplateEditor", true);

      if (template.background.type === "uid") {
        const d = await getDoc(
          doc(
            db,
            "workspaces",
            rootState.users.userProfile.workspace,
            "media",
            template.background.uid
          )
        );
        commit("setTemplateBackground", {
          type: "uid",
          uid: template.background.uid,
          url: d.data().url,
          title: d.data().title,
        });
      } else {
        commit("setTemplateBackground", template.background);
      }
      commit("setTemplateLoading", false);
    },
  },
  getters: {},
};

export default template;
