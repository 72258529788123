<template>
  <v-app>
    <NotificationDesktop />
    <FeedbackForm />
    <OpenAIDesktop />
    <Queue />
    <Media />
    <Preview />
    <Topics />
    <Loading />
    <NavigationDrawer v-if="!$vuetify.breakpoint.smAndDown" />
    <AppBar @queue_drawer="queue_drawer = true" v-if="!$route.meta.noNav" />
    <v-main style="background-color: #f0f0f0">
      <transition name="slide-fade" mode="out-in">
        <router-view
          :class="{
            mobile:
              $vuetify.breakpoint.smAndDown &&
              $route.path != '/inbox' &&
              $route.path != '/access' &&
              $route.path != '/verify' &&
              $route.path != '/reset',
            desktop:
              !$vuetify.breakpoint.smAndDown &&
              $route.path != '/access' &&
              $route.path != '/calendar' &&
              $route.path != '/feed' &&
              $route.path != '/chat' &&
              $route.path != '/join' &&
              $route.path != '/create-workspace' &&
              $route.path != '/verify' &&
              $route.path != '/reset' &&
              !$route.path.includes('/library') &&
              !$route.path.includes('/settings') &&
              !$route.path.includes('/admin'),
          }"
        />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { getFunctions, httpsCallable } from "firebase/functions";
import "animate.css";
import FeedbackForm from "@/components/FeedbackForm.vue";
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import AppBar from "@/components/AppBar.vue";
import NotificationDesktop from "@/components/notifications/NotificationDesktop.vue";
import OpenAIDesktop from "@/components/openai/OpenaiDesktop.vue";
import Media from "@/components/media/BrowserDesktop.vue";
import Queue from "@/components/queue/DrawerDesktop.vue";
import Preview from "@/components/preview/PreviewDialog.vue";
import Topics from "@/components/TopicsDrawer.vue";
import Loading from "@/components/LoadingCard.vue";

export default {
  name: "App",
  components: {
    Preview,
    NavigationDrawer,
    AppBar,
    FeedbackForm,
    Queue,
    NotificationDesktop,
    Media,
    OpenAIDesktop,
    Topics,
    Loading,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
    }),
  },
  data: () => ({
    drawer: false,
    queue_drawer: false,
    user_id: null,
    access_token: "",
  }),
  methods: {
    logout() {
      window.FB.logout(function (response) {
        console.log(response);
      });
    },
    async page_token() {
      const functions = getFunctions();
      const page_token = httpsCallable(functions, "page_token");
      const res = await page_token({
        id: this.$store.state.social.user_id,
        access_token: this.$store.state.social.access_token,
      });
      this.$store.commit("setPageTokens", res.data.data);
    },
    async post() {
      const functions = getFunctions();
      const post = httpsCallable(functions, "post");
      const res = await post({
        message: "this is a test",
        method: "POST",
        page_id: "264654333561511",
        access_token: this.$store.state.social.page_tokens[0].access_token,
      });

      console.log(res.data);
    },
    async status() {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          console.log(response.authResponse);
        }
      });
    },
    test() {
      const vm = this;
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            vm.$store.commit("setUserId", response.authResponse.userID);
            vm.$store.commit(
              "setAccessToken",
              response.authResponse.accessToken
            );

            console.log("Welcome!  Fetching your information.... ");
            window.FB.api("/me", function (response) {
              console.log("Good to see you, " + response.name + ".");
            });
            window.FB.api("/me/permissions", function (response) {
              console.log(JSON.stringify(response));
            });
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "public_profile,email" }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.animate__animated.animate__slideInDown {
  --animate-duration: 0.5s;
}
.animate__animated.animate__slideInLeft {
  --animate-duration: 0.5s;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.desktop {
  padding: 20px 44px 0 44px;
  max-width: 1800px;
}
.mobile {
  padding: 30px 44px;
  margin: 0 auto;
}
</style>

<style lang="scss">
.v-input--switch__thumb {
  border-bottom: 2px solid #272727;
  border-left: 1px solid #272727;
  border-top: 1px solid #272727;
  border-right: 2px solid #272727;
}

.v-menu__content {
  border-bottom: 3px solid #272727;
  border-left: 1px solid #272727;
  border-top: 1px solid #272727;
  border-right: 3px solid #272727;
  border-radius: 6px;
}

.myClass:focus::before {
  opacity: 0 !important;
}
</style>
