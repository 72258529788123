import { db } from "@/firebase";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";

const post = {
  state: {
    askChatDrawer: false,
    category: null,
    chat: [
      {
        role: "system",
        content: "You are the best social media post writer in the world.",
      },
    ],
    comment: "",
    instructions: "",
    link_preview: null,
    media: null,
    post: null,
    preview_dialog: false,
    recurring: null,
    schedule_date: null,
    selected_accounts: [],
    show_comment: false,
    status: "draft",
    structure: null,
    topic: "",
    variation: null,
    variations: [
      {
        text: "",
        type: "main",
      },
    ],
  },
  mutations: {
    clearPost(state) {
      state.category = null;
      state.chat = [
        {
          role: "system",
          content: "You are the best social media post writer in the world.",
        },
      ];
      state.comment = "";
      state.instructions = "";
      state.link_preview = null;
      state.media = null;
      state.post = null;
      state.recurring = null;
      state.schedule_date = null;
      state.selected_accounts = [];
      state.show_comment = false;

      state.status = "draft";
      state.structure = null;
      state.topic = "";
      state.variations = [
        {
          text: "",
          type: "main",
        },
      ];
    },
    setPostTopic(state, val) {
      state.topic = val;
    },
    setPostMedia(state, val) {
      if (state.link_preview) {
        state.link_preview = null;
      }
      state.media = val;
    },
    setPostInstructions(state, val) {
      state.instructions = val;
    },
    setPostCategory(state, val) {
      state.category = val;
    },
    setPostSelectedAccounts(state, val) {
      state.selected_accounts = val;
    },
    setPostComment(state, val) {
      state.comment = val;
    },
    setPostPreviewDialog(state, val) {
      state.preview_dialog = val;
      if (!val) {
        this.commit("clearPost");
      }
    },
    setPostShowComment(state, val) {
      state.show_comment = val;
    },
    setPostVariations(state, val) {
      state.variations = val;
    },
    setPostRecurring(state, val) {
      state.recurring = val;
    },
    setPostChat(state, val) {
      state.chat = val;
    },
    setPostStructure(state, val) {
      state.structure = val;
    },
    setPostLink(state, val) {
      state.link = val;
    },
    setPostLinkPreview(state, val) {
      if (state.media) {
        state.media = null;
      }
      state.link_preview = val;
    },
    setPostUid(state, val) {
      state.post = val;
    },
    setPostScheduleDate(state, val) {
      state.schedule_date = val;
    },
    setPostStatus(state, val) {
      state.status = val;
    },
    setPostAskChatDrawer(state, val) {
      state.askChatDrawer = val;
    },
  },
  actions: {
    async setPost({ commit, rootState }, uid) {
      commit("clearPost");
      commit("setPostUid", uid);

      const docRef = doc(
        db,
        "workspaces",
        rootState.users.userProfile.workspace,
        "posts",
        uid
      );
      const docSnap = await getDoc(docRef);
      let p = docSnap.data();

      p.variations = [];
      const messages = await getDocs(
        collection(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "posts",
          uid,
          "variations"
        )
      );
      messages.forEach((d) => {
        p.variations.push(d.data());
      });

      let category = p.category
        ? rootState.posts.categories.find((c) => c.uid === p.category)
        : null;
      commit("setPostCategory", category);

      let accounts = [];
      for (let x in p.accounts) {
        const docSnap = await getDoc(
          doc(
            db,
            "workspaces",
            rootState.users.userProfile.workspace,
            "accounts",
            p.accounts[x]
          )
        );

        if (docSnap.exists()) {
          accounts.push(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
      commit("setPostSelectedAccounts", accounts);

      // TO DO: if has link, then update the link preview.
      commit("setPostVariations", p.variations);
      // this.message = p.message;
      commit("setPostComment", p.comment);

      if (p.media) {
        commit("setPostMedia", p.media);
      }

      if (p.link_preview) {
        commit("setPostLinkPreview", p.link_preview);
      }
      commit("setPostRecurring", p.recurring);
      commit("setPostScheduleDate", p.schedule_date);
      commit("setPostStatus", p.status);
      commit("setPostPreviewDialog", true);
    },
  },
  getters: {},
};

export default post;
