<template>
  <v-list nav dark>
    <v-menu v-model="menu" :nudge-width="200" offset-x right open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
          class="primary py-2 lighten-1"
          link
          v-bind="attrs"
          v-on="on"
        >
          <v-img height="30" contain src="@/assets/logo/logo-white.svg"></v-img>
        </v-list-item>
      </template>

      <v-card>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="font-size: 10pt; font-weight: 600"
        >
          Workspaces
        </v-sheet>
        <v-divider class="primary"></v-divider>
        <v-list>
          <v-list-item
            @click="select(w)"
            link
            v-for="w in workspaces"
            :key="w.uid"
          >
            <v-list-item-avatar v-if="w.avatar">
              <img :src="w.avatar" :alt="w.name" width="15" height="15" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title style="font-size: 14px; font-weight: 500">{{
                w.about.name
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                small
                v-if="workspace.uid === w.uid"
                color="success darken-1"
                >mdi-check-circle</v-icon
              >
            </v-list-item-action>
          </v-list-item>

          <v-divider class="mt-2"></v-divider>
          <div class="px-2">
            <v-btn
              @click="$router.push('/create-workspace')"
              class="mt-2"
              block
              text
              small
              ><v-icon small class="mr-1">mdi-plus</v-icon>Create new
              workspace</v-btn
            >
          </div>
        </v-list>

        <v-divider></v-divider>
      </v-card>
    </v-menu>
  </v-list>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      workspaces: (state) => state.workspace.workspaces,
    }),
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    select(v) {
      if (v.uid != this.workspace.uid) {
        this.$store.dispatch("changeWorkspace", v.uid);
      }
    },
  },
};
</script>
