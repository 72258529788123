<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="admin_emails"
      :items-per-page="-1"
      class="smoke"
      hide-default-footer
      :search="search"
      height="90vh"
      :loading="loading.admin.emails"
      sort-by="delivery.endTime"
      sort-desc=""
    >
      <template v-slot:top>
        <div class="pa-4 d-flex align-center" style="gap: 10px">
          <v-text-field
            v-model="search"
            label="Search"
            solo
            flat
            style="border-radius: 6px"
            hide-details=""
          ></v-text-field>
          <v-btn
            height="48"
            :loading="loading.admin.emails"
            elevation="0"
            color="primary"
            @click="$store.dispatch('fetchAdminEmails')"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </div>
      </template>

      <template v-slot:[`item.delivery.endTime`]="{ item }">
        <span v-if="item.delivery">{{
          formatDate(item.delivery.endTime)
        }}</span>
      </template>

      <template v-slot:[`item.to`]="{ item }">
        <span>{{ item.to }}</span>
        <span>{{ item.bcc }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="smoke lighten-1" elevation="0" @click="openInFb(item.uid)"
          ><v-icon>mdi-firebase</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      admin_emails: (state) => state.admin.emails,
    }),
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "To", value: "to" },
        { text: "Template", value: "template.name" },
        { text: "Date", value: "delivery.endTime" },
        { text: "Status", value: "delivery.state" },
        { text: "", value: "actions" },
      ],
      filter_noposts: false,
    };
  },
  methods: {
    formatDate(d) {
      let date = d.toDate();
      return moment(date).format("DD-MM-YYYY - hh:mm");
    },
    openInFb(uid) {
      let production = window.location.origin.includes("app.stix.social");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "one-startup-stack" : "stix-staging-f240f"
      }/firestore/data/~2Fmail~2F${uid}`;
      window.open(url, "_blank");
    },
  },
};
</script>
