<template>
  <v-card
    flat
    :style="{
      borderBottom: '3px solid #272727',
      borderLeft: '1px solid #272727',
      borderTop: '1px solid #272727',
      borderRight: '3px solid #272727',
    }"
    width="300"
    height="345"
    :id="`post-${p.uid}`"
  >
    <div class="d-flex align-center pa-3" style="gap: 10px">
      <v-avatar size="24">
        <v-img src="@/assets/max.jpeg"></v-img>
      </v-avatar>
      <p class="mb-0 cap" style="font-size: 11px">{{ p.created_by_email }}</p>

      <v-spacer></v-spacer>

      <v-icon v-if="p.recurring" color="success darken-1" small
        >mdi-leaf</v-icon
      >
      <v-icon v-if="!p.recurring && p.recurring != null" color="primary" small
        >mdi-numeric-1-circle-outline</v-icon
      >
      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon small
            ><v-icon small>mdi-dots-vertical</v-icon></v-btn
          >
        </template>
        <v-list>
          <v-list-item
            link
            class="px-4"
            @click="$router.push(`/post?id=${p.uid}`)"
          >
            <v-icon small color="primary" class="mr-3">mdi-pencil</v-icon>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            class="px-4"
            @click="$store.dispatch('setPost', p.uid)"
          >
            <v-icon small color="primary" class="mr-3">mdi-eye</v-icon>
            <v-list-item-title>Preview</v-list-item-title>
          </v-list-item>

          <v-list-item link class="px-4" @click="deletePost">
            <v-icon small color="primary" class="mr-3">mdi-delete</v-icon>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div v-if="p.link_preview || p.media">
      <v-img
        height="100"
        :aspect-ratio="16 / 9"
        :src="p.link_preview ? p.link_preview.image : p.media.url"
      >
        <v-btn
          @click="openLink(p.link_preview.url)"
          v-if="p.link_preview"
          rounded
          color="smoke"
          small
          style="opacity: 0.8"
          absolute
          bottom
          left
          class="font-weight-regular"
          elevation="0"
          ><v-icon small class="mr-2">mdi-link</v-icon
          ><span
            style="
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 260px;
            "
            >{{ p.link_preview.url }}</span
          ></v-btn
        ></v-img
      >
    </div>

    <div
      class="pa-3"
      :style="{
        height: p.media || p.link_preview ? '90px' : '240px',
      }"
    >
      <p
        class="mb-0 line-clamp"
        style="font-size: 14px"
        :style="{
          '-webkit-line-clamp': p.media || p.link_preview ? 3 : 12,
        }"
      >
        <v-chip v-if="p.variations.length > 1" small class="mr-2" color="smoke"
          >{{ p.variations.length }} variation<span
            v-if="p.variations.length > 1"
            >s</span
          ></v-chip
        >{{ p.variations.find((v) => v.type === "main").text }}
      </p>
    </div>

    <v-divider></v-divider>

    <div class="pa-3 d-flex align-center">
      <v-chip
        small
        :color="p.status === 'active' ? 'success' : 'info'"
        class="primary--text font-weight-medium"
        >{{ p.status }}</v-chip
      >
      <v-spacer></v-spacer>
      <v-chip
        v-if="p.category"
        small
        label
        :color="p.category.color"
        class="ml-2 primary--text font-weight-medium"
        ><span
          :style="{
            color: p.category.color === '#272727' ? 'white' : '#272727',
          }"
        >
          {{ p.category.name }}
        </span></v-chip
      >
    </div>
  </v-card>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  props: {
    p: {
      type: Object,
    },
  },
  methods: {
    async deletePost() {
      this.$store.commit("setLoading", { type: "general", val: true });
      const functions = getFunctions();
      const deletePost = httpsCallable(functions, "deletePost");
      await deletePost(this.p.uid);

      this.$store.dispatch("fetchCategories");
      this.$store.dispatch("getCalendarDays");
      this.$store.commit("setLoading", { type: "general", val: false });
      this.$store.commit("setNotification", {
        show: true,
        text: "Your post has been deleted!",
        type: "primary",
        icon: "delete",
      });
    },
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cap {
  overflow: hidden;
  max-width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
