var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex start"},[_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
            borderRadius: '8px',
          })},[_c('v-btn',_vm._g(_vm._b({staticClass:"right-button",staticStyle:{"border-radius":"6px"},attrs:{"elevation":"0","color":"button","width":"180"}},'v-btn',attrs,false),on),[_vm._v("Start ")])],1)]}}])},[_c('v-list',{staticClass:"py-0"},_vm._l((_vm.items),function(i){return _c('v-list-item',{key:i.text,attrs:{"link":"","to":i.route},on:{"click":function($event){return _vm.action(i.action)}}},[_c('v-list-item-icon',[(i.icon)?_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v(_vm._s(i.icon))]):_vm._e(),(i.logo)?_c('v-img',{attrs:{"max-width":"20","height":"20","src":require(`@/assets/${i.logo}`)}}):_vm._e()],1),_c('v-list-item-title',[_vm._v(_vm._s(i.text))])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }