<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filtered"
      :items-per-page="-1"
      class="smoke"
      hide-default-footer
      :search="search"
      height="90vh"
      :loading="loading.admin.calendar"
    >
      <template v-slot:top>
        <div class="pa-4 d-flex align-center" style="gap: 10px">
          <v-text-field
            v-model="search"
            label="Search"
            solo
            flat
            style="border-radius: 6px"
            hide-details=""
          ></v-text-field>
          <v-btn
            @click="filter_noposts = !filter_noposts"
            height="48"
            elevation="0"
            color="primary"
            ><v-icon>{{
              filter_noposts ? "mdi-note-off" : "mdi-note"
            }}</v-icon></v-btn
          >
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="smoke lighten-1" elevation="0" @click="openInFb(item.uid)"
          ><v-icon>mdi-firebase</v-icon></v-btn
        >
      </template>

      <template v-slot:[`item.date`]="{ item }">
        {{ formatDate(item.date) }}
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      admin_notifications: (state) => state.admin.notifications,
      admin_calendar: (state) => state.admin.calendar,
    }),
    filtered() {
      let list = this.admin_calendar;

      if (this.filter_noposts) {
        list = list.filter((d) => d.posts.length > 0);
      }
      return list;
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Day", value: "date" },
        { text: "Posts", value: "posts.length" },
        { text: "", value: "actions" },
      ],
      filter_noposts: false,
    };
  },
  methods: {
    formatDate(d) {
      let date = d.toDate();
      return moment(date).format("DD-MM-YYYY");
    },
    openInFb(uid) {
      let production = window.location.origin.includes("app.stix.social");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "one-startup-stack" : "stix-staging-f240f"
      }/firestore/data/~2Fcalendar~2F${uid}`;
      window.open(url, "_blank");
    },
  },
};
</script>
