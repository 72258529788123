import { db } from "@/firebase";
import { getDocs, collection, doc, deleteDoc } from "firebase/firestore";

const templates = {
  state: {
    templates: [],
  },
  mutations: {
    setTemplates(state, val) {
      state.templates = val;
    },
    removeTemplate(state, val) {
      let i = state.templates.find((t) => t.uid === val);
      state.templates.splice(i, 1);
    },
  },
  actions: {
    async fetchTemplates({ commit, rootState }) {
      let templates = [];

      const querySnapshot = await getDocs(
        collection(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "templates"
        )
      );
      querySnapshot.forEach((doc) => {
        templates.push(doc.data());
      });

      templates.sort((a, b) =>
        a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
      );

      commit("setTemplates", templates);
    },
    async deleteTemplate({ commit, rootState }, val) {
      console.log("deleting template " + val);
      await deleteDoc(
        doc(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "templates",
          val
        )
      );
      commit("removeTemplate", val);
    },
  },
  getters: {},
};

export default templates;
