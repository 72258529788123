var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('div',{staticClass:"top"},[_c('v-btn',{staticClass:"px-8",attrs:{"height":"40","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.$router.push('/post')}}},[_vm._v("Add Post")]),_c('v-text-field',{staticStyle:{"border-radius":"8px","max-width":"200px"},style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }),attrs:{"label":"Search posts ...","flat":"","solo":"","dense":"","hide-details":"","clearable":""},on:{"click:clear":_vm.clearSearch,"change":_vm.changeSearch},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}}),_c('v-select',{staticStyle:{"border-radius":"8px","max-width":"200px"},style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }),attrs:{"flat":"","solo":"","clearable":"","dense":"","hide-details":"","label":"Recurring","items":_vm.recurring_items,"placeholder":"Filter on recurring"},on:{"change":_vm.changeRecurring,"click:clear":_vm.clearRecurring},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100%","font-size":"13px"}},[(item.text === 'Recurring')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"button darken-2"}},[_vm._v("mdi-leaf")]):_vm._e(),(item.text === 'One-time')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"smoke darken-2"}},[_vm._v("mdi-numeric-1-circle")]):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1)]}},{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[(item.text === 'Recurring')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"button darken-2"}},[_vm._v("mdi-leaf")]):_vm._e(),(item.text === 'One-time')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"smoke darken-2"}},[_vm._v("mdi-numeric-1-circle")]):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1)]}}],null,false,3393900891),model:{value:(_vm.query.recurring),callback:function ($$v) {_vm.$set(_vm.query, "recurring", $$v)},expression:"query.recurring"}}),_c('v-select',{staticStyle:{"border-radius":"8px","max-width":"200px"},style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }),attrs:{"flat":"","solo":"","dense":"","hide-details":"","label":"Category","items":_vm.categories,"item-value":"uid","item-text":"name","placeholder":"Filter on category","value":"All","clearable":"","loading":_vm.loading.categories},on:{"change":_vm.changeCategory,"click:clear":_vm.clearCategory},model:{value:(_vm.query.category),callback:function ($$v) {_vm.$set(_vm.query, "category", $$v)},expression:"query.category"}})],1),(_vm.filtered_posts.length > 0)?_c('div',{staticClass:"posts"},_vm._l((_vm.filtered_posts),function(p){return _c('div',{key:p.uid},[_c('PostsCard',{attrs:{"p":p},on:{"delete":_vm.deletePost}})],1)}),0):_vm._e(),(_vm.posts.length === 0 && !_vm.loading.posts)?_c('p',{staticStyle:{"opacity":"0.6","margin-top":"20px"}},[_vm._v(" Whoops! No posts found yet.. ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-4"},[_c('ConfirmDialog',{attrs:{"dialog":_vm.confirm_dialog},on:{"confirm":_vm.confirm}}),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('h1',{staticClass:"mb-4"},[_vm._v("Posts")]),_c('v-btn',{staticClass:"mb-4",attrs:{"elevation":"0","color":"button"},on:{"click":function($event){return _vm.$router.push('/post')}}},[_vm._v("Add new post")])],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('p',{staticClass:"mb-2",staticStyle:{"font-size":"10px"}},[_vm._v("Search")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticStyle:{"border-radius":"8px"},style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }),attrs:{"label":"Search","height":"50","solo":"","hide-details":"","flat":""}}),_c('FilterSheet')],1)]):_vm._e()],1),(_vm.$vuetify.breakpoint.smAndDown && _vm.posts.length > 0)?_c('div',{staticClass:"cards_mobile"},[_vm._l((_vm.posts),function(i){return _c('ContentCard',{key:i.uid,attrs:{"item":i}})}),_c('p',{staticStyle:{"text-align":"center","opacity":"0.6","margin-top":"20px"}},[_vm._v(" 👋 That's it! ")])],2):_vm._e(),(_vm.posts.length === 0 && _vm.$vuetify.breakpoint.smAndDown)?_c('p',{staticStyle:{"text-align":"center","opacity":"0.6","margin-top":"20px"}},[_vm._v(" Whoops! No posts found yet.. ")]):_vm._e(),_c('div')])
}
var staticRenderFns = []

export { render, staticRenderFns }