<template>
  <div>
    <v-dialog
      width="500"
      v-model="dialog"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          height="40"
          elevation="0"
          color="primary"
          class="px-8 topic-button"
          >Add topic</v-btn
        >
      </template>

      <v-card
        flat
        width="500"
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="font-size: 10pt; font-weight: 600"
        >
          {{ uid ? "Edit topic" : "New topic" }}
        </v-sheet>
        <v-divider class="primary"></v-divider>
        <v-window v-model="step">
          <v-window-item :value="0">
            <v-textarea
              placeholder="Write your topic here.."
              solo
              v-model="topic"
              flat
              @keypress="enter"
              class="pa-6"
              autofocus
              hide-details=""
              dense
              style="border-radius: 6px"
              hint="Press enter to add"
              no-resize
              ref="topicinput"
              full-width
            ></v-textarea>
            <div class="mx-6 my-2" v-if="!uid">
              <v-btn
                color="primary lighten-2"
                block
                text
                small
                @click="step = 1"
                >Ask Stix AI for topics</v-btn
              >
            </div>
            <v-divider></v-divider>
            <v-combobox
              label="Tags"
              solo
              style="border-radius: 8px"
              hide-details=""
              class="mx-6 my-2"
              :items="$store.state.topics.tags"
              height="50"
              flat
              clearable
              multiple
              v-model="tags"
              chips
            ></v-combobox>
            <v-divider></v-divider>
            <div class="d-flex pa-4">
              <v-spacer></v-spacer>
              <v-btn small elevation="0" text class="px-6 mr-2" @click="close"
                >Close</v-btn
              >
              <v-btn
                small
                elevation="0"
                class="px-6"
                color="button"
                @click="save"
                >Save</v-btn
              >
            </div>
          </v-window-item>
          <v-window-item :value="1">
            <div class="pa-6">
              <h1>Generate topic ideas</h1>
              <p style="font-size: 11pt">
                Describe to Stix AI on what topic or keywords you would like
                topic suggestions?
              </p>

              <div>
                <p style="font-size: 10px" class="mb-2 mt-4">
                  Topic
                  <v-tooltip bottom color="primary" max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="ml-1" v-bind="attrs" v-on="on"
                        >mdi-information</v-icon
                      >
                    </template>
                    <div class="pa-2">
                      <span class="white--text"
                        >Topic or keyword that is used as input for Stix AI to
                        generate relevant topic suggestions.</span
                      >
                    </div>
                  </v-tooltip>
                </p>
                <div>
                  <v-textarea
                    placeholder="Football clubs"
                    solo
                    v-model="prompt"
                    flat
                    hide-details=""
                    dense
                    style="border-radius: 6px"
                    background-color="smoke"
                    hint="Press enter to add"
                    rows="2"
                    @keypress="enterTopic"
                  ></v-textarea>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="d-flex pa-4">
              <v-spacer></v-spacer>
              <v-btn elevation="0" text class="px-6 mr-2" @click="close"
                >Close</v-btn
              >
              <v-btn elevation="0" color="button" @click="generate"
                >Generate topics</v-btn
              >
            </div>
          </v-window-item>
          <v-window-item :value="2"><Loading /></v-window-item>
          <v-window-item :value="3">
            <v-list dense style="border-radius: 8px">
              <v-list-item v-for="(item, i) in topic_items" :key="i">
                <v-text-field
                  solo
                  flat
                  background-color="smoke"
                  hide-details=""
                  style="font-size: 10pt; border-radius: 8px"
                  v-model="topic_items[i]"
                  class="my-1"
                >
                  <template v-slot:append>
                    <v-btn
                      v-if="topics.findIndex((t) => t.topic === item) < 0"
                      small
                      elevation="0"
                      color="button"
                      @click="saveTopic(item)"
                    >
                      Save
                    </v-btn>
                    <v-icon class="px-5" size="20" v-else color="primary"
                      >mdi-check</v-icon
                    >
                  </template>
                </v-text-field>
              </v-list-item>
            </v-list>
            <div class="mx-6 mb-2">
              <v-btn
                color="primary lighten-2"
                block
                text
                small
                @click="generate"
                >Load 5 new</v-btn
              >
            </div>
            <v-divider></v-divider>
            <div class="d-flex pa-4">
              <v-btn text small @click="step = 1">
                <v-icon small class="mr-1">mdi-chevron-left</v-icon>
                Edit input
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn elevation="0" class="px-6" color="button" @click="close"
                >Close</v-btn
              >
            </div>
          </v-window-item>
        </v-window>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loading from "@/components/lottie/AiLoading.vue";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  props: [],
  components: { Loading },
  data() {
    return {
      chat: [
        {
          role: "system",
          content: `You are a social media expert. You will be provided with a prompt. Your task is to provide five topics to write social media posts. Provide output in JSON format as follows: ["topic", "another topic", "another topic", "another topic", "another topic"].`,
        },
      ],
      dialog: false,
      keyword: "",
      prompt: "",
      topic: "",
      tags: [],
      topic_items: [],
      step: 0,
      uid: null,
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      loading: (state) => state.loading,
      topics: (state) => state.topics.topics,
    }),
  },

  methods: {
    close() {
      this.topic = "";
      this.keyword = "";
      this.uid = null;
      this.step = 0;
      this.tags = [];
      this.dialog = false;
    },
    enter(v) {
      if (v.key === "Enter") {
        this.save();
      }
    },
    enterTopic(v) {
      if (v.key === "Enter") {
        this.generate();
      }
    },
    edit(t) {
      this.dialog = true;
      this.tags = t.tags;
      this.uid = t.uid;
      this.topic = t.topic;
    },
    generate() {
      this.step = 2;

      if (this.chat.length === 1) {
        this.chat.push({
          role: "user",
          content: `${this.prompt}`,
        });
      } else {
        this.chat.push({
          role: "user",
          content: `Try again.`,
        });
      }
      const functions = getFunctions();
      const chat = httpsCallable(functions, "chatComplete");
      chat({ messages: this.chat, temperature: 1 }).then((result) => {
        this.chat.push({ role: "assistant", content: result.data });
        console.log(result.data);
        this.topic_items = JSON.parse(result.data);
        this.$store.commit("setLoading", { type: "ai.topics", val: false });
        this.step = 3;
      });
    },
    open() {
      this.dialog = true;
    },
    save() {
      this.$store.dispatch("saveTopic", {
        uid: this.uid,
        topic: this.topic,
        tags: this.tags,
      });
      this.dialog = false;
    },
    remove(i) {
      this.$store.dispatch("removeTag", this.workspace.keywords[i]);
    },
    saveTopic(i) {
      console.log(i);
      this.$store.dispatch("saveTopic", {
        topic: i,
        uid: null,
        tags: [],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item_title {
  white-space: normal;
}

.keys {
  gap: 10px;
  flex-wrap: wrap;
}

.topic-button .v-input__slot::before {
  border-bottom: "3px solid #272727";
  border-left: "1px solid #272727";
  border-top: "1px solid #272727";
  border-right: "3px solid #272727";
}
</style>
