import { render, staticRenderFns } from "./social_library.vue?vue&type=template&id=0159c437&scoped=true&"
import script from "./social_library.vue?vue&type=script&lang=js&"
export * from "./social_library.vue?vue&type=script&lang=js&"
import style0 from "./social_library.vue?vue&type=style&index=0&id=0159c437&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0159c437",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VDivider,VTab,VTabs,VTabsSlider})
