import { render, staticRenderFns } from "./SettingsStixAI.vue?vue&type=template&id=71871ab3&scoped=true&"
import script from "./SettingsStixAI.vue?vue&type=script&lang=js&"
export * from "./SettingsStixAI.vue?vue&type=script&lang=js&"
import style0 from "./SettingsStixAI.vue?vue&type=style&index=0&id=71871ab3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71871ab3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VCard,VDivider,VIcon,VSheet,VSpacer,VSwitch,VTextField,VTextarea,VTooltip})
