var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$vuetify.breakpoint.smAndDown)?_c('InboxItem',{ref:"dialog",attrs:{"item":_vm.items[0]}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"email","hide-default-footer":"","hide-default-header":"","mobile-breakpoint":"100"},on:{"click:row":_vm.selectItem},scopedSlots:_vm._u([{key:`item.avatar`,fn:function({ item }){return [_c('div',{staticClass:"py-4"},[_c('v-badge',{attrs:{"overlap":"","color":"accent","left":"","avatar":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-img',{staticStyle:{"border":"0.5px solid #fff","border-radius":"50px"},attrs:{"height":"20","width":"20","src":require(`@/assets/${item.platform}.png`)}})]},proxy:true}],null,true)},[(item)?_c('v-avatar',[_c('img',{attrs:{"src":require("@/assets/max.jpeg"),"alt":"John"}})]):_vm._e()],1)],1)]}},{key:`item.text`,fn:function({ item }){return [_c('p',{staticClass:"my-0 primary--text"},[_c('b',[_vm._v("Max van Kaathoven")])]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(item.text))]),_c('p',{staticClass:"my-0 py-0 primary--text",staticStyle:{"font-size":"10px","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(item.time)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""}},[(item)?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-dots-horizontal ")]):_vm._e()],1)]}}],null,true)})],1):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"inbox desktop"},[_c('div',{staticClass:"tools"},[_c('div',{staticClass:"d-flex justify-space-between align-start"},[_c('h1',{staticClass:"mb-4"},[_vm._v("Inbox")]),_c('v-chip',{staticClass:"error bell"},[_c('v-icon',{attrs:{"size":"16","color":"primary"}},[_vm._v("mdi-message")]),_c('span',[_vm._v("24 New")])],1)],1),_c('v-select',{staticClass:"mb-4",staticStyle:{"border-radius":"8px"},style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }),attrs:{"multiple":"","solo":"","flat":"","label":"Channels","hide-details":"","no-data-text":"No social accounts setup"}}),_c('v-select',{staticStyle:{"border-radius":"8px"},style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }),attrs:{"multiple":"","solo":"","flat":"","label":"Assigned to","hide-details":"","no-data-text":"No social accounts setup"}})],1),_c('div',[_c('v-card',{staticClass:"py-4",style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }),attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"email","hide-default-footer":"","hide-default-header":"","mobile-breakpoint":"100"},on:{"click:row":_vm.selectItem},scopedSlots:_vm._u([{key:`item.avatar`,fn:function({ item }){return [_c('div',{staticClass:"py-4"},[_c('v-badge',{attrs:{"overlap":"","color":"accent","left":"","avatar":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-img',{staticStyle:{"border":"0.5px solid #fff","border-radius":"50px"},attrs:{"height":"20","width":"20","src":require(`@/assets/${item.platform}.png`)}})]},proxy:true}],null,true)},[(item)?_c('v-avatar',[_c('img',{attrs:{"src":require("@/assets/max.jpeg"),"alt":"John"}})]):_vm._e()],1)],1)]}},{key:`item.text`,fn:function({ item }){return [_c('p',{staticClass:"my-0 primary--text"},[_c('b',[_vm._v("Max van Kaathoven")])]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(item.text))]),_c('p',{staticClass:"my-0 py-0 primary--text",staticStyle:{"font-size":"10px","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(item.time)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""}},[(item)?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-dots-horizontal ")]):_vm._e()],1)]}}],null,true)})],1)],1),_c('div',[_c('v-card',{staticClass:"px-8 pb-8 primary",style:({
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }),attrs:{"flat":""}},[_c('PreviewContent',{attrs:{"channels":_vm.channels}})],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }