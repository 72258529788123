var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
      mobile_schedules: _vm.$vuetify.breakpoint.smAndDown,
      desktop_schedules: !_vm.$vuetify.breakpoint.smAndDown,
    }},[_c('div',{staticClass:"d-flex mb-4"},[_c('h1',{staticClass:"mr-8"},[_vm._v(" Schedules "),(!_vm.loading.schedules || _vm.schedules.length > 0)?_c('span',{staticClass:"font-weight-regular"},[_vm._v("("+_vm._s(_vm.schedules.length)+")")]):_vm._e()]),_c('ScheduleDialog',{ref:"scheduleDialog"})],1),_vm._l((_vm.schedules),function(s){return _c('div',{key:s.uid,staticClass:"mb-2 mr-2"},[_c('v-card',{staticClass:"py-4 d-flex align-center justify-space-between",class:{
          'px-5': _vm.$vuetify.breakpoint.smAndDown,
          'px-10': !_vm.$vuetify.breakpoint.smAndDown,
        },style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }),attrs:{"flat":"","max-width":"750"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('span',[_vm._v("From ")]),(_vm.$vuetify.breakpoint.smAndDown)?_c('span',[_c('b',[_c('u',[_vm._v(_vm._s(s.category.name))])])]):_vm._e(),_c('span',[_vm._v(" post every "),_c('span',[_c('b',[_vm._v(_vm._s(s.frequency === 1 ? "" : s.frequency)+" "+_vm._s(s.frequency === 1 ? s.type.toLowerCase().slice(0, -1) : s.type.toLowerCase()))])]),(s.type === 'Weeks')?_c('span',[_vm._v(" on a "),_c('span',[_c('b',[_vm._v("Thursday")])]),_vm._v(" at "),_c('b',[_vm._v(_vm._s(s.timeslot))])]):_vm._e(),_vm._v(". ")])]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex align-center flex-wrap",staticStyle:{"gap":"10px"}},[_c('span',[_vm._v(" From")]),_c('v-chip',{staticClass:"px-6 font-weight-bold",attrs:{"color":s.category.color,"label":""}},[_c('span',{style:({
                color: s.category.color === '#272727' ? 'white' : '#272727',
              })},[_vm._v(_vm._s(s.category.name))])]),(_vm.$vuetify.breakpoint.smAndDown)?_c('span',[_c('b',[_c('u',[_vm._v(_vm._s(s.category.name))])])]):_vm._e(),_c('span',[_vm._v("post every "),_c('span',[_c('b',[_vm._v(_vm._s(s.frequency === 1 ? "" : s.frequency)+" "+_vm._s(s.frequency === 1 ? s.type.toLowerCase().slice(0, -1) : s.type.toLowerCase()))])]),(s.type === 'Weeks' || s.type === 'Months')?_c('span',[_vm._v(" on a "),(!Array.isArray(s.day_of_week))?_c('span',[_c('b',[_vm._v(_vm._s(_vm.days.find((f) => f.value === s.day_of_week).text))])]):_c('span',[_c('b',[_vm._v(_vm._s(_vm.getDays(s.day_of_week)))])])]):_vm._e(),_vm._v(" at "),_c('b',[_vm._v(_vm._s(s.timeslot))]),_vm._v(" . ")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$store.dispatch('deleteSchedule', s.uid)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)],1)}),(_vm.schedules && _vm.schedules.length === 0)?_c('p',{staticStyle:{"opacity":"0.6","margin-top":"20px"}},[_vm._v(" No schedules have been setup yet. Click the button 'Add schedule' to start! ")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('h1',[_vm._v("Schedules")]),_c('ScheduleMobile')],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }