<template>
  <div>
    <InboxItem
      :item="items[0]"
      ref="dialog"
      v-if="$vuetify.breakpoint.smAndDown"
    />

    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-data-table
        :headers="headers"
        :items="items"
        sort-by="email"
        hide-default-footer
        hide-default-header
        mobile-breakpoint="100"
        @click:row="selectItem"
      >
        <template v-slot:[`item.avatar`]="{ item }">
          <div class="py-4">
            <v-badge overlap color="accent" left avatar>
              <v-avatar v-if="item">
                <img src="@/assets/max.jpeg" alt="John"
              /></v-avatar>
              <template v-slot:badge>
                <v-img
                  style="border: 0.5px solid #fff; border-radius: 50px"
                  height="20"
                  width="20"
                  :src="require(`@/assets/${item.platform}.png`)"
                ></v-img>
              </template>
            </v-badge>
          </div>
        </template>
        <template v-slot:[`item.text`]="{ item }">
          <p class="my-0 primary--text"><b>Max van Kaathoven</b></p>
          <p class="mb-1">{{ item.text }}</p>
          <p
            class="my-0 py-0 primary--text"
            style="font-size: 10px; text-transform: uppercase"
          >
            {{ item.time }}
          </p>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon>
            <v-icon color="primary" v-if="item" small>
              mdi-dots-horizontal
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <div class="inbox desktop" v-if="!$vuetify.breakpoint.smAndDown">
      <div class="tools">
        <div class="d-flex justify-space-between align-start">
          <h1 class="mb-4">Inbox</h1>
          <v-chip class="error bell"
            ><v-icon size="16" color="primary">mdi-message</v-icon>
            <span>24 New</span>
          </v-chip>
        </div>
        <v-select
          multiple
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
          solo
          flat
          style="border-radius: 8px"
          label="Channels"
          hide-details=""
          no-data-text="No social accounts setup"
          class="mb-4"
        >
        </v-select>
        <v-select
          multiple
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
          solo
          flat
          style="border-radius: 8px"
          label="Assigned to"
          hide-details=""
          no-data-text="No social accounts setup"
        >
        </v-select>
      </div>
      <div>
        <v-card
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
          flat
          class="py-4"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            sort-by="email"
            hide-default-footer
            hide-default-header
            mobile-breakpoint="100"
            @click:row="selectItem"
          >
            <template v-slot:[`item.avatar`]="{ item }">
              <div class="py-4">
                <v-badge overlap color="accent" left avatar>
                  <v-avatar v-if="item">
                    <img src="@/assets/max.jpeg" alt="John"
                  /></v-avatar>
                  <template v-slot:badge>
                    <v-img
                      style="border: 0.5px solid #fff; border-radius: 50px"
                      height="20"
                      width="20"
                      :src="require(`@/assets/${item.platform}.png`)"
                    ></v-img>
                  </template>
                </v-badge>
              </div>
            </template>
            <template v-slot:[`item.text`]="{ item }">
              <p class="my-0 primary--text"><b>Max van Kaathoven</b></p>
              <p class="mb-1">{{ item.text }}</p>
              <p
                class="my-0 py-0 primary--text"
                style="font-size: 10px; text-transform: uppercase"
              >
                {{ item.time }}
              </p>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon>
                <v-icon color="primary" v-if="item" small>
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div>
        <v-card
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
          flat
          class="px-8 pb-8 primary"
        >
          <PreviewContent :channels="channels" />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import InboxItem from "@/components/InboxItem.vue";
import { mapState } from "vuex";
import PreviewContent from "@/components/preview/previewContent.vue";
export default {
  components: {
    InboxItem,
    PreviewContent,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      accounts: (state) => state.accounts,
      categories: (state) => state.posts.categories,
    }),
    channels() {
      let list = [];
      this.accounts.forEach((a) => {
        a.pages.forEach((p) => {
          list.push({ ...p, platform: a.type });
        });
      });
      return list;
    },
  },
  data() {
    return {
      items: [
        {
          platform: "facebook",
          text: "This is the message that the user has sent.",
          time: "4hrs ago",
        },
        {
          platform: "facebook",
          text: "This is the message that the user has sent.",
          time: "4hrs ago",
        },
        {
          platform: "facebook",
          text: "This is the message that the user has sent.",
          time: "4hrs ago",
        },
      ],
      headers: [
        {
          text: "Avatar",
          align: "center",
          sortable: false,
          value: "avatar",
        },
        {
          text: "Text",
          sortable: false,
          value: "text",
          align: "start",
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      selectedItem: null,
      showItem: false,
    };
  },
  methods: {
    selectItem(v) {
      this.selectedItem = v;
      const child = this.$refs.dialog;
      child.set(true);
      console.log(v);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
