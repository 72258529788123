var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-4 d-flex align-center",staticStyle:{"gap":"20px"}},[(_vm.userProfile && _vm.userProfile.superadmin)?_c('v-btn',{staticClass:"px-6",attrs:{"elevation":"0","color":"primary","height":"40"},on:{"click":_vm.addTemplate}},[_vm._v("Add template")]):_vm._e(),_c('v-select',{staticStyle:{"border-radius":"8px"},style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
          maxWidth: '200px',
        }),attrs:{"menu-props":{ offsetY: true },"multiple":"","items":_vm.items,"loading":_vm.loading.templates,"dense":"","solo":"","flat":"","label":"Tags","clearable":"","hide-details":"","no-data-text":_vm.loading.templates ? 'Fetching templates..' : 'No templates'},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"20px"}},_vm._l((_vm.filtered),function(t){return _c('TemplateCard',{key:t.uid,attrs:{"template":t}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }