<template>
  <v-dialog width="500" :value="dialog" transition="dialog-bottom-transition">
    <v-card
      flat
      width="500"
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
      v-if="dialog"
    >
      <v-sheet
        class="d-flex justify-center align-center py-2"
        color="accent"
        style="font-size: 10pt; font-weight: 600"
      >
        Edit template
      </v-sheet>
      <v-divider class="primary"></v-divider>
      <div class="pa-6">
        <div v-for="(t, index) in template.blocks" :key="index">
          <v-text-field v-model="t.text"></v-text-field>
        </div>
        <v-btn @click="save">save</v-btn>
      </div>
      <v-divider class="primary"></v-divider>
      <v-img
        :src="template.background.url"
        alt=""
        :aspect-ratio="template.aspect_ratio"
        id="my-node"
      >
        <div
          :style="{
            padding: `${template.padding.y}px ${template.padding.x}px`,
            'justify-content': template.align_items,
            'align-items': template.justify_content,
          }"
          style="
            height: 100%;
            display: flex;
            flex-direction: column;
            border: 1px solid black;
          "
        >
          <div
            v-for="(b, index) in template.blocks"
            :key="index"
            style="width: 100%"
          >
            <p
              v-if="b.type === 'text'"
              :style="{
                color: b.color,
                'line-height': b.line_height + '%',
                'font-size': b.font.size + 'px',
                'font-weight': b.font.weight,
                'font-style':
                  b.format && b.format.includes('italic') ? 'italic' : '',
                'margin-bottom': b.margin.bottom + 'px',
                'margin-top': b.margin.top + 'px',
                'margin-left': b.margin.left + 'px',
                'margin-right': b.margin.right + 'px',
                'text-align': b.text_align,
                'text-decoration':
                  b.format && b.format.includes('underline') ? 'underline' : '',
              }"
            >
              {{ b.text }}
            </p>
            <div
              v-if="b.type === 'image'"
              class="d-flex"
              :style="{ 'justify-content': b.justify }"
              style="width: 100%"
            >
              <img
                :style="{ width: b.width + '%' }"
                :src="b.media && b.media.url ? b.media.url : ''"
                alt=""
              />
            </div>
          </div>
        </div>
      </v-img>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import * as htmlToImage from "html-to-image";

export default {
  props: ["dialog", "template"],
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
    }),
  },
  data() {
    return {};
  },
  methods: {
    async save() {
      console.log("saving image");
      const dataUrl = await htmlToImage.toJpeg(
        document.getElementById("my-node"),
        { quality: 1 }
      );
      this.$emit("result", dataUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.template {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
</style>
